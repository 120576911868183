export const TRANSLATIONS_EN = {
    NCCP:"NATIONAL CANCER CONTROL PROGRAMME",
    NCCP_SUB:"National Cancer Registry of Sri\u00A0Lanka",
    CANCER: "Cancer",
    LATEST:"Today At A Glance",
    TREND:"Over Time",
    FACT_SHEET:"Fact Sheets",
    FORECAST:"Tomorrow",
    ROADMAP: "Roadmap",

    GENDER:"Gender",
    CANCER_SITE:"Cancer Site",
    DISTRICT:"District",
    MALE:"Male",
    FEMALE:"Female",
    BOTH:"Both",
    General:"General",
    Male:"Male",
    Female:"Female",
    Both:"Both Sexes",
    TOP10:"Top 10",
    ALL:"All",
    NO_OF_DATA:"Number of Data",
    RESET_FILTERS:"Reset Filters",
    BASED_ON:"Based On",
    YEAR_RANGE:"Year Range",

    BAR_CHART:"Bar Chart",
    PIE_CHART:"Pie Chart",
    LINE_CHART:"Line Chart",

    LBCRD:"Crude Incidence Rate",
    LBASR:"Age Standardized Incidence Rate",
    LBOAS:"Age Specific Incidence Rate",
    LPPLC:"Proportion of Leading Cancers",
    LLADC:"Age Distribution of Leading Cancers",
    TLCCI:"Crude Cancer Incidence Rates",
    TLASI:"Age Standardized Incidence Rates",

    //chart x, y axises
    //X
    CRUDE_RATE_X:"Crude Rate/100,000\u00A0pop.",
    ASR_X:"ASR / 100,000 Pop",
    AGE_SPEC_RATE_X:"Age Specific rate/100,000\u00A0pop.",
    AGE_RANGE_X:"Age Range",
    YEAR_X:"Year",

    //Y
    CANCER_SITES_Y:"Cancer Sites",
    AGE_RANGE_Y:"Age Range",
    INCI_RATE_Y:"Incidence Rate",
    CRUDE_RATE_Y:"Crude Rate",
    ASR_Y:"ASR",

    CANNOT_LOAD_CHART: "CANNOT LOAD CHART DATA",
    NO_FACTSHEET_DATA: "NO FACTSHEET DATA FOUND",
};
