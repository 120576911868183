import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Table } from 'react-bootstrap';
import './chartTableView.css';
import { withTranslation } from 'react-i18next';

class ChartTableView extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    componentDidMount(){
        this._isMounted = true;

        if(this._isMounted){
            
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    render(){

        /* const exportchartimage = <><tr style={{display:"none"}}><td colSpan="2"></td></tr><tr><td colSpan="2">
                <img src={this.props.generateprintimg} alt="" />
            </td></tr></>; */

        return (
        <>
            {(this.props.chartcode==="LBCRD" || this.props.chartcode==="LBASR"? //Crude Rate, Age Standardized Rate
                <Table bordered striped id="exceltable1" className="exceltable1-scroll">
                    <thead>
                        <tr>
                            <th style={{width:"180px",paddingLeft:"5%"}}>{this.props.axistitles.y}</th><th style={{width:"130px", textAlign:"center"}}>{this.props.axistitles.x}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.tabledata.data.map((item, i) =>{
                                return (
                                    <tr key={i}>
                                        <td style={{width:"200px",paddingLeft:"5%"}}>{item.cancerSiteName}</td>
                                        <td className="valtd" style={{width:"100px",paddingRight:"14%"}}>{item.count!==null?this.numberWithCommas(item.count):"N/A"}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            :this.props.chartcode==="LBOAS" ? //Overall Age Specific Rate
                <Table bordered striped id="exceltable1" className="exceltable1-scroll">
                    <thead>
                        <tr>
                            <th style={{width:"200px",paddingLeft:"10%"}}>{this.props.axistitles.y}</th><th style={{width:"100px", textAlign:"center"}}>{this.props.axistitles.x}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.tabledata.data.map((item, i) =>{
                                return <tr key={i}><td style={{width:"200px", paddingLeft:"10%"}}>{item.ageRangeName}</td><td className="valtd" style={{width:"100px",paddingRight:"14%"}}>{item.rate!==null?this.numberWithCommas(item.rate):"N/A"}</td></tr>
                            })
                        }
                    </tbody>
                </Table>  
                
            :this.props.chartcode==="LPPLC" ? //Proportion of Leading Cancers 
                <Col style={{overflowX:"auto",width:"100%"}}>
                    <Table striped id="exceltable1" className="exceltable1-scroll">
                        <thead>
                            <tr>
                                <th style={{width:"200px",paddingLeft:"5%"}}>Cancer Site</th><th width="200px" style={{width:"100px", textAlign:"center"}}>Count</th><th style={{width:"100px", textAlign:"center"}}>Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.tabledata.data.map((item, i) =>{
                                    return <tr key={i}><td style={{width:"200px",paddingLeft:"5%"}}>{item.cancerSiteName}</td><td className="valtd" style={{width:"100px",paddingRight:"8%"}}>{item.count!==null?this.numberWithCommas(item.count):"N/A"}</td><td className="valtd" style={{width:"100px",paddingRight:"8%"}}>{item.percentage}%</td></tr>
                                })
                            }
                        </tbody>
                    </Table> 
                
                </Col>
            :this.props.chartcode==="LLADC" ? //Age Distribution of leading Cancers 
                <Col xs={12} className="mainchart-overlay" style={{overflowX:this.props.tabledata.columns.length>6?"scroll":"hidden", overflowY:"auto",maxHeight:"calc(100vh - 240px)"}}>
                    <Table bordered striped id="exceltable1" className={this.props.tabledata.columns.length>5?"exceltable1-scroll scroll-to-left":"exceltable1-scroll scroll-to-right"}>
                        <thead>
                            <tr className="trheadersum"><th className="mobileview-td"></th><th colSpan={this.props.tabledata.columns.length-1} style={{textAlign:"center", width:((this.props.tabledata.columns.length-1)*100)+"px"}}>{this.props.axistitles.y}</th></tr>
                            <tr>
                                {
                                    this.props.tabledata.columns.map((item, i) =>{
                                        return(
                                            <th key={i} className={i>0?"text-center":""} style={{width:i===0?"200px":"100px", paddingLeft:i===0?(this.props.tabledata.columns.length>2?"10px":"5%"):"0px"}} >{item}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.tabledata.data.map((item, i) =>{
                                    return (
                                        <tr key={i}>
                                            <td className="mobileview-td" style={{paddingLeft:this.props.tabledata.columns.length>2?"10px":"5%"}}>{item.name}</td>
                                            {item.values.map((val, x) =>{
                                                return (<td key={x} className="valtd" style={{width:"100px", paddingRight:this.props.tabledata.columns.length>2?"20px":"15%"}} >{val!==null?this.numberWithCommas(val):"N/A"}</td>);
                                            })}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            :this.props.chartcode==="TLCCI" ? //CRUDE CANCER INCIDENCE RATES
                <Col xs={12} className="mainchart-overlay" style={{overflow:"auto",maxHeight:"calc(100vh - 240px)"}}>
                    <Table bordered striped id="exceltable1" className="exceltable1-scroll scroll-to-left">
                        <thead>
                        <tr className="trheadersum"><th style={{width:"200px"}}></th><th colSpan={this.props.tabledata.columns.length-1} style={{textAlign:"center", width:((this.props.tabledata.columns.length-1)*80)+"px"}}>{this.props.axistitles.y}</th></tr>
                            <tr>
                                {
                                    this.props.tabledata.columns.map((item, i) =>{
                                        return(
                                            <th className={i>0?"text-center":""} style={{width:i===0?"200px":"80px"}} key={i}>{item}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.tabledata.data.map((item, i) =>{
                                    return (
                                        <tr key={i}>
                                            <td style={{width:"200px"}}>{item.name}</td>
                                            {item.values.map((val, x) =>{
                                                return (<td key={x} className="valtd" style={{width:"80px"}} >{val!==null?this.numberWithCommas(val):"N/A"}</td>);
                                            })}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            :this.props.chartcode==="TLASI" ? //Age Standardized Incidence Rates 
                    <><Col xs={12} className="mainchart-overlay" style={{overflow:"auto",maxHeight:"calc(100vh - 240px)"}}>
                        <Table bordered striped id="exceltable1" className="exceltable1-scroll scroll-to-left">
                            <thead>
                            <tr className="trheadersum"><th style={{width:"200px"}}></th><th colSpan={this.props.tabledata.columns.length-1} style={{textAlign:"center", width:((this.props.tabledata.columns.length-1)*80)+"px"}}>{this.props.axistitles.y}</th></tr>
                                <tr>
                                    {
                                        this.props.tabledata.columns.map((item, i) =>{
                                            return(
                                                <th className={i>0?"text-center":""} style={{width:i===0?"200px":"80px"}} key={i}>{item}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            
                            <tbody>
                                {
                                    this.props.tabledata.data.map((item, i) =>{
                                        return (
                                            <tr key={i}>
                                                <td style={{width:"193px"}}>{item.name}</td>
                                                {item.values.map((val, x) =>{
                                                    return (<td key={x} className="valtd" style={{width:"80px"}}>{val!==null?val:"N/A"}</td>);
                                                })}
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>  
                    
                    </Col>
                    
                    </>
            :<Col></Col>
            )}
        </>);
    }
}

//export default ChartTableView;
export default withTranslation()(withRouter(connect()(ChartTableView)));
