import { LATESTYEAR_SET, USE_COLOR_SET, CURRENT_DATA_VERSION_SET } from '../../constants/commonTypes';

export const setLatestYear = (payload) => {
    return {
      type: LATESTYEAR_SET,
      payload
    }
};

export const setUseColor = (payload) => {
  return {
    type: USE_COLOR_SET,
    payload
  }
};

export const setCurrentDataVersion = (payload) => {
  return {
    type: CURRENT_DATA_VERSION_SET,
    payload
  }
};
