import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Table } from 'react-bootstrap';
import './chartTableView.css';
import { withTranslation } from 'react-i18next';

// import DynamicBarChart from '../chartmapView/charttypes/barchart';
// import DynamicPieChart from '../chartmapView/charttypes/piechart';
// import DynamicLineChart from '../chartmapView/charttypes/linechart';

// import nnplogo from '../../../assets/img/logo512.png';

class ExcelChartTableView extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    componentDidMount(){
        this._isMounted = true;

        if(this._isMounted){
            
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    
    render(){
        //console.log(this.props);
        return (
        <>
            {(this.props.chartcode==="LBCRD" || this.props.chartcode==="LBASR"? //Crude Rate, Age Standardized Rate
                <Table striped id="exceltable">
                    <thead>
                        {/* <tr className={this.props.title?"":"d-none"}><th colSpan="2">{this.props.t("NCCP")}</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2" style={{fontSize:"10px"}}>{this.props.t("NCCP_SUB")}</th></tr> */}
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2" style={{fontSize:"15px"}}>National Cancer Registry of Sri Lanka</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2" style={{fontSize:"10px"}}>NATIONAL CANCER CONTROL PROGRAMME</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2"></th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2">{this.props.title}</th></tr>
                        
                        {/* <tr><th colSpan="2"><img src={nnplogo}/></th></tr>
                        <tr>
                            <th colSpan="2"><DynamicBarChart data={this.props.chartdata} title={this.props.title} axistitles={this.props.axistitles} animations={false} /></th>
                        </tr> */}
                        {/* <tr><td colSpan="4"><img src={URL.createObjectURL(this.props.chartImg)} /></td></tr> */}

                        <tr>
                            <th width="80%">{this.props.axistitles.y}</th><th>{this.props.axistitles.x}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            this.props.tabledata.data.map((item, i) =>{
                                return <tr key={i}><td>{item.cancerSiteName}</td><td>{item.count}</td></tr>
                            })
                        }
                    </tbody>
                </Table>
            :this.props.chartcode==="LBOAS" ? //Overall Age Specific Rate
                <Table striped id="exceltable">
                    <thead>
                        {/* <tr className={this.props.title?"":"d-none"}><th colSpan="2">{this.props.t("NCCP")}</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2" style={{fontSize:"10px"}}>{this.props.t("NCCP_SUB")}</th></tr> */}
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2" style={{fontSize:"15px"}}>National Cancer Registry of Sri Lanka</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2" style={{fontSize:"10px"}}>NATIONAL CANCER CONTROL PROGRAMME</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2"></th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2">{this.props.title}</th></tr>
                        <tr>
                            <th width="80%">{this.props.axistitles.y}</th><th>{this.props.axistitles.x}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.tabledata.data.map((item, i) =>{
                                return <tr key={i}><td>{item.ageRangeName==="5-9"?"5᠆10":item.ageRangeName==="10-14"?"10᠆14":item.ageRangeName}</td><td>{item.rate}</td></tr>
                            })
                        }
                    </tbody>
                </Table>  
                
            :this.props.chartcode==="LPPLC" ? //Proportion of Leading Cancers 
                <Table striped id="exceltable">
                    <thead>
                        {/* <tr className={this.props.title?"":"d-none"}><th colSpan="2">{this.props.t("NCCP")}</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="2" style={{fontSize:"10px"}}>{this.props.t("NCCP_SUB")}</th></tr> */}
                        <tr className={this.props.title?"":"d-none"}><th colSpan="3" style={{fontSize:"15px"}}>National Cancer Registry of Sri Lanka</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="3" style={{fontSize:"10px"}}>NATIONAL CANCER CONTROL PROGRAMME</th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="3"></th></tr>
                        <tr className={this.props.title?"":"d-none"}><th colSpan="3">{this.props.title}</th></tr>
                        <tr>
                            <th width="80%">Cancer Site</th><th width="200px">Count</th><th width="200px" >Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.tabledata.data.map((item, i) =>{
                                return <tr key={i}><td>{item.cancerSiteName}</td><td>{item.count}</td><td>{item.percentage}</td></tr>
                            })
                        }
                    </tbody>
                </Table> 
            :this.props.chartcode==="LLADC" ? //Age Distribution of leading Cancers 
                <Table striped id="exceltable">
                    <thead>
                    {/* <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}>{this.props.t("NCCP")}</th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"10px"}}>{this.props.t("NCCP_SUB")}</th></tr> */}
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"15px"}}>National Cancer Registry of Sri Lanka</th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"10px"}}>NATIONAL CANCER CONTROL PROGRAMME</th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}></th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}>{this.props.title}</th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}></th></tr>
                    <tr><th style={{width:"200px"}}></th><th colSpan={this.props.tabledata.columns.length-1} style={{textAlign:"center", width:((this.props.tabledata.columns.length-1)*100)+"px"}}>{this.props.axistitles.y}</th></tr>
                    <tr>
                        {
                            this.props.tabledata.columns.map((item, i) =>{
                                return(
                                    <th key={i}>{item}</th>
                                )
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.tabledata.data.map((item, i) =>{
                                return (
                                    <tr key={i}>
                                        <td>{item.name==="5-9"?"5᠆10":item.name==="10-14"?"10᠆14":item.name}</td>
                                        {item.values.map((val, x) =>{
                                            return (<td key={x} className="valtd" >{val!==null?val:"N/A"}</td>);
                                        })}
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                    
                        {/* {
                            this.props.tabledata.data.map((item, i) =>{
                                return (
                                    <tbody key={i}>
                                    <tr key={i}><td colSpan="2">{item.cancerSiteName}</td></tr>
                                    {
                                        item.values.map((vitem, x) =>{
                                            return(
                                                <tr key={x}><td>{vitem.ageRange}</td><td>{parseFloat(vitem.count).toFixed(2)}</td></tr>
                                            )
                                        })
                                    }
                                   </tbody>
                                )
                            })
                        } */}
                    
                </Table>    
            :this.props.chartcode==="TLCCI" ? //CRUDE CANCER INCIDENCE RATES
                <Table bordered striped id="exceltable">
                        <thead>
                            {/* <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}>{this.props.t("NCCP")}</th></tr>
                            <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"10px"}}>{this.props.t("NCCP_SUB")}</th></tr> */}
                            <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"15px"}}>National Cancer Registry of Sri Lanka</th></tr>
                            <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"10px"}}>NATIONAL CANCER CONTROL PROGRAMME</th></tr>
                            <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}></th></tr>
                            <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}>{this.props.title}</th></tr>
                            <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}></th></tr>
                            <tr><th style={{width:"200px"}}></th><th colSpan={this.props.tabledata.columns.length-1} style={{textAlign:"center", width:((this.props.tabledata.columns.length-1)*80)+"px"}}>{this.props.axistitles.y}</th></tr>
                            <tr>
                                {
                                    this.props.tabledata.columns.map((item, i) =>{
                                        return(
                                            <th key={i}>{item}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.tabledata.data.map((item, i) =>{
                                    return (
                                        <tr key={i}>
                                            <td style={{width:"400px"}}>{item.name}</td>
                                            {item.values.map((val, x) =>{
                                                return (<td key={x} className="valtd">{val!==null?val:"N/A"}</td>);
                                            })}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    
                        {/* {
                            this.props.tabledata.data.map((item, i) =>{
                                return (
                                    <tbody key={i} >
                                        <tr><td width="80%" style={{padding:"0px",border:"none"}}></td><td style={{padding:"0px",border:"none"}}></td></tr>
                                        <tr><td colSpan="2" style={{textAlign:"left", fontWeight:"700",color:"#fff", fontSize:"16px", background:"#7052fb"}}>{item.year}</td></tr>
                                        {
                                            item.values.map((val, x) =>{
                                                return (<tr key={x} style={{fontSize:"13px"}}><td>{this.props.tabledata.type==="CANCER_SITE" ? val.cancerSiteName : val.gender}</td><td>{val.count!==null?val.count:"N/A"}</td></tr>);
                                            })
                                        }
                                    </tbody>
                                );
                            })
                        } */}
                    
                    </Table>  
            :this.props.chartcode==="TLASI" ? //Age Standardized Incidence Rates 
            <Table bordered striped id="exceltable">
                <thead>
                    {/* <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}>{this.props.t("NCCP")}</th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"10px"}}>{this.props.t("NCCP_SUB")}</th></tr> */}
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"15px"}}>National Cancer Registry of Sri Lanka</th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length} style={{fontSize:"10px"}}>NATIONAL CANCER CONTROL PROGRAMME</th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}></th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}>{this.props.title}</th></tr>
                    <tr className={this.props.title?"":"d-none"}><th colSpan={this.props.tabledata.columns.length}></th></tr>
                    <tr><th style={{width:"200px"}}></th><th colSpan={this.props.tabledata.columns.length-1} style={{textAlign:"center", width:((this.props.tabledata.columns.length-1)*80)+"px"}}>{this.props.axistitles.y}</th></tr>
                    <tr>
                        {
                            this.props.tabledata.columns.map((item, i) =>{
                                return(
                                    <th key={i}>{item}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                
                <tbody>
                    {
                        this.props.tabledata.data.map((item, i) =>{
                            return (
                                <tr key={i}>
                                    <td style={{width:"400px"}}>{item.name}</td>
                                    {item.values.map((val, x) =>{
                                        return (<td key={x} className="valtd">{val!==null?val:"N/A"}</td>);
                                    })}
                                </tr>
                            );
                        })
                    }
                </tbody>
                    
                

                {/* {
                    this.props.tabledata.data.map((item, i) =>{
                        return (
                            <tbody key={i}>
                                <tr><td width="80%" style={{padding:"0px",border:"none"}}></td><td style={{padding:"0px",border:"none"}}></td></tr>
                                <tr>
                                    <td colSpan="2" style={{textAlign:"left", fontWeight:"700",color:"#fff", fontSize:"16px", background:"#7052fb"}}>{item.year}</td>
                                </tr>
                                {item.values.map((val, x) =>{
                                    return (<tr key={x} style={{fontSize:"13px"}}><td>{this.props.tabledata.type==="CANCER_SITE" ? val.cancerSiteName : val.gender}</td><td>{val.count!==null?val.count:"N/A"}</td></tr>);
                                })}
                            </tbody>
                        );
                    })
                } */}
                
            </Table>  
            :<Col></Col>
            )}
        </>);
    }
}

//export default ChartTableView;
export default withTranslation()(withRouter(connect()(ExcelChartTableView)));
