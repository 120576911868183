import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row, Breadcrumb, Modal, Button } from 'react-bootstrap';
import { Home, BarChart2, PieChart, ChevronRight, ChevronLeft } from 'react-feather';
//import { Slide } from "react-awesome-reveal";

import sal from 'sal.js';

import { langList } from '../../_services/common.service';

import i18n from "i18next";
import "../../_translations/i18n";
//import { useTranslation } from 'react-i18next';

import './chartmappage.css';
import { withTranslation } from 'react-i18next';

import FiltersView from './filtersView/filtersview';
import ChangeChartView from './changechartview';
import ChartMapView from './chartmapView/mapview';

import { setMainType, setChartType, setChartCode } from '../../actions/search/search_action';
import { setSearchFilters } from '../../actions/search/search_action';

import { submitCollection } from '../../_services/submit.service';
import { chartList } from '../../_services/common.service';
import { submitSets } from '../UiComponents/SubmitSets';

import linechart from '../../assets/img/icons/line-chart.png';
import footerlogo from '../../assets/img/footer-logo.png';
import aitdevlogo from '../../assets/img/ait_developers.png';

const salstyle = {
    "--sal-duration": "0.8s",
}

const chartliststrings = {
    LBCRD:"Crude Incidence Rate",
    LBASR:"Age Standardized Incidence Rate",
    LBOAS:"Age Specific Incidence Rate",
    LPPLC:"Proportion of Leading Cancers",
    LLADC:"Age Distribution of Leading Cancers",
    TLCCI:"Crude Cancer Incidence Rates",
    TLASI:"Age Standardized Incidence Rates"
}

const chartxystrings = {
    CRUDE_RATE_X:"Crude Rate/100,000\u00A0pop.",
    ASR_X:"ASR / 100,000\u00A0Pop",
    AGE_SPEC_RATE_X:"Age Specific rate/100,000\u00A0pop.",
    AGE_RANGE_X:"Age Range",
    YEAR_X:"Year",
    CANCER_SITES_Y:"Cancer Sites",
    AGE_RANGE_Y:"Age Range",
    INCI_RATE_Y:"Incidence Rate",
    CRUDE_RATE_Y:"Crude Rate",
    ASR_Y:"ASR"
}

class ChartMapPage extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            selectedLang: "en",
            chartSelectModalShow:false,
            filterVisibility:{cancerSite:false, topResults:false, district:false, gender:false, ageRange:false},
            filterobj: {cancerSite: 0,selCancerSites:[], district:0, totalNoOfResults:10, gender: 'Both', minAge:0, maxAge:75, districtName:"", cancerSiteName:"", basedOnType:"CANCER_SITE",isICDCodeView:false, minYear:2005, maxYear:2019},
            mainTableData:{data:[], type:"CANCER_SITE", columns:[]}, chartCode:null, mainChartType:null, chartTitle:"", showChart:false,axistitles:{x:"", y:""}, chartBaseType:"",
            chartData:{categories:[],series:[]}, lineChartData:{categories:[],series:[{name:"",data:[]}]},
            colorsList:[],
            viewtype: "chart",
            chartDescription:{engDes:"", sinDes:"", tamDes:""},
            ischartpageloading: true, isloadingerror: false,
        }
    }

    componentDidMount(){
        this._isMounted = true;

        if(this._isMounted){
            sal({threshold: 0.2, once: true});

            if(localStorage.getItem("ahclangobj")){
                const clangobj = JSON.parse(localStorage.getItem("ahclangobj"));
                i18n.changeLanguage(clangobj.code);
            }

            //load language
            if(this.props.langobj&&this.props.langobj.languageDetails){
                this.setState({ selectedLang: (this.props.langobj.languageDetails.code?this.props.langobj.languageDetails.code:"en") });
            }
        }
        this.initData();
        
    }

    initData = () => {
        if(this.props.searchcatobj.chartCode!==null){//if chart is selected (check in redux)
            let chcode = this.props.searchcatobj.chartCode;
            let selectedChart = {};
            for (let i = 0; i < chartList.length; i++) {
                const item = chartList[i];
                if(item.code === chcode){
                    selectedChart = item;
                }
            }

            this.handleChartClick(selectedChart,this.props.searchobj.filters);//set chart
        }
        else{
            if(this.props.searchcatobj.mainType){
                this.setState({chartSelectModalShow:true});
            } else{
                setTimeout(() => {
                    this.props.history.push("/");
                }, 100);
            }
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleLang = (lcode, evt) => {
        evt.preventDefault();
        this.setState({ selectedLang: langList[lcode].code});
        i18n.changeLanguage(langList[lcode].code);
        this.props.handleLangObj(langList[lcode]);
        localStorage.setItem("ahclangobj",JSON.stringify(langList[lcode]));
    }

    changeMainType = (type) =>{
        this.props.SetMainType(type);
        this.props.SetChartType(null);
        this.props.SetChartCode(null);
        this.setState({chartCode:null});
    }

    changeChartType = (type) =>{
        this.props.SetChartType(type);
        this.props.SetChartCode(null);
        this.setState({chartCode:null});
    }

    updateFilterObjWithoutBackCall = (fobj) =>{
        this.setState({filterobj:fobj});
        this.props.SetSearchFilters(fobj);
        //let chartTitle = this.props.t(this.props.searchcatobj.chartCode);
        let chartTitle = chartliststrings[this.props.searchcatobj.chartCode];
        let filtersobj = this.state.filterobj;
        //set title
        if(this.state.chartCode==="LBCRD"){//Crude Rate
            let cancerSitesNames = "";
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");
            
            if(filtersobj.selCancerSites.length<=3){
                for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                    if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                        cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name + (j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                    }
                }
            }
            chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
            //chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - "+this.props.t("TOP10"):"");
            chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - Top 10":"");

        }
        if(this.state.chartCode==="LBASR"){//Age Standardized Rate
            let cancerSitesNames = "";
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");
            
            if(filtersobj.selCancerSites.length<=3){
                for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                    if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                        cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name +(j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                    }
                }
            }

            chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
            //chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - "+this.props.t("TOP10"):"");
            chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - Top 10":"");
        }
        if(this.state.chartCode==="LBOAS"){//Overall Age Specific Rate
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");

            let cname= filtersobj.cancerSiteName;
            
            chartTitle = chartTitle + (cname!==""? (" - "+cname):"") ;
        }
        if(this.state.chartCode==="LPPLC"){//Proportion of Leading Cancers
            let cancerSitesNames = "";
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");

            if(filtersobj.selCancerSites.length<=3){
                for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                    if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                        cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name +(j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                    }
                }
            }
            chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
        }
        if(this.state.chartCode==="LLADC"){//Age Distribution of leading Cancers (at the point of diagnosis)
            let cancerSitesNames = "";
            if(filtersobj.selCancerSites.length<=3){
                for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                    if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                        cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name +(j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                    }
                }
            }
          
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");
            chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
        }
        if(this.state.chartCode==="TLCCI"){//Crude Cancer Incidence Rates
            let cancerSitesNames = "";  
            if(filtersobj.basedOnType==="CANCER_SITE"){
                if(filtersobj.selCancerSites.length<=3){
                    for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                        if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                            cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name + (j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                        }
                    }
                }
            }
            chartTitle = chartTitle + " (" +filtersobj.minYear + " - "+filtersobj.maxYear+")";
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");

            if(filtersobj.basedOnType==="CANCER_SITE"){
                chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
                //chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - "+this.props.t("TOP10"):"");
                chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - Top 10":"");
            }
        }
        if(this.state.chartCode==="TLASI"){//Age Standardized Incidence Rates
            let cancerSitesNames = "";
            if(filtersobj.basedOnType==="CANCER_SITE"){
                if(filtersobj.selCancerSites.length<=3){
                    for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                        if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                            cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name + (j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                        }
                    }
                }
            }
            
            chartTitle = chartTitle + " (" +filtersobj.minYear + " - "+filtersobj.maxYear+")";
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");

            if(filtersobj.basedOnType==="CANCER_SITE"){
                chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
                //chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - "+this.props.t("TOP10"):"");
                chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - Top 10":"");
            }
           
            
        }

        this.setState({chartTitle:chartTitle});
    }

    handleChartClick = (item,sobj) =>{
        //let axisobj = {x:this.props.t(item.axistitles.x),  y:this.props.t(item.axistitles.y) }
        let axisobj = {x:chartxystrings[item.axistitles.x],  y:chartxystrings[item.axistitles.y] }
        this.setState({filterVisibility:item.filters, 
                        chartCode:item.code, 
                        mainChartType:item.chartType, 
                        chartBaseType:item.baseType,
                        axistitles:axisobj,
                        mainTableData:{data:[], type:"CANCER_SITE",columns:[]},
                        chartTitle:"", 
                        chartData:{categories:[],series:[]}, lineChartData:{categories:[],series:[{name:"",data:[]}]},
                        filterobj:sobj===null?{
                                cancerSite: (item.filters.csall===true?-1:(item.code==="LBOAS"?"AllSitesAgeSpecificRate":"0")), 
                                selCancerSites:((item.filters.multiCancerSites===true || item.filters.basedOnType===true) && item.filters.csall===true)?[{id:0,name:"All Sites",icdcode:"All Sites"}]: (item.code==="LLADC"?[{id:"AllSitesAgeSpecificRate",name:"All Sites Combined",icdcode:"All Sites Combined"}]:[]), 
                                district:0, 
                                totalNoOfResults:10, 
                                gender: (item.filters.genderOnlyMF===true || item.filters.basedOnType===true?"Male":"Both"), 
                                minAge:0, maxAge:75 , 
                                districtName:"", 
                                cancerSiteName:"",
                                basedOnType:"CANCER_SITE",
                                isICDCodeView:false,
                                minYear:2005, maxYear:this.props.latestYear}:sobj },() =>{
            
            this.props.SetChartCode(item.code);
            this.handleModal(false);
            this.mainChartLoad(this.state.filterobj);
        });
    }

    handleModal = (type) =>{
        this.setState({chartSelectModalShow:type});
    }

    mainChartLoad = (sobj) =>{
        //console.log(sobj);
        this.setState({filterobj:sobj});
        this.props.SetSearchFilters(sobj);
        let backmethod = {};
        this.setState({showChart:false, ischartpageloading: true, isloadingerror: false});

        if(this.state.chartCode!==null){
            if(this.state.chartCode==="LBCRD"){//Crude Rate
                backmethod = submitCollection.getCRData;
            }
            else if(this.state.chartCode==="LBASR"){//Age Standardized Rate
                backmethod = submitCollection.getASRData;
            }
            else if(this.state.chartCode==="LBOAS"){//Overall Age Specific Rate
                backmethod = submitCollection.getOverallAgeSpecificIncidenceRate;
            }
            else if(this.state.chartCode==="LPPLC"){//Proportion of Leading Cancers
                backmethod = submitCollection.getProprtionOfLeadingCancers;
            }
            else if(this.state.chartCode==="LLADC"){//Age Distribution of leading Cancers (at the point of diagnosis)
                if(sobj.selCancerSites.length===0){
                    return false;
                }
                backmethod = submitCollection.getAgeDistributionOfLeadingCancers;
            }
            else if(this.state.chartCode==="TLCCI"){//Crude Cancer Incidence Rates
                if(sobj.selCancerSites.length===0){
                    return false;
                }
                backmethod = submitCollection.getCrudeCancerIncidenceRates;
            }
            else if(this.state.chartCode==="TLASI"){//Age Standardized Incidence Rates
                if(sobj.selCancerSites.length===0){
                    return false;
                }
                backmethod = submitCollection.getAgeStandardizedIncidenceRates;
            }
            
            submitSets(backmethod,sobj).then(res=> {
                if(res.status===true && res.extra.length>0){
                    this.initChartData(res.extra);
                    this.setState({showChart:true});
                } else{
                    this.setState({ ischartpageloading: false, isloadingerror: true });
                }
            });
        }
    }

    initChartData(data){
        let chartData = {categories:[],series:[], colors:[]};
        let lineChartData = {categories:[], series:[{name:"", data:[]}], colors:[]};
        //let chartTitle = this.props.t(this.props.searchcatobj.chartCode);
        let chartTitle = chartliststrings[this.props.searchcatobj.chartCode];
        let filtersobj = this.state.filterobj;
        //console.log(this.state.chartCode);
        if(this.state.chartCode==="LBCRD"){//Crude Rate
            let cancerSitesNames = "";
            for (let i = 0; i < data.length; i++) {
                //separate long text
                // if(data[i].cancerSiteName.length>12){
                //     let arr = data[i].cancerSiteName.split(/(?=[&])/g);
                //     chartData.categories.push(arr);
                // }
                // else{
                
                // }
                
                chartData.categories.push(data[i].cancerSiteName);
                chartData.series.push(data[i].count);
                if(this.props.usecolor===true){
                    chartData.colors.push(data[i].cancerSiteColor);
                }
            }
            
            
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");
            
            if(filtersobj.selCancerSites.length<=3){
                for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                    if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                        cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name + (j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                    }
                }
            }
            chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
            //chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - "+this.props.t("TOP10"):"");
            chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - Top 10":"");

        }
        if(this.state.chartCode==="LBASR"){//Age Standardized Rate
            let cancerSitesNames = "";
            for (let i = 0; i < data.length; i++) {
                //separate long text
                // if(data[i].cancerSiteName.length>12){
                //     let arr = data[i].cancerSiteName.split(/(?=[&])/g);
                //     chartData.categories.push(arr);
                // }
                // else{
                // }
                chartData.categories.push(data[i].cancerSiteName);
                chartData.series.push(data[i].count);
                if(this.props.usecolor===true){
                    chartData.colors.push(data[i].cancerSiteColor);
                }
            }

            
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");
            
            if(filtersobj.selCancerSites.length<=3){
                for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                    if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                        cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name +(j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                    }
                }
            }

            chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
            //chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - "+this.props.t("TOP10"):"");
            chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - Top 10":"");
        }
        if(this.state.chartCode==="LBOAS"){//Overall Age Specific Rate
            let datalist = JSON.parse(JSON.stringify(data))
            let masdata = datalist.reverse();
            for (let i = 0; i < masdata.length; i++) {
                chartData.categories.push(masdata[i].ageRangeName);
                chartData.series.push(masdata[i].rate);
            }
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");

            let cname= filtersobj.cancerSiteName;
            chartTitle = chartTitle + (cname!==""? (" - "+cname):"") ;
        }
        if(this.state.chartCode==="LPPLC"){//Proportion of Leading Cancers
            let cancerSitesNames = "";
            for (let i = 0; i < data.length; i++) {
                chartData.categories.push(data[i].cancerSiteName);
                chartData.series.push(parseFloat(data[i].count));
                if(this.props.usecolor===true){
                    chartData.colors.push(data[i].cancerSiteColor);
                }
            }

            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");

            if(filtersobj.selCancerSites.length<=3){
                for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                    cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name +(j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                }
            }
            chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
        }
        if(this.state.chartCode==="LLADC"){//Age Distribution of leading Cancers (at the point of diagnosis)
            let cancerSitesNames = "";
            for (let i = 0; i < data.length; i++) {
                const mitem = data[i];
                let carr = [];
                
                for (let x = 0; x < mitem.values.length; x++) {
                    if(lineChartData.categories.length <= mitem.values.length){
                        lineChartData.categories.push(mitem.values[x].ageRange);
                    }
                    carr.push(mitem.values[x].count);

                }

                if(this.props.usecolor===true){
                    lineChartData.colors.push(mitem.cancerSiteColor);
                }
                
                lineChartData.series.push({name:mitem.cancerSiteName, data:carr});
                // if(data.length<=3){
                    //     cancerSitesNames = cancerSitesNames + " "+mitem.cancerSiteName +(i=== (data.length - 1)?"":"," );
                    // }
                }
                
                if(filtersobj.selCancerSites.length<=3){
                    for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                        cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name +(j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                    }
                }
                
            
            lineChartData.series.splice(0,1);
            chartTitle = chartTitle + " " +this.props.latestYear;
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");
            chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
        }
        if(this.state.chartCode==="TLCCI"){//Crude Cancer Incidence Rates
            let cancerSitesNames = "";
            if(filtersobj.basedOnType==="CANCER_SITE"){
                let cancerSiteList = [];
                for (let i = 0; i < data.length; i++) {
                    const mitem = data[i];
                    lineChartData.categories.push(mitem.year);
                    
                    if(i===0){
                        for (let x = 0; x < mitem.values.length; x++) {
                            cancerSiteList.push({name:mitem.values[x].cancerSiteName, data:[]});
                            if(this.props.usecolor===true){
                                lineChartData.colors.push(mitem.values[x].cancerSiteColor);
                            }
                        }
                    }
                }

                for (let y = 0; y < data.length; y++) {
                    const yitem = data[y];
                    for (let z = 0; z < yitem.values.length; z++) {
                        for (let g = 0; g < cancerSiteList.length; g++) {
                            if(yitem.values[z].cancerSiteName===cancerSiteList[g].name){
                                cancerSiteList[g].data.push(yitem.values[z].count);
                            }
                            
                        }
                        
                    }
                    
                }

                if(filtersobj.selCancerSites.length<=3){
                    for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                        if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                            cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name + (j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                        }
                    }
                }

                lineChartData.series = cancerSiteList;
            }
            else{
                let marray = [];let farray = [];let barray = [];
                for (let i = 0; i < data.length; i++) {
                    lineChartData.categories.push(data[i].year);
                    for (let x = 0; x < data[i].values.length; x++) {
                        let yitem = data[i].values[x];
                        if(yitem.gender==="Male"){
                            marray.push(yitem.count);
                        }
                        else if (yitem.gender==="Female"){
                            farray.push(yitem.count);
                        }
                        else if(yitem.gender==="Both"){
                            barray.push(yitem.count);
                        }
                    }
                }
                lineChartData.series = [];
                
                if(filtersobj.gender==="Male"){
                    lineChartData.series.push({name:"Male",data:marray});
                    lineChartData.colors.push("#2a52be");
                }
                else if(filtersobj.gender==="Female"){
                    lineChartData.series.push({name:"Female",data:farray});
                    lineChartData.colors.push("#de6fa1");
                }
                else if(filtersobj.gender==="Both"){
                    lineChartData.series.push({name:"Male",data:marray});
                    lineChartData.series.push({name:"Female",data:farray});
                    lineChartData.series.push({name:"Both",data:barray});
                }

            }
            
            chartTitle = chartTitle + " (" +filtersobj.minYear + " - "+filtersobj.maxYear+")";
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");
            
            if(filtersobj.basedOnType==="CANCER_SITE"){
                chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
                //chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - "+this.props.t("TOP10"):"");
                chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - Top 10":"");
            }
            
        }
        if(this.state.chartCode==="TLASI"){//Age Standardized Incidence Rates
            let cancerSitesNames = "";
            if(filtersobj.basedOnType==="CANCER_SITE"){
                let cancerSiteList = [];
                for (let i = 0; i < data.length; i++) {
                    const mitem = data[i];
                    lineChartData.categories.push(mitem.year);
                    
                    if(i===0){
                        for (let x = 0; x < mitem.values.length; x++) {
                            cancerSiteList.push({name:mitem.values[x].cancerSiteName, data:[]});
                            //cancerSitesNames = cancerSitesNames + " "+mitem.values[x].cancerSiteName +(x=== (mitem.values.length - 1)?"":"," );
                        }
                    }
                }

                for (let y = 0; y < data.length; y++) {
                    const yitem = data[y];
                    for (let z = 0; z < yitem.values.length; z++) {
                        for (let g = 0; g < cancerSiteList.length; g++) {
                            if(yitem.values[z].cancerSiteName===cancerSiteList[g].name){
                                cancerSiteList[g].data.push(yitem.values[z].count);
                                if(this.props.usecolor===true){
                                    lineChartData.colors.push(yitem.values[z].cancerSiteColor);
                                }
                            }
                            
                        }
                        
                    }
                    
                }
                if(filtersobj.selCancerSites.length<=3){
                    for (let j = 0; j < filtersobj.selCancerSites.length; j++) {
                        if(filtersobj.selCancerSites[j].id!=="0" && filtersobj.selCancerSites[j].id!==0){
                            cancerSitesNames = cancerSitesNames + " "+filtersobj.selCancerSites[j].name + (j=== (filtersobj.selCancerSites.length - 1)?"":"," );
                        }
                    }

                }
              
               
                lineChartData.series = cancerSiteList;
            }
            else{
                let marray = [];let farray = [];let barray = [];
                for (let i = 0; i < data.length; i++) {
                    lineChartData.categories.push(data[i].year);
                    for (let x = 0; x < data[i].values.length; x++) {
                        let yitem = data[i].values[x];
                        if(yitem.gender==="Male"){
                            marray.push(yitem.count);
                        }
                        else if (yitem.gender==="Female"){
                            farray.push(yitem.count);
                        }
                        else if(yitem.gender==="Both"){
                            barray.push(yitem.count);
                        }
                    }
                }
                lineChartData.series = [];
                
                if(filtersobj.gender==="Male"){
                    lineChartData.series.push({name:"Male",data:marray});
                    lineChartData.colors.push("#2a52be");
                }
                else if(filtersobj.gender==="Female"){
                    lineChartData.series.push({name:"Female",data:farray});
                    lineChartData.colors.push("#de6fa1");
                }
                else if(filtersobj.gender==="Both"){
                    lineChartData.series.push({name:"Male",data:marray});
                    lineChartData.series.push({name:"Female",data:farray});
                    lineChartData.series.push({name:"Both",data:barray});
                }

            }
            
            chartTitle = chartTitle + " (" +filtersobj.minYear + " - "+filtersobj.maxYear+")";
            //chartTitle = chartTitle + (filtersobj.gender!==""? " - "+this.props.t(filtersobj.gender):"");
            chartTitle = chartTitle + (filtersobj.gender!==""? " - "+filtersobj.gender:"");

            if(filtersobj.basedOnType==="CANCER_SITE"){
                chartTitle = chartTitle + (cancerSitesNames!==""? " - "+cancerSitesNames:"");
                //chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - "+this.props.t("TOP10"):"");
                chartTitle = chartTitle + (filtersobj.totalNoOfResults===10?" - Top 10":"");
            }
           
            
        }

        let tbldata = {};
        tbldata.data = data;
        tbldata.type = filtersobj.basedOnType;

        if(this.state.chartCode==="TLASI" || this.state.chartCode==="TLCCI"){
            tbldata.columns = [];
            
            let csList = []; let columnList = [];
            if(data.length>0 && filtersobj.basedOnType==="CANCER_SITE"){
                //set cancer sites
                columnList.push("Cancer Site");
                for (let c = 0; c < data[0].values.length; c++) {
                    csList.push({id:data[0].values[c].cancerSiteId, name:data[0].values[c].cancerSiteName, values:[]});
                }
               
                //set data
                for (let x = 0; x < data.length; x++) {
                    let mobj = data[x];
                    columnList.push(mobj.year);
                    for (let i = 0; i < csList.length; i++) {
                        for (let y = 0; y < mobj.values.length; y++) {
                            let sobj = mobj.values[y];
                            if(csList[i].id === sobj.cancerSiteId){
                                csList[i].values.push(sobj.count);
                            }
                        }
                    }
                }    
                //console.log(csList);
                
                tbldata.columns = columnList;
                tbldata.data = csList;
            }
            else if(data.length>0 && filtersobj.basedOnType==="GENDER"){
                //set cancer sites
                columnList.push("Gender");
                for (let c = 0; c < data[0].values.length; c++) {
                    csList.push({name:data[0].values[c].gender, values:[]});
                }
               
                //set data
                for (let x = 0; x < data.length; x++) {
                    let mobj = data[x];
                    columnList.push(mobj.year);
                    for (let i = 0; i < csList.length; i++) {
                        for (let y = 0; y < mobj.values.length; y++) {
                            let sobj = mobj.values[y];
                            if(csList[i].name === sobj.gender){
                                csList[i].values.push(sobj.count);
                            }
                        }
                    }
                }    
                //console.log(csList);
                
                tbldata.columns = columnList;
                tbldata.data = csList;
            }
        }

        if(this.state.chartCode==="LLADC"){
            tbldata.columns = [];
            let arList = []; let columnList = [];
            if(data.length>0){
                columnList.push("Age Range");
                for (let c = 0; c < data[0].values.length; c++) {
                    arList.push({id:data[0].values[c].ageRangeId, name:data[0].values[c].ageRange, values:[]});
                }
               
                //set data
                for (let x = 0; x < data.length; x++) {
                    let mobj = data[x];
                    columnList.push(mobj.cancerSiteName);
                    for (let i = 0; i < arList.length; i++) {
                        for (let y = 0; y < mobj.values.length; y++) {
                            let sobj = mobj.values[y];
                            if(arList[i].id === sobj.ageRangeId){
                                arList[i].values.push(sobj.count);
                            }
                        }
                    }
                }    
                //console.log(csList);
                
                tbldata.columns = columnList;
                tbldata.data = arList;
            }
        }
        
        //console.log(this.state.chartCode);

        //console.log(chartData);
        this.setState({chartData:chartData, lineChartData:lineChartData, chartTitle:chartTitle , mainTableData:tbldata },()=>{
            this.setState({ischartpageloading: false});
        });
        this.getChartDescription();
    }

    getChartDescription = () =>{
        //console.log(this.props.chartDescriptions);
        let desObj = {engDes:"", sinDes:"", tamDes:""};
        for (let i = 0; i < this.props.chartDescriptions.length; i++) {
            if(this.state.chartCode===this.props.chartDescriptions[i].chartCode){
                desObj.engDes = this.props.chartDescriptions[i].description!==null?this.props.chartDescriptions[i].description:"";
                desObj.sinDes = this.props.chartDescriptions[i].descriptionSin!==null?this.props.chartDescriptions[i].descriptionSin:"";
                desObj.tamDes = this.props.chartDescriptions[i].descriptionTam!==null?this.props.chartDescriptions[i].descriptionTam:"";
            }
        }
        this.setState({chartDescription:desObj});
        //console.log(desObj);
    }

    changeviewtype = (type) =>{//switch between chart view and table view
        this.setState({viewtype:type});
    }

    render(){
        return (<>
        <Col className="main-inner-content">
            <Col xs={12} className="main-content" style={{paddingTop:"70px"}}>
                <Container>
                    <Col xs={12} className="middle-content" style={{marginBottom:"8rem"}}>
                        {/* <h3 className="middle-content-header" style={{marginTop:"5px"}}>
                            {this.props.searchcatobj.mainType==="latest"?this.props.t("LATEST"):this.props.t("TREND")}
                            {this.props.searchcatobj.chartCode!==null?(" / "+this.props.t(this.props.searchcatobj.chartCode)):""}
                        </h3> */}
                        
                        <Col style={{minHeight:"450px"}}>
                            <Col className={this.state.chartCode!==null?"":"d-none"}>
                                <Row>
                                    <Col xs={12} md={5} lg={3} className="containts-div" style={{padding:"15px 8px"}}>
                                        <Breadcrumb style={{marginBottom:"10px"}}>
                                            <Link to="/" className="breadcrumb-item" style={{marginTop:"-3px"}}><Home size={14}/></Link>
                                            <Breadcrumb.Item active>{this.props.searchcatobj.mainType==="latest"?"Today":this.props.searchcatobj.mainType==="trends"?"Overtime":""}</Breadcrumb.Item>
                                        </Breadcrumb>
                                        
                                        <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle}>
                                            <ChangeChartView t={this.props.t} searchcatobj={this.props.searchcatobj} SetSearchFilters={this.props.SetSearchFilters} SetChartCode={this.props.SetChartCode} SetMainType={this.props.SetMainType} history={this.props.history} />
                                        </Col>
                                        
                                        <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle} className="containts-inner">
                                            <FiltersView chartcode={this.state.chartCode} mainChartLoad={this.mainChartLoad} updateFilterObjWithoutBackCall={this.updateFilterObjWithoutBackCall} filtersData={this.props.filterlists} filtersVisibility={this.state.filterVisibility} filtervals={this.state.filterobj} latestYear={this.props.latestYear} chartBaseType={this.state.chartBaseType} langobj={this.props.langobj.languageDetails} />
                                        </Col>
                                    </Col>
                                    <Col xs={12} md={7} lg={9} className="containts-div">
                                        <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" className="containts-inner" style={{...salstyle,minHeight:"500px",position:"relative"}}>
                                            {this.state.ischartpageloading?<div className="lds-dual-ring" style={{position:"absolute",marginLeft:"auto", marginTop:"auto", left:"0", right:"0", top:"200px",textAlign:"center"}}></div>:<></>}
                                            <ChartMapView data={this.state.chartData} linechartdata={this.state.lineChartData} charttitle={this.state.chartTitle} charttype={this.state.mainChartType} showChart={this.state.showChart} axistitles={this.state.axistitles} viewtype={this.state.viewtype} changeviewtype={this.changeviewtype} chartcode={this.state.chartCode} tabledata={this.state.mainTableData} useCustomColors={this.props.usecolor} chartDescription={this.state.chartDescription} langobj={this.props.langobj.languageDetails} />                    
                                            {this.state.isloadingerror?<h2 className="text-center" style={{padding:"13rem 0",fontSize:"1.6rem",color:"#999"}}>{this.props.t("CANNOT_LOAD_CHART")}</h2>:<></>}
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>    
                        </Col>
                        
                    </Col>  
                </Container>
            </Col>
        </Col>
        <Col className="main-footer">
            <Container>
                <img src={footerlogo} className="img-fluid" alt="footer logo"/>
                <span>National Cancer Control Programme,  Sri Lanka.</span>
                <a href="http://www.avenir.lk" target="_blank" rel="noreferrer"><img src={aitdevlogo} className="img-fluid devlogo-footer" title="Avenir IT (pvt) Ltd, Sri Lanka" alt="avenir it logo"/></a>
            </Container>
        </Col>

        <Modal show={this.state.chartSelectModalShow} centered animation={false} backdrop="static" keyboard={false} className="changechart-modal" onHide={()=>this.handleModal(false)}>
            <Modal.Body>
                {/* <Col xs={12}>
                    <h4>Explore Statistics</h4>
                    <Button variant={this.props.searchcatobj.mainType==="latest"?"primary":"secondary"} onClick={()=>this.changeMainType("latest")}>{this.props.t("LATEST")}</Button>{' '}
                    <Button variant={this.props.searchcatobj.mainType==="trends"?"primary":"secondary"} onClick={()=>this.changeMainType("trends")}>{this.props.t("TREND")}</Button>
                </Col><br/>
                <Col xs={12} className={this.props.searchcatobj.mainType==="latest"?"":"d-none"}>
                    <h5>Latest Statistics</h5>
                    <Button variant={this.props.searchcatobj.chartType==="latest-bar" ?"primary" : "secondary"} onClick={()=>this.changeChartType("latest-bar")}>{this.props.t("BAR_CHART")}</Button>{' '}
                    <Button variant={this.props.searchcatobj.chartType==="latest-pie" ?"primary" : "secondary"} onClick={()=>this.changeChartType("latest-pie")}>{this.props.t("PIE_CHART")}</Button>{' '}
                    <Button variant={this.props.searchcatobj.chartType==="latest-line" ?"primary" : "secondary"} onClick={()=>this.changeChartType("latest-line")}>{this.props.t("LINE_CHART")}</Button>{' '}
                </Col>
                <Col xs={12} className={this.props.searchcatobj.mainType==="trends"?"":"d-none"}>
                    <h5>Trends</h5>
                    <Button variant={this.props.searchcatobj.chartType==="trends-line" ?"primary" : "secondary"} onClick={()=>this.changeChartType("trends-line")}>{this.props.t("LINE_CHART")}</Button>
                </Col><br/> */}

                <Col xs={12} className={"mega-subitem-main"+this.props.searchcatobj.mainType!==null?"":" d-none"}>
                    <Link to="/"><Button variant="outline-primary" className="homelink-btn"><ChevronLeft size={14} /> HOME</Button></Link>
                    <h4>{this.props.searchcatobj.mainType} Charts</h4>
                    <Col className={this.props.searchcatobj.mainType==="latest"?"":"d-none"}><Row>
                        {chartList.map((item, i) =>{
                            return <React.Fragment key={i}>{item.type==="latest-bar"?<Col xs={12} md={12} lg={6} className="mega-subitem-main">
                            <Col onClick={()=> this.handleChartClick(item,null)}><BarChart2 size={24}/>{this.props.t(item.code)} 
                            <span className="chev-right"><ChevronRight size={14}/></span></Col>
                            </Col>:<></>}</React.Fragment>
                        })}
                    </Row></Col>

                    <Col className={this.props.searchcatobj.mainType==="latest"?"":"d-none"}><Row>
                        {chartList.map((item, i) =>{
                        return <React.Fragment key={i}>{item.type==="latest-pie"?<Col xs={12} md={12} lg={6} className="mega-subitem-main">
                            <Col onClick={()=> this.handleChartClick(item,null)}><PieChart size={24}/>{this.props.t(item.code)} 
                            <span className="chev-right"><ChevronRight size={14}/></span></Col>
                        </Col>:<></>}</React.Fragment>
                        })}
                    </Row></Col>

                    <Col className={this.props.searchcatobj.mainType==="latest"?"":"d-none"}><Row>
                        {chartList.map((item, i) =>{
                            return <React.Fragment key={i}>{item.type==="latest-line"?<Col xs={12} md={12} lg={6} className="mega-subitem-main">
                            <Col onClick={()=> this.handleChartClick(item,null)}><img src={linechart} style={{width:"24px",height:"24px",marginRight:"10px"}} alt="line chart"/>{this.props.t(item.code)} 
                            <span className="chev-right"><ChevronRight size={14}/></span></Col>
                            </Col>:<></>}</React.Fragment>
                        })}
                    </Row></Col>

                    <Col className={this.props.searchcatobj.mainType==="trends"?"":"d-none"}><Row>
                        {chartList.map((item, i) =>{
                            return <React.Fragment key={i}>{item.type==="trends-line"?<Col xs={12} md={12} lg={6} className="mega-subitem-main">
                            <Col onClick={()=> this.handleChartClick(item,null)}><img src={linechart} style={{width:"24px",height:"24px",marginRight:"10px"}} alt="line chart"/>{this.props.t(item.code)} 
                            <span className="chev-right"><ChevronRight size={14}/></span></Col>
                            </Col>:<></>}</React.Fragment>
                        })}
                    </Row></Col>
                </Col>

            </Modal.Body>
        </Modal>

        </>);
    }
}

const mapDispatchToProps = dispatch => ({
    SetMainType: (payload) => dispatch(setMainType(payload)),
    SetChartType: (payload) => dispatch(setChartType(payload)),
    SetChartCode: (payload) => dispatch(setChartCode(payload)),
    SetSearchFilters: (payload) => dispatch(setSearchFilters(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(ChartMapPage)));
