import React from 'react';
import Chart from "react-apexcharts";
//import { Col } from 'react-bootstrap';
import {numberWithCommas} from '../../../../_services/common.service';

const defaultColors = ['#FC5404','#54E346','#6930C3','#52057B', '#9D0191', '#FF577F','#0028FF', '#FECD1A', '#892CDC', '#1CC5DC','#FFD371','#FF449F','#54E346','#F0134D','#C299FC'];

export default function DynamicPieChart(props) {
    //const [activeSlice, setActiveSlice] = useState(undefined);

    var options = {
        chart: {
            animations:{ enabled: props.animations, speed: 600,},
            type:"donut",
            fontFamily: (props.arialfont?'Arial, sans-serif':'Poppins, sans-serif'),
            background: '#fff',
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            offsetY: -10,
            style: {
                fontSize: '15px',
                colors: undefined,
                fontFamily: (props.arialfont?'Arial, sans-serif':'Poppins, sans-serif'),
                fontWeight: '600',
            },
            background: {
                enabled: true,
                foreColor: "#283747",
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.4,
                dropShadow: {
                  enabled: false,
                  top: 1,
                  left: 1,
                  blur: 1,
                  color: '#fff',
                  opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },
        plotOptions: {
            pie:{
                //offsetX: -50,
                offsetY: 20,
                dataLabels: {
                    offset: 75,
                    minAngleToShowLabel: 5,
                },
                customScale: 0.8,
                donut: {
                    size: '40%'
                  }
            },

        },
        tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return ('<div class="arrow_box" style="border-color:'+(props.data.colors[seriesIndex])+';"><span>'+w.globals.labels[seriesIndex]+": " +series[seriesIndex] + "</span></div>");
            }
        },
        markers: {
            size: 0
        },
        labels: props.data.categories,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                width: '100%'
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        legend: {
            //show:false,
            //position: 'right',
            fontSize:"15px",
            formatter: function(label, opts) {
                return label + "  [n=" + numberWithCommas(opts.w.globals.series[opts.seriesIndex]) +"]"
                //return"<span class='legend__value'>"+opts.w.globals.series[opts.seriesIndex]+"</span><div class='legend__title'>"+label+"</div>";
            },
            //customLegendItems: (array) => { return '<label>yay</label>';},
        },
        fill: {
            // type: 'gradient',
            // gradient: {
            //     shade: 'dark',
            //     type: "horizontal",
            //     shadeIntensity: 0.2,
            //     gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            //     inverseColors: false,
            //     opacityFrom: 1,
            //     opacityTo: 1,
            //     stops: [0, 50, 100],
            //     colorStops: []
            // },
          },
          colors:props.data.colors.length>0?props.data.colors: defaultColors,
        //colors: ['#2a52be','#de6fa1','#da4040','#486090', '#00e699', '#a035ea','#35ead4', '#FF6600', '#ebc55e', '#409caf'],
    };
    var series = props.data.series;

    /* const handleActiveSlice = (isactive, cindex) => {
        if(isactive){
            setActiveSlice(cindex);
        } else{
            setActiveSlice(undefined);
        }
    } */

    //console.log(props);
    return (
        <div className="main-pie-chart">
            {/* <Col xs={9}>
                <Chart className="mchart-view" options={options} series={series} type="donut" height={400} />
            </Col>
            <Col xs={3}>
                <div className="chartview-legend">
                    <ul>
                        {props.data.categories?props.data.categories.map((xitem,xidx) => {
                            return <li key={xidx} style={{fontSize:"13px"}}><div style={{background: props.data.colors[xidx]}}></div> {xitem} <span>n={props.data.series[xidx]}</span></li>;
                        }):<></>}
                    </ul>
                </div>    
            </Col> */}
            <Chart className="mchart-view" options={options} series={series} type="donut" height={400} />
        </div>
    )


}
