import React from 'react';
import { Row, Col } from 'react-bootstrap';

import DynamicBarChart from '../charttypes/barchart';
import DynamicPieChart from '../charttypes/piechart';
import DynamicLineChart from '../charttypes/linechart-downnload';
import DynamicDistrictMap from '../charttypes/mapchart';
import logopath from '../../../../assets/img/logo512.png';

import {defaultColors} from "../../../../_services/common.service"

import './chartdownload.css';
import { withTranslation } from 'react-i18next';
class ChartDownload extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    componentDidMount(){
        this._isMounted = true;
        if(this._isMounted){
            
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    
    render(){
        let barchartdata = JSON.parse(JSON.stringify(this.props.data));
        if(this.props.charttype==="bar" && this.props.downloadType==="ppt" && this.props.chartcode!=="LBOAS"){
            if(barchartdata.series.length > 10){
                barchartdata.categories.length = 10;
                barchartdata.series.length = 10;
            }
        }
        
        let linchartdata = JSON.parse(JSON.stringify(this.props.linechartdata));
        if(this.props.charttype==="line" && this.props.downloadType==="ppt"){
            if(linchartdata.series.length>10){
                linchartdata.series.length = 10;
            }
            if(linchartdata.colors.length>10){
                linchartdata.colors.length = 10;
            }
        }
        //console.log(this.props.chartcode);
        
        let pptBottom = "0px";
        if(this.props.chartcode==="LBCRD" || this.props.chartcode==="LBOAS" || this.props.chartcode==="LBASR"){//Crude Incidence Rate
            pptBottom ="0px";
        }
        else if(this.props.chartcode==="LPPLC"){
            pptBottom ="30px";
        }
        else if(this.props.chartcode==="LLADC" || this.props.chartcode==="TLCCI" || this.props.chartcode==="TLASI"){
            if(linchartdata.series.length<=2){
                pptBottom ="0px";
            }
            else{
               pptBottom ="22px";
            }

        }
        else if(this.props.chartcode==="TLCCI"){
            pptBottom ="30px";
        }
        else if(this.props.chartcode==="TLASI"){
            pptBottom ="30px";
        }
        
        //console.log(this.props)

        // set coors
        let ccolors = [];
        if(this.props.charttype==="line"){
            if(this.props.linechartdata.colors.length>0){
                ccolors = this.props.linechartdata.colors;
            }
            else{
                ccolors = defaultColors;
            }
        }

        return (
        <>
            <Col className="main-container" style={{padding:this.props.downloadType==="ppt"?"0":"10px"}} id="onlyChartPrint">
                <Col style={{padding:this.props.downloadType==="ppt"?"0":"20px 30px 10px 0px", position:"relative"}} className={this.props.downloadType==="ppt"?"ppt":"pdf"}>
                    <h5 style={{color:"#4A235A",marginBottom:"5px", paddingLeft:"20px"}} className={this.props.downloadType==="ppts"?"d-none":""} >{this.props.title}</h5>
                    {this.props.charttype==="bar" && this.props.showChart===true ?<Col><DynamicBarChart data={barchartdata} arialfont={true} title={this.props.charttitle} axistitles={this.props.axistitles} animations={false} downloadType={this.props.downloadType} /></Col>
                    :this.props.charttype==="pie" && this.props.showChart===true ?<Col><DynamicPieChart data={this.props.data} arialfont={true} title={this.props.charttitle} animations={false}/></Col>
                    :this.props.charttype==="line" && this.props.showChart===true ?<Col><DynamicLineChart data={linchartdata} arialfont={true} title={this.props.charttitle} axistitles={this.props.axistitles} animations={false} downloadType={this.props.downloadType} /></Col>
                    :this.props.charttype==="map" && this.props.showChart===true ?<Col><DynamicDistrictMap/></Col>
                    :<Col></Col>
                    }
                    <Col className={this.props.downloadType==="ppt"?"":"d-none"} style={{ position:"absolute" , bottom:pptBottom, right:"10px"}}>
                        <h5 style={{textAlign:"center", marginBottom:"2px"}}><img src={logopath} alt="logo" style={{width:"35px"}} /></h5>
                        {/* <h5 style={{textAlign:"center", fontSize:"9px", color:"#4A235A",marginBottom:"0px"}}>{this.props.t("NCCP")}</h5>
                        <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"9px", color:"#4A235A"}}> {this.props.t("NCCP_SUB")}</h5> */}
                        <h5 style={{textAlign:"center", fontSize:"10px", color:"#4A235A",marginBottom:"0px"}}>National Cancer Registry of Sri Lanka</h5>
                        <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"8px", color:"#4A235A"}}>NATIONAL CANCER CONTROL PROGRAMME</h5>

                    </Col>
                </Col>

                
                
                {/*  */}
                <Row className={this.props.downloadType==="img" && this.props.charttype!=="line" ?"":"d-none"} style={{marginTop:this.props.charttype==="bar"?"-50px":this.props.charttype==="line"?"-80px":"-50px"}}>
                    <Col xs={8} style={{background:"transparent"}}></Col>
                    <Col xs={4} style={{ position:"relative"}}>
                        <h5 style={{textAlign:"center", marginBottom:"2px"}}><img src={logopath} alt="logo" style={{width:"35px"}} /></h5>
                        {/* <h5 style={{textAlign:"center", fontSize:"9px", color:"#4A235A",marginBottom:"0px"}}> {this.props.t("NCCP_SUB")}</h5>
                        <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"9px", color:"#4A235A"}}>{this.props.t("NCCP")}</h5> */}
                        <h5 style={{textAlign:"center", fontSize:"10px", color:"#4A235A",marginBottom:"0px"}}>National Cancer Registry of Sri Lanka</h5>
                        <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"8px", color:"#4A235A"}}>NATIONAL CANCER CONTROL PROGRAMME</h5>

                    </Col>
                </Row>

                {/*  */}
                <Row className={this.props.downloadType==="img" && this.props.charttype==="line" ? "":"d-none"} style={{marginTop:"-10px"}}>
                    <Col xs={8} style={{background:"transparent"}}>
                       <div className="custom-legend">
                           {
                               this.props.linechartdata.series.map((item, i) =>{
                                    return (
                                        <div key={i} className="main-item"><div className="dot" style={{background:ccolors[i]}}></div>{item.name}</div>
                                    )
                                }) 
                           }
                           
                       </div>
                    </Col>
                    <Col xs={4} style={{ position:"relative"}}>
                        <h5 style={{textAlign:"center", marginBottom:"2px"}}><img src={logopath} alt="logo" style={{width:"35px"}} /></h5>
                        {/* <h5 style={{textAlign:"center", fontSize:"9px", color:"#4A235A",marginBottom:"0px"}}> {this.props.t("NCCP_SUB")}</h5>
                        <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"9px", color:"#4A235A"}}>{this.props.t("NCCP")}</h5> */}
                        <h5 style={{textAlign:"center", fontSize:"10px", color:"#4A235A",marginBottom:"0px"}}>National Cancer Registry of Sri Lanka</h5>
                        <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"8px", color:"#4A235A"}}>NATIONAL CANCER CONTROL PROGRAMME</h5>

                    </Col>
                </Row>
                
                <Row className={this.props.downloadType!=="ppt" && this.props.downloadType!=="img" ?"":"d-none"} style={this.props.charttype==="bar"?{marginTop:"-15px"}:{}}>
                    <Col xs={12} style={{textAlign:"center",paddingTop:"0px",marginBottom:"0px", fontSize:"13px",paddingLeft:"40px", paddingRight:"40px"}}>
                        {this.props.langobj.code==="en"? this.props.chartDescription.engDes: 
                            this.props.langobj.code==="si"? this.props.chartDescription.sinDes:
                                this.props.langobj.code==="ta"? this.props.chartDescription.tamDes: this.props.chartDescription.engDes
                        }
                    </Col>
                </Row>

                <Row className={this.props.downloadType==="pdf"?"":"d-none"} style={{marginTop:this.props.charttype==="bar"?"0px":this.props.charttype==="line"?"0px":"0px"}}>
                    <Col xs={8} style={{background:"transparent"}}></Col>
                    <Col xs={4} style={{ position:"relative"}}>
                        <h5 style={{textAlign:"center", marginBottom:"2px"}}><img src={logopath} alt="logo" style={{width:"35px"}} /></h5>
                        {/* <h5 style={{textAlign:"center", fontSize:"9px", color:"#4A235A",marginBottom:"0px"}}> {this.props.t("NCCP_SUB")}</h5>
                        <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"9px", color:"#4A235A"}}>{this.props.t("NCCP")}</h5> */}
                        <h5 style={{textAlign:"center", fontSize:"10px", color:"#4A235A",marginBottom:"0px"}}>National Cancer Registry of Sri Lanka </h5>
                        <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"8px", color:"#4A235A"}}>NATIONAL CANCER CONTROL PROGRAMME</h5>

                    </Col>
                </Row>

               

            </Col>
        </>);
    }
}

export default withTranslation() (ChartDownload);
