import { FACTSHEET_SET } from '../constants/factSheetTypes';

const INITIAL_STATE = { selectedSheet:null };

const factSheetReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FACTSHEET_SET:
        return {
          ...state,
          selectedSheet: action.payload
        };
      default:
        return state;
    }
  };

export default factSheetReducer;