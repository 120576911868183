export const TRANSLATIONS_SI = {
    NCCP:"ජාතික පිළිකා පාලන වැඩසටහන",
    NCCP_SUB:"ශ්‍රී ලංකාවේ ජාතික පිළිකා ලේඛකාධිකාරය",
    CANCER: "පිළිකා",
    LATEST:"නවතම",
    TREND:"ප්‍රවණතාවයන්",
    FACT_SHEET:"කරුණු පත්‍ර",
    FORECAST:"හෙට දවස",
    ROADMAP: "සන්ධිස්ථාන",

    GENDER:"ස්ත්‍රී පුරුෂ භාවය",
    CANCER_SITE:"පිළිකා ස්ථානය",
    DISTRICT:"දිස්ත්‍රික්කය",
    MALE:"පුරුෂ",
    FEMALE:"ස්ත්‍රී",
    BOTH:"සියළු",
    General:"පොදු",
    Male:"පුරුෂ",
    Female:"ස්ත්‍රී",
    Both:"සියළු",
    TOP10:"පළමු 10",
    ALL:"සියල්ල",
    NO_OF_DATA:"දත්ත සංඛ්යාව",
    RESET_FILTERS:"නැවත සකසන්න",
    BASED_ON:"පදනම",
    YEAR_RANGE:"කාල පරාසය",

    BAR_CHART:"තීරු ප්‍රස්ථාර",
    PIE_CHART:"වට ප්‍රස්ථාර",
    LINE_CHART:"රේඛා ප්‍රස්ථාර",

    LBCRD:"Crude Incidence Rate",
    LBASR:"Age Standardized Incidence Rate",
    LBOAS:"Age Specific Incidence Rate",
    LPPLC:"Proportion of Leading Cancers",
    LLADC:"Age Distribution of Leading Cancers",
    TLCCI:"Crude Cancer Incidence Rates",
    TLASI:"Age Standardized Incidence Rates",

    //chart x, y axises
    //X
    CRUDE_RATE_X:"සිදුවීම අනුපාතය/100,000 ජනගහනය",
    ASR_X:"ASR/100,000 ජනගහනය",
    AGE_SPEC_RATE_X:"නිශ්චිත වයස් අනුපාතය/100,000 ජනගහනය",
    AGE_RANGE_X:"වයස් සීමාව",
    YEAR_X:"වර්ෂය",

    //Y
    CANCER_SITES_Y:"පිළිකා",
    AGE_RANGE_Y:"වයස් සීමාව",
    INCI_RATE_Y:"සිදුවීම අනුපාතය",
    CRUDE_RATE_Y:"සිදුවීම අනුපාතය",
    ASR_Y:"ASR",

    CANNOT_LOAD_CHART: "ප්රස්ථාර දත්ත බැලිය නොහැක",
    NO_FACTSHEET_DATA: "තොරතුරු පත්‍රිකා දත්ත මූලාශ්‍ර නැත",
};