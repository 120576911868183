import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { XSquare } from 'react-feather';

import MainSearch from '../mainSearch/mainSearch';

class SearchModal extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
          searchwords: null, isshowsearch: false,
        };
    }

    async componentDidMount(){
      this._isMounted = true;

      if(this._isMounted){
        if(this.props.searchQuery){
          this.setState({ searchwords: this.props.searchQuery}, () => {
            this.setState({isshowsearch: true});
          });
        } else{
          this.setState({isshowsearch: true});
        }
      }
    }

    componentWillUnmount(){
      this._isMounted = false;
    }
    
    render() {
      return (
        <>
          <Modal show={this.props.showsearchmodal} animation={false} backdrop="static" keyboard={false} className="mainsearch-modal" onHide={this.props.handleCloseSearch}>  {/* */}
            <span className="close-modal-link" onClick={this.props.handleCloseSearch}><XSquare size={35}/></span>
            <Modal.Body>
              {this.state.isshowsearch?<MainSearch isbodysearch={this.props.isbodysearch?this.props.isbodysearch:false} handleCloseSearch={this.props.handleCloseSearch} latestYear={this.props.latestYear} cancerSites={this.props.cancerSites} searchwords={this.state.searchwords} ismodalview={this.state.isshowsearch}/>:<></>}
            </Modal.Body>
          </Modal>
        </>
      );
    }
}

export default withTranslation()(withRouter(SearchModal));
