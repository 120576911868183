import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Breadcrumb, Row, Col, Container, Card} from 'react-bootstrap';
import { Home,  Clock, FileText, Copy, CheckCircle} from 'react-feather';//Clock, FileText, Copy

import sal from 'sal.js';

import './method.css';
import { withTranslation } from 'react-i18next';
import processImg from '../../assets/img/method-flow.png'
import footerlogo from '../../assets/img/footer-logo.png';
import aitdevlogo from '../../assets/img/ait_developers.png';

const salstyle = {
    "--sal-duration": "0.8s",
}

class NoMatchComponent extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            
        }
    }
      
    componentDidMount(){
        this._isMounted = true;
        sal({threshold: 0, once: true});
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        return (<>
            
            <Col xs={12} className="main-inner-content">
                <Col xs={12} className="main-content" style={{paddingTop:"85px"}}>
                    <Container>
                        <Breadcrumb style={{marginBottom:"25px",marginLeft:"-4px"}}>
                            <Link to="/" className="breadcrumb-item" style={{marginTop:"-3px"}}><Home size={14}/></Link>
                            <Breadcrumb.Item active>METHOD</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Card className="method-card" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle}>
                            <Card.Body>
                                <h4 className="main-title text-center"><div>Cancer Registry Information System in Sri&nbsp;Lanka</div></h4>
                                <p style={{marginBottom:"30px", marginTop:"20px"}}>National Cancer Control Programme (NCCP) maintains the National Cancer Registry of Sri&nbsp;Lanka and the Population-Based Cancer Registry&nbsp;(PBCR) for the capital district of Colombo which are the official sources of cancer incidence data for the country.</p>
                                
                                <p style={{marginBottom:"30px"}}>The process of cancer registration and data flow is summarized below.</p>

                                <h5 className="main-sub-title text-center"><div>Summary on cancer registration</div></h5>

                                <Row>
                                    <Col xs={12} md={12} lg={4} className="summary-main-col">
                                        <Col xs={12} className="summary-panel">
                                            <Col xs={12} className="summarypanel-header first">Point of Diagnosis</Col>
                                            <Col xs={12} className="main-col-summary">
                                                <Col xs={12} className="summarypanel-body-item first list topic1">
                                                    <h6><CheckCircle size={40}/></h6>
                                                    <h6>Point of Data Generation</h6>
                                                    <table style={{width:"100%"}}>
                                                        <tbody>
                                                            <tr><td><div className="subdiv">Histopathology – about 70</div></td></tr>
                                                            <tr><td><div className="subdiv">Haematology - about 35</div></td></tr>
                                                            <tr><td><div className="subdiv">Oral Pathology laboratories - 3</div></td></tr>
                                                        </tbody>
                                                    </table>
                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic2">
                                                    <h6><Copy size={40}/></h6>
                                                    <h6>Data Collection Instrument</h6>
                                                    <h5>National Cancer Surveillance Form (H&nbsp;1256)</h5>
                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic3">
                                                    <h6><FileText size={40} /></h6> 
                                                    <h6>Data Collection Mode</h6>
                                                    <h5>Paper based / Simple excel sheet </h5>
                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic4">
                                                    <h6><Clock size={40} ></Clock></h6>
                                                    <h6>Frequency of sending to NCCP</h6>
                                                    <h5>Monthly</h5>
                                                </Col>
                                            
                                            </Col>

                                        </Col>
                                    </Col>
                                    <Col xs={12} md={12} lg={4} className="summary-main-col">
                                        <Col xs={12} className="summary-panel">
                                            <Col xs={12} className="summarypanel-header second">Point of Treatment</Col>
                                            <Col xs={12} className="main-col-summary">
                                                <Col xs={12} className="summarypanel-body-item second list topic1">
                                                    <h6><CheckCircle size={40}/></h6>
                                                    <h6>Point of Data Generation</h6>
                                                    <table style={{width:"100%"}}>
                                                        <tbody>
                                                                <tr><td><div className="subdiv">Cancer treatment centres - 25</div></td></tr>
                                                                <tr><td><div className="subdiv">Oral & Maxillo Facial Units - about 30 </div></td></tr>
                                                                <tr><td><div className="subdiv">Other units – Eg. Gastro Intestinal (GI) Units, Heapato Biliary Units etc </div></td></tr>
                                                        </tbody>
                                                    </table>

                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic2">
                                                    <h6><Copy size={40}/></h6>
                                                    <h6>Data Collection Instrument</h6>
                                                    <h5>Cancer Return Form 1 (H 1290)</h5>
                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic3">
                                                    <h6><FileText size={40}/></h6>
                                                    <h6>Data Collection Mode</h6>
                                                    <h5>CanReg 5 Software , Paper based </h5>
                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic4">
                                                    <h6><Clock size={40}/></h6>
                                                    <h6>Frequency of sending to NCCP</h6>
                                                    <h5>6 Months after registration</h5>
                                                </Col>

                                            </Col>

                                        </Col>
                                    </Col>
                                    <Col xs={12} md={12} lg={4} className="summary-main-col">
                                        <Col xs={12} className="summary-panel">
                                            <Col xs={12} className="summarypanel-header third">Point of registration of deaths</Col>
                                            <Col xs={12} className="main-col-summary">
                                                <Col xs={12} className="summarypanel-body-item third list topic1">
                                                    <h6><CheckCircle size={40}/></h6>
                                                    <h6>Point of Data Generation</h6>
                                                    <table style={{width:"100%"}}>
                                                        <tbody>
                                                                <tr><td><div className="subdiv">Divisional Death Registrars <br/> (Colombo district only)</div></td></tr>
                                                        </tbody>
                                                    </table>

                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic2">
                                                    <h6><Copy size={40}/></h6>
                                                    <h6>Data Collection Instrument</h6>
                                                    <h5>Cancer Return Form – 3</h5>
                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic3">
                                                    <h6><FileText size={40}/></h6>
                                                    <h6>Data Collection Mode</h6>
                                                    <h5>Paper based</h5>
                                                </Col>

                                                <Col xs={12} className="summarypanel-body-item topic4">
                                                    <h6><Clock size={40}/></h6>
                                                    <h6>Frequency of sending to NCCP</h6>
                                                    <h5>Monthly</h5>
                                                </Col>

                                            </Col>
                                        </Col>
                                    </Col>
                                </Row>

                                <div xs={12} className="processimgsiv">
                                    <h5 className="main-sub-title" style={{marginTop:"40px"}}><div>Process of Cancer Regisration in Sri&nbsp;Lanka</div></h5>
                                    <img src={processImg} alt="table method" />
                                </div>

                            </Card.Body>
                        </Card>
                    </Container>
                
                </Col>
            </Col>

            <Col className="main-footer text-center">
                <Container>
                    <img src={footerlogo} className="img-fluid" alt="footer logo"/>
                    <span>National Cancer Control Programme,  Sri Lanka.</span>
                    <a href="http://www.avenir.lk" target="_blank" rel="noreferrer"><img src={aitdevlogo} className="img-fluid devlogo-footer" title="Avenir IT (pvt) Ltd, Sri Lanka" alt="avenir it logo"/></a>
                </Container>
            </Col>
        </>);
    }
}

export default withTranslation()(withRouter(NoMatchComponent));