import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row,Col, Button, Modal } from 'react-bootstrap';
import { Columns, BarChart2, RefreshCw, Info } from 'react-feather'; //FileText, Image, Layout, Columns

import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import pptxgen from "pptxgenjs";
//import jsPDF from 'jspdf';
//import { dataURLToBlob } from 'blob-util';

import nnplogo from '../../../assets/img/logo512.png';
import chartlogo from '../../../assets/img/chart-logo.png';
import methodologyicon from '../../../assets/img/methology-icon.png';
import './mapview.css';

import DynamicBarChart from './charttypes/barchart';
import DynamicPieChart from './charttypes/piechart';
import DynamicLineChart from './charttypes/linechart';
import DynamicDistrictMap from './charttypes/mapchart';
import ChartTableView from '../chartTableView/chartTableView';
import ExcelChartTableView from '../chartTableView/excelChartTableView';
import ChartDownload from './chartdownload/chartdownload';
import { withTranslation } from 'react-i18next';

//import Test1 from '../chartmapView/testCharts/test1';
//import Test2 from '../chartmapView/testCharts/test2';

class ChartMapView extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
             downData:"", showdownload:false, showExcelDownload:false,downloadType:"",
             generateprintimg: null, excelImg:"", pptconfirmModalShow:false

        }
    }

    componentDidMount(){
        this._isMounted = true;
    }

    getChartImgForExcel = () =>{
        //const input = document.getElementById('mainchartdiv');
        
        //html2canvas(input,{ allowTaint : true, logging:true, useCORS: true, scrollY:0, scrollX:0 , scale:1.6, removeContainer: true,})//width:720, height:1080,
        //.then((canvas) => {
            //if(canvas!==undefined){
                //const blob = await base64Response.blob();
                //console.log(canvas.toDataURL("image/png"));
                //let b64Data = canvas.toDataURL("");
                // const contentType = 'image/png';
                //const blob = dataURLToBlob(canvas.toDataURL("image/png"));
    
    
    
               //this.setState({excelImg:blob});
               //console.log(blob);
           // }
        //});
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    print = (type) =>{
        this.setState({showdownload:true, downloadType:type}, ()=>{
            setTimeout(() => {
                if(type==="pdf"){
                    var element = document.getElementById('printArea');
                    var opt = {
                        margin:       0,
                        filename:     this.props.charttitle+'.pdf',
                        image:        { type: 'jpeg', quality: 0.98 },
                        html2canvas:  { scale: 5 },
                        jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true, precision: 8 }
                    };

                    html2pdf().set(opt).from(element).save().then(()=>{
                        this.setState({showdownload:false, downloadType:""});
                    });
                }
                else if(type==="img" || type==="generateimg"){
                    const input = document.getElementById('printArea');
                    html2canvas(input,{ allowTaint : true, logging:true, useCORS: true, scrollY:0, scrollX:0 , scale:3, removeContainer: true,})//width:720, height:1080,
                    .then((canvas) => {
                    // const imgData = canvas.toDataURL('image/png');
                    // const pdf = new jsPDF('p', 'mm', [210, 297]);
                    // pdf.addImage(imgData, 'JPEG', 0, 0);
                    // pdf.save(this.props.charttitle+".pdf");
                    if(type==="img"){
                        var link = document.createElement("a");
                        link.download = this.props.charttitle+".png";
                        link.href = canvas.toDataURL("image/png");
                        link.target = '_blank';
                        link.click();    
                    } else{
                        this.setState({ generateprintimg: canvas.toDataURL("image/png")});
                    }
                    
                    this.setState({showdownload:false});
                    });
                }
                
            }, 200);
        });
    }

    checkPPTResultCount = () =>{
        //console.log(this.props.charttype+" - "+this.props.chartcode);
        if(this.props.charttype==="bar" && this.props.chartcode!=="LBOAS"){
            if(this.props.data.series.length>10){
                this.handlePPTConfirmModal(true);
            }
            else{
                this.pptChartAsImg();
            }
        }
        else if(this.props.charttype==="line"){
            if(this.props.linechartdata.series.length>10){
                this.handlePPTConfirmModal(true);
            }
            else{
                this.pptChartAsImg();
            }
        }
        else{
            this.pptChartAsImg();
        }
    }

    handlePPTConfirmModal = (type) =>{
        this.setState({pptconfirmModalShow:type});
    }

    pptChartAsImg = () =>{
        this.handlePPTConfirmModal(false);
        this.setState({showdownload:true, downloadType:"ppt"}, ()=>{
            setTimeout(() => {
                let pptx = new pptxgen();
                let slide = pptx.addSlide();
                //let titlefontSize = 20;
                
                //slide.addText(this.props.charttitle, { x:0.5, y:0.5,w:9, fontSize:titlefontSize, color:'#4A235A' });
    
    
                const input = document.getElementById('onlyChartPrint');
                html2canvas(input,{ allowTaint : true, logging:true, useCORS: true, scrollY:0, scrollX:0 , scale:1.6, removeContainer: true,})//width:720, height:1080,
                .then((canvas) => {
                    let width=9.0; let height=5.0; let x=0.4; let y=0.3;

                    if(this.props.charttype==="bar"){
                        width=9.0; height=5.3;  x=0.4; y=0.2;
                    }
                    if(this.props.charttype==="pie"){
                        width=9.0; height=5.0; x=0.4; y=0.3;
                    }
                    if(this.props.charttype==="line"){
                        width=8.5; height=5; x=0.65; y=0.3;
                    }

                    slide.addImage({ path:canvas.toDataURL("image/png") , w:width, h:height, x:x, y:y});
                    pptx.writeFile({ fileName: this.props.charttitle+'.pptx' });
                    this.setState({showdownload:false});
                });
            }, 100);
        });
    }

    pptExport = () =>{
        let pptx = new pptxgen();
        let slide = pptx.addSlide();
        slide.addImage({ path:nnplogo , w: 0.3, h:0.3, x:7.9, y:4.6});
        slide.addText(this.props.t("NCCP"), { x:6.8, y:5.0, fontSize:9, color:'#4A235A' });
        slide.addText(this.props.t("NCCP_SUB"), { x:6.9, y:5.18, fontSize:9, color:'#4A235A' });
        //slide.addText(this.props.t("NCCP"), { x:3.3, y:0.5, fontSize:20, color:'#2980B9' });
        //slide.addText(this.props.t("NCCP_SUB"), { x:3.3, y:0.8, fontSize:14, color:'#2980B9' });
        slide.addText(this.props.charttitle, { x:1, y:0.7, fontSize:18, color:'#4A235A' });
        slide.addText([{text:this.props.charttitle, options:{ align:'center' }}], {x:1, y:4.5, fontSize:15, color:'363636' });
        //console.log(this.props.data);
        if(this.props.charttype==="bar"){
            slide.addText(this.props.axistitles.y, { x:-3, y:-1, fontSize:15,rotate: 270, color:'363636' });
            slide.addText(this.props.axistitles.x, { x:5, y:4.0, fontSize:15, color:'363636' });
            let data = [ { name: '',
                            labels: this.props.data.categories,
                            values: this.props.data.series 
                        } 
                    ]

        slide.addChart(pptx.charts.BAR, data, {showLegend:false, barDir: 'bar',chartColors: ['0077BF'], x: 1, y:0.9, w: 8, h:3});
        }
        else if(this.props.charttype==="pie"){
            let data = [ { name: '',
                            labels: this.props.data.categories,
                            values: this.props.data.series 
                        } 
                    ]

            slide.addChart(pptx.charts.PIE, data, {showLegend:true,dataLabelFormatCode: '##.##%', legendFontSize:"14",chartColors: ['0077BF','4E9D2D','ECAA00','5FC4E3','DE4216','154384','7D666A','A3C961','EF907B','9BA0A3'], x: 1, y: 0.9, w: 8, h:3});
        }
        else if(this.props.charttype==="line"){
            slide.addText(this.props.axistitles.y, {x:-3, y:-1, fontSize:15,rotate: 270, color:'363636' });
            slide.addText(this.props.axistitles.x, { x:4.5, y:4.0, fontSize:15, color:'363636' });
        
            let data = []

            this.props.linechartdata.series.forEach(element => {
                data.push(
                    { 
                        name: element.name,
                        labels: this.props.linechartdata.categories,
                        values: element.data 
                    } 
                );
            });

            slide.addChart(pptx.charts.LINE, data, {showLegend:true,legendFontSize:"14",chartColors: ['0077BF','4E9D2D','ECAA00','5FC4E3','DE4216','154384','7D666A','A3C961','EF907B','9BA0A3'], x: 1, y: 0.9, w: 8, h:3});
        }
        
        pptx.writeFile({ fileName: this.props.charttitle+'.pptx' });
    }

    //handle redirect
    handleRedirect = (rpath) => {
        this.props.history.push(rpath);
    }

    render(){
      // console.log(this.props.linechartdata);
        return (
        <>
            <Col className="main-filter-content">
                <h5>{this.props.charttitle}</h5>
                {this.props.showChart?<><ul className="table-toggle-list list-inline float-right" style={{marginTop:"-33px",marginBottom:"0px"}}>
                    <li className="list-inline-item"><Button variant={this.props.viewtype==="chart"?"primary":"secondary"} size="sm" onClick={()=>this.props.changeviewtype("chart")} style={{marginRight:"-5px"}} title="Chart View"><BarChart2 size={15} /></Button></li>
                    <li className="list-inline-item"><Button variant={this.props.viewtype==="table"?"primary":"secondary"} size="sm" onClick={()=>this.props.changeviewtype("table")} title="Table View"><Columns size={15} /></Button></li>
                </ul>
                <hr style={{marginBottom:"0px"}}/></>:<></>}
                {/* <img src={this.state.excelImg} /> */}
                {
                    (this.props.viewtype==="chart"?
                    <Col className="mainchart-overlay">
                        <Col id="mainchartdiv" style={{minWidth:"550px"}}>
                            {this.props.charttype==="bar" && this.props.showChart===true ?<Col><DynamicBarChart data={this.props.data} title={this.props.charttitle} axistitles={this.props.axistitles} animations={true}/></Col>
                            :this.props.charttype==="pie" && this.props.showChart===true ?<Col><DynamicPieChart data={this.props.data} title={this.props.charttitle} animations={true} /></Col>
                            :this.props.charttype==="line" && this.props.showChart===true ?<Col><DynamicLineChart data={this.props.linechartdata} title={this.props.charttitle} axistitles={this.props.axistitles} animations={true} /></Col>
                            :this.props.charttype==="map" && this.props.showChart===true ?<Col><DynamicDistrictMap/></Col>
                            :<Col></Col>
                            }
                            <h6 className={this.props.showChart===true?"chartdestxt":"d-none chartdestxt"} >{this.props.langobj.code==="en"? this.props.chartDescription.engDes: 
                                this.props.langobj.code==="si"? this.props.chartDescription.sinDes:
                                this.props.langobj.code==="ta"? this.props.chartDescription.tamDes: this.props.chartDescription.engDes
                            }</h6>
                        </Col>
                    </Col>
                    :this.props.viewtype==="table"?
                    <Col className="main-table-view-div">
                        <ChartTableView generateprintimg={this.state.generateprintimg} title={this.props.charttitle} chartcode={this.props.chartcode}  tabledata={this.props.tabledata} axistitles={this.props.axistitles} />
                    </Col>
                    :<Col></Col>
                    )
                }

                
                {/* {this.props.showChart===true?<Test1 charttype={this.props.charttype} data={this.props.data} linedata={this.props.amLineData} sitearray={this.props.sitearray} />:<></>} */}

                {this.props.showChart===true?<ul className="download-links-list">
                <li onClick={()=>this.print("pdf")}><div className={"downloadbutton"} ><div className="downloadopt pdf"></div><p>PDF</p></div></li>
                <li onClick={()=>this.print("img")}><div className={"downloadbutton3"}><div className="downloadopt png"></div><p>PNG</p></div></li>
                <li style={{padding:"0px"}}><div className={"downloadbutton2"} style={{padding:"6px"}}>
                    <div className="downloadopt excel"></div><p>XLS</p>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="exceltable"
                        filename = {this.props.charttitle}
                        sheet={this.props.charttitle}
                        buttonText=""/>
                </div></li>
                <li onClick={()=>this.checkPPTResultCount()}><div className={"downloadbutton4"}><div className="downloadopt ppt"></div><p>PPT</p></div></li></ul>:<></>}
            </Col>

            {
                this.props.showChart===true ?
                    <Row className="chart-logo-main-div">
                        <Col xs={2} style={{position:"relative"}}>
                            {/* <label style={{position:"absolute", left:"10px", bottom:"5px"}} title="Methodology" className="method-redirect-btn" onClick={() => this.handleRedirect("/method")}><Info size={20} /></label> */}
                            <img src={methodologyicon} onClick={() => this.handleRedirect("/method")} className="method-redirect-img" title="Methodology" alt="logo" style={{width:"40px",position:"absolute", left:"10px", bottom:"5px"}} />
                        </Col>
                        <Col xs={10} style={{textAlign:"right"}}>
                            <img src={chartlogo} className="chart-logo-img" width="190px" alt="chart-logo"/>
                            {/* <div>

                                <h5 style={{textAlign:"center", marginBottom:"2px"}}><img src={nnplogo} alt="logo" style={{width:"35px"}} /></h5>
                                <h5 style={{textAlign:"center", fontSize:"10px", color:"#4A235A",marginBottom:"0px"}}>National Cancer Registry of Sri Lanka</h5>
                                <h5 style={{textAlign:"center", marginTop:"2px",marginBottom:"2px", fontSize:"8px", color:"#4A235A"}}>NATIONAL CANCER CONTROL PROGRAMME</h5>

                            </div> */}
                        </Col>
                    </Row>
                    :<></>
            }

            <div style={{width:"0px", overflowX:"hidden"}}>
                <Col style={{opacity:"0",width: "800px"}}>{/* chart download view */}
                    {this.state.showdownload===true?
                        <Col id="printArea">
                            <ChartDownload  data={this.props.data} linechartdata={this.props.linechartdata} title={this.props.charttitle} charttype={this.props.charttype} showChart={this.props.showChart} axistitles={this.props.axistitles} downloadType={this.state.downloadType} chartDescription={this.props.chartDescription} langobj={this.props.langobj} chartcode={this.props.chartcode} />
                        </Col>
                    :<Col></Col>
                    }
                </Col>
            </div>
            
            <Col className="d-none">{/* excel download view */}
                <Col id="excelPrintArea">
                        <ExcelChartTableView chartcode={this.props.chartcode}  tabledata={this.props.tabledata} title={this.props.charttitle} chartdata={this.props.data} axistitles={this.props.axistitles} charttype={this.props.charttype} linechartdata={this.props.linechartdata} chartImg={this.state.excelImg} />
                </Col>
            </Col>
            
            {
                this.state.showdownload===true?
                <Col className="export-loading-div"><span> <RefreshCw className="loadingicon" size="18" /> Generating file, Please wait. </span></Col>
                :<></>
            }

            {/* <Modal className={"export-loading-modal "+(this.state.showdownload?"show":"")} show={this.state.showdownload} centered animation={false} backdrop="static" keyboard={false}>
                <Modal.Body style={{height:"55px"}}>
                    <Col className="export-loading-div"><span> <RefreshCw className="loadingicon" size="18" /> Generating file, Please wait. </span></Col>
                </Modal.Body>
            </Modal> */}

            <Modal className="ppt-confrim-modal" show={this.state.pptconfirmModalShow} centered animation={false} backdrop="static" keyboard={false}  onHide={()=>this.handlePPTConfirmModal(false)}>
                <Modal.Body style={{height:"55px"}}>
                    <div>Powerpoint export shows only first 10 results. Continue?</div>
                </Modal.Body>
                <Modal.Footer style={{padding:"5px"}}>
                    <Button size="sm" onClick={()=>this.handlePPTConfirmModal(false)} variant="secondary">Close</Button>
                    <Button size="sm" onClick={()=>this.pptChartAsImg()} style={{background:"#725cd3", color:"#FFF"}} >Continue</Button>
                </Modal.Footer>
            </Modal>

        </>);
    }
}

export default withTranslation()(withRouter(connect()(ChartMapView)));
