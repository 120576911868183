import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Breadcrumb, Row, Alert } from 'react-bootstrap';
import { Home } from 'react-feather';

import sal from 'sal.js';

import { submitSets } from '../UiComponents/SubmitSets';
import { submitCollection } from '../../_services/submit.service';

import './factsheetpage.css';
import { withTranslation } from 'react-i18next';

import { setFactSheet } from '../../actions/factsheets/factsheet_action';

import footerlogo from '../../assets/img/footer-logo.png';
import aitdevlogo from '../../assets/img/ait_developers.png';

const salstyle = {
    "--sal-duration": "0.8s",
}

class FactSheetPage extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            factsheetslist: [{type:1, site:"breast", url:"", color:"#de6fa1", viewtype: "BOTH"},{type:1, site: "lip, tongue & mouth", url:"", color:"#da4040"},{type:1, site: "cervix", url:"", color:"#00e699", viewtype: "SINGLE"},{type:1, site: "trachea", url:"", color:"#486090"},{type:1, site: "bronchus & lung", url:"", color:"#409caf"}
            ,{type:1, site: "thyroid", url:"", color:"#2a52be"},{type:1, site: "colon & rectum", url:"", color:"#35ead4"},{type:1, site: "oesophagus", url:"", color:"#FF6600"},{type:2, site: "population based cancer registry", url:"", color:"#a035ea", viewtype: "POP"}],
            loadedfactsheets: [], showalert: false,
            isloadingerror: false,
        }
    }

    componentDidMount(){
        this._isMounted = true;

        if(this._isMounted){
            
            this.loadFactSheetsData(); //load all factsheets
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleFSRedirect = (citem) => {
        const clang = (this.props.langobj?(this.props.langobj.languageDetails.code==="en"?"English":this.props.langobj.languageDetails.code==="si"?"Sinhala":this.props.langobj.languageDetails.code==="ta"?"Tamil":"English"):"English");
        var selectedpath = null;
        if(citem.files){
            for (let i = 0; i < citem.files.length; i++) {
                const cpdfitem = citem.files[i];
                if(cpdfitem.language === clang){
                    selectedpath = cpdfitem;
                }
            }
        }
        if(selectedpath){
            window.scrollTo({ top: 0, behavior: 'smooth' }); //scroll to top

            citem["selectedobj"] = selectedpath;
            this.props.SetFactSheet(citem);
            this.props.history.push("/factsheets/viewer");
        } else{
            this.setState({ showalert: true }, () => {
                setTimeout(() => {
                    this.setState({ showalert: false });
                }, 1000);
            });
        }
    }

    loadFactSheetsData = () => {
        submitSets(submitCollection.getAllFactSheets).then(res=> {
            //console.log(res);
            if(res.status===true && res.extra && res.extra.length > 0){
                const loadedfactsheets = [{gender:"Both",sheets:[]}, {gender:"Female",sheets:[]}, {gender:"Male",sheets:[]}, {gender:"General",sheets:[]}];
                for (let i = 0; i < res.extra.length; i++) {
                    if(res.extra[i].gender === "Female"){
                        loadedfactsheets[1].sheets.push(res.extra[i]);
                    } else if(res.extra[i].gender === "Male"){
                        loadedfactsheets[2].sheets.push(res.extra[i]);
                    } else if(res.extra[i].gender === "Both" && res.extra[i].sheetType !== "populationBased"){
                        loadedfactsheets[0].sheets.push(res.extra[i]);
                    } else{
                        loadedfactsheets[3].sheets.push(res.extra[i]);
                    }
                }
                //console.log(loadedfactsheets);
                this.setState({ loadedfactsheets: loadedfactsheets }, () => {
                    sal({once: true});
                });
            } else{
                this.setState({ isloadingerror: true });
            }
        });
    }

    render(){
        //console.log(this.props.langobj);
        const clang = (this.props.langobj?this.props.langobj.languageDetails.code:"en");

        return (<>
        <Col className="main-inner-content">

            <Alert variant="danger" className={"alertview-main "+(!this.state.showalert?"hidealert":"")} style={{marginTop:"2rem"}}>File not found</Alert>

            <Col xs={12} className="main-content" style={{paddingTop:"85px"}}>
                <Container>
                    <Col xs={12} className="middle-content fscontent-main" style={{marginBottom:"8rem"}}>
                        {/* <h3 className="middle-content-header" style={{marginTop:"5px",fontWeight:"800"}}>{this.props.t("FACT_SHEET")}</h3> */}
                        <Breadcrumb style={{marginBottom:"25px",marginLeft:"-4px"}}>
                            <Link to="/" className="breadcrumb-item" style={{marginTop:"-3px"}}><Home size={14}/></Link>
                            <Breadcrumb.Item active>{this.props.t('FACT_SHEET')}</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Col>
                            {this.state.loadedfactsheets && this.state.loadedfactsheets.length > 0?<>
                                {this.state.loadedfactsheets.map((zitem,zidx) => {
                                return <React.Fragment key={zidx}>{(zitem.sheets.length > 0?<>
                                    <div data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle}><h2 className={(zitem.gender.toLowerCase())}><div className={"fsthumb-view "}></div> {this.props.t(zitem.gender)} {this.props.t('FACT_SHEET')}</h2></div>
                                    <Row>
                                        {zitem.sheets.map((xitem,xidx) => {
                                            return <React.Fragment key={xidx}><Col xs={12} md={6} lg={4} className="card-content">
                                            <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle} onClick={() => this.handleFSRedirect(xitem)} className="subcard-main">
                                                {/* {xitem.viewtype?<div style={{width:"6px",height:"6px",borderRadius:"10px",background:"red",position:"absolute",marginTop:"-10px"}}></div>:<></>} */}
                                                <div className="thumb-view" style={{background:xitem.color}}>
                                                    <div className="thumbview-overlay"></div>
                                                    <div className={"thumbcancer-icon "+xitem.cancerSiteId}></div>
                                                    {xitem.sheetType==="singleCancer"?<><h5>{(clang==="si"?xitem.cancerSiteSinName:clang==="ta"?xitem.cancerSiteTamName:xitem.cancerSiteName)}</h5>{/* <small>{this.props.t('CANCER')}</small> */}</>
                                                    :<h5>{(clang==="si"?xitem.sinhalaName:clang==="ta"?xitem.tamilName:xitem.sheetName)}</h5>}
                                                </div>
                                                {/* <p>Provides data visualizations that present current national estimates of {xitem.sheetType==="singleCancer"?(xitem.cancerSiteName+" cancer rates."):(xitem.sheetName+".")}</p> */}    
                                            </Col></Col></React.Fragment>;
                                        })}
                                    </Row>
                                </>:<></>)}</React.Fragment>;
                                })}
                            </>:<></>}
                            {this.state.isloadingerror?<h2 className="text-center" style={{padding:"8rem 0",fontSize:"1.6rem",color:"#999"}}>{this.props.t("NO_FACTSHEET_DATA")}</h2>:<></>}
                        </Col>
                    </Col>  
                </Container>
            </Col>
        </Col>
        <Col className="main-footer text-center">
            <Container>
                <img src={footerlogo} className="img-fluid" alt="footer logo"/>
                <span>National Cancer Control Programme,  Sri Lanka.</span>
                <a href="http://www.avenir.lk" target="_blank" rel="noreferrer"><img src={aitdevlogo} className="img-fluid devlogo-footer" title="Avenir IT (pvt) Ltd, Sri Lanka" alt="avenir it logo"/></a>
            </Container>
        </Col>
        </>);
    }
}

const mapDispatchToProps = dispatch => ({
    SetFactSheet: (payload) => dispatch(setFactSheet(payload)),
  });

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(FactSheetPage)));
