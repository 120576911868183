import { MAINTYPE_SET, CHARTTYPE_SET, CHARTCODE_SET } from '../constants/searchCategoryTypes';

const INITIAL_STATE = { mainType:null, chartType:null, chartCode:null};

const searchCategoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case MAINTYPE_SET:
        return {
          ...state,
          mainType: action.payload
        };
      case CHARTTYPE_SET:
        return {
          ...state,
          chartType: action.payload
        };
      case CHARTCODE_SET:
        return {
          ...state,
          chartCode: action.payload
        };
      default:
        return state;
    }
  };

export default searchCategoryReducer;