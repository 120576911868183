import { SEARCH_FILTERS_SET, SEARCH_RESULTS_SET } from '../../constants/searchTypes';
import { MAINTYPE_SET, CHARTTYPE_SET, CHARTCODE_SET } from '../../constants/searchCategoryTypes';

export const setSearchFilters = (payload) => {
    return {
      type: SEARCH_FILTERS_SET,
      payload
    }
};

export const setSearchResults = (payload) => {
  return {
    type: SEARCH_RESULTS_SET,
    payload
  }
};

export const setMainType = (payload) => {
  return {
    type: MAINTYPE_SET,
    payload
  }
};

export const setChartType = (payload) => {
  return {
    type: CHARTTYPE_SET,
    payload
  }
};

export const setChartCode = (payload) => {
  return {
    type: CHARTCODE_SET,
    payload
  }
};