

import React from "react";
import Slider from "react-slick";
import { Col } from 'react-bootstrap';
import { Play, Pause, ChevronLeft, ChevronRight } from 'react-feather';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class RoadMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            issliderpaused: false,
            settings: {
                dots: false,
                arrows: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1100,
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 0,
                pauseOnHover: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
        }

        this.slider = React.createRef();
        this.sliderPlay = this.sliderPlay.bind(this);
        this.sliderPause = this.sliderPause.bind(this);
        this.sliderNext = this.sliderNext.bind(this);
        this.sliderPrevious = this.sliderPrevious.bind(this);
    }

    sliderPlay() {
        this.setState({issliderpaused: false});
        this.slider.slickPlay();
    }

    sliderPause() {
        this.setState({issliderpaused: true});
        this.slider.slickPause();
    }

    sliderNext() {
        this.slider.slickNext();
    }

    sliderPrevious() {
        this.slider.slickPrev();
    }

    render() {
        //console.log(this.props.langobj);
        const clang = (this.props.langobj?this.props.langobj.languageDetails.code:"en");
        //console.log(clang);

        return <>
            <Col className="slider-options" style={{ textAlign: "center", marginRight:"100px" }}>
                {this.state.issliderpaused?<button className="btn btn-secondary btn-sm" onClick={this.sliderPlay}><Play size={16}/></button>
                :<button className="btn btn-secondary btn-sm" onClick={this.sliderPause}><Pause size={16}/></button>}
            </Col>
            <Col className="slider-options" style={{ textAlign: "center" }}>
                <button className="btn btn-secondary btn-sm" onClick={this.sliderPrevious}><ChevronLeft size={16}/></button>
                <button className="btn btn-secondary btn-sm" onClick={this.sliderNext}><ChevronRight size={16}/></button>
            </Col>
            
            <Slider ref={slider => (this.slider = slider)} {...this.state.settings}>
                {this.props.roadmaplist && this.props.roadmaplist.length > 0?this.props.roadmaplist.map((ritem, ridx) => {
                    return <div key={ridx} className="timeline-carousel__item">
                    <div className="timeline-carousel__item-inner">
                        <span className="year">{ritem.year}</span><div className="pointer"></div>
                        {ritem.descriptions && ritem.descriptions.length > 0?ritem.descriptions.map((ditem,didx) => {
                            return <p key={didx}>{clang==="si"?ditem.descriptionSin:clang==="ta"?ditem.descriptionTam:ditem.description}</p>
                        }):<></>}
                    </div>
                </div>
                }):<></>}
            </Slider>
        </>;    
    };
    
}