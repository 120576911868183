import { DISTRICT_LIST_SET, CANCER_SITE_SET, ICD10_LIST_SET, TRENDS_YEARS_LIST_SET } from '../../constants/filterListTypes';

export const setDistrictListAction = (payload) => {
    return {
      type: DISTRICT_LIST_SET,
      payload
    }
};


export const setCancerSiteListAction = (payload) => {
    return {
      type: CANCER_SITE_SET,
      payload
    }
};

export const setICD10ListAction = (payload) => {
    return {
      type: ICD10_LIST_SET,
      payload
    }
};

export const setTrendYearsAction = (payload) => {
  return {
    type: TRENDS_YEARS_LIST_SET,
    payload
  }
};