import { CHART_DESCRIPTION_SET } from '../constants/chartDescriptionTypes';

const INITIAL_STATE = { chartDescriptionsList:[]};

const chartDescriptionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CHART_DESCRIPTION_SET:
        return {
          ...state,
          chartDescriptionsList: action.payload
        };
      default:
        return state;
    }
  };

export default chartDescriptionsReducer;