export const TRANSLATIONS_TA = {
    NCCP:"தேசிய புற்றுநோய் கட்டுப்பாட்டு திட்டம்",
    NCCP_SUB:"இலங்கையின் தேசிய புற்றுநோய் பதிவு",
    CANCER: "புற்றுநோய்",
    LATEST:"சமீபத்திய",
    TREND:"போக்கு",
    FACT_SHEET:"உண்மை தாள்",
    FORECAST:"நாளை",
    ROADMAP: "சாலை புள்ளிகள்",

    GENDER:"பாலினம்",
    CANCER_SITE:"புற்றுநோய் தளம்",
    DISTRICT:"மாவட்டம்",
    MALE:"ஆண்",
    FEMALE:"பெண்",
    BOTH:"இரண்டும்",
    General:"பொது",
    Male:"ஆண்",
    Female:"பெண்",
    Both:"இரண்டும்",
    TOP10:"முதல் 10",
    ALL:"அனைத்து",
    NO_OF_DATA:"தரவு எண்ணிக்கை",
    RESET_FILTERS:"மீட்டமை",
    BASED_ON:"அடித்தளம்",
    YEAR_RANGE:"கால வரையறை",

    BAR_CHART:"பார் வரைபடம்",
    PIE_CHART:"பை விளக்கப்படம்",
    LINE_CHART:"வரி விளக்கப்படம்",

    LBCRD:"Crude Incidence Rate",
    LBASR:"Age Standardized Incidence Rate",
    LBOAS:"Age Specific Incidence Rate",
    LPPLC:"Proportion of Leading Cancers",
    LLADC:"Age Distribution of Leading Cancers",
    TLCCI:"Crude Cancer Incidence Rates",
    TLASI:"Age Standardized Incidence Rates",

    //chart x, y axises
    //X
    CRUDE_RATE_X:"சோதனை உரை/100,000 உரை",
    ASR_X:"ASR / 100,000 சோதனை உரை",
    AGE_SPEC_RATE_X:"சோதனை உரை/100,000 உரை",
    AGE_RANGE_X:"சோதனை உரை",
    YEAR_X:"உரை",

    //Y
    CANCER_SITES_Y:"சோதனை உரை",
    AGE_RANGE_Y:"சோதனை உரை",
    INCI_RATE_Y:"சோதனை உரை",
    CRUDE_RATE_Y:"சோதனை உரை",
    ASR_Y:"ASR",

    CANNOT_LOAD_CHART: "வரைபடங்களின் தரவைப் பார்க்க முடியாது",
    NO_FACTSHEET_DATA: "உண்மைத் தாள்கள் தரவுத் தளம் இல்லை",
};