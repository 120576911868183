import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Breadcrumb, Button } from 'react-bootstrap';
import { Home } from 'react-feather';
//import { Document } from 'react-pdf/dist/umd/entry.webpack';

import sal from 'sal.js';

import './factsheetviewer.css';
import { withTranslation } from 'react-i18next';

import footerlogo from '../../../assets/img/footer-logo.png';
import aitdevlogo from '../../../assets/img/ait_developers.png';

const salstyle = {
    "--sal-duration": "0.8s",
}

class FactSheetViewer extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            viewurl: "", selectedsheet: null, isloaded: false,
            loadingerror: false,
        }
    }

    componentDidMount(){
        this._isMounted = true;

        if(this._isMounted){
            sal({threshold: 0, once: true});

            //console.log(this.props.facsheetobj);
            if(this.props.facsheetobj && this.props.facsheetobj.selectedSheet){
                const selectedsheet = this.props.facsheetobj.selectedSheet;
                const clang = (this.props.langobj?(this.props.langobj.languageDetails.code==="en"?"English":this.props.langobj.languageDetails.code==="si"?"Sinhala":this.props.langobj.languageDetails.code==="ta"?"Tamil":"English"):"English");

                var isloadingerror = true;
                for (let i = 0; i < selectedsheet.files.length; i++) {
                    const cpdfitem = selectedsheet.files[i];
                    if(cpdfitem.language === clang){
                        isloadingerror = false;
                    }
                }
                this.setState({ viewurl: selectedsheet.selectedobj.currentFilePath, selectedsheet: selectedsheet, loadingerror: isloadingerror, isloaded: isloadingerror});
            }
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    iframeLoaded = () => {
        //console.log("yap");
        var iframeBody = document.getElementById('viewerpdf');
        var iframeDoc = iframeBody.src;
        
        this.setState({ isloaded: true, loadingerror:((iframeDoc===null||iframeDoc === "")?true:false) }); //, loadingerror: (!iframeBody.contentDocument?true:false) 
    }

    handleViewerError = (err) => {
        //console.log(err);
    }

    render(){
        const clang = (this.props.langobj?(this.props.langobj.languageDetails.code==="en"?"English":this.props.langobj.languageDetails.code==="si"?"Sinhala":this.props.langobj.languageDetails.code==="ta"?"Tamil":"English"):"English");
        var cviewurl = null;
        if(this.props.facsheetobj.selectedSheet && this.props.facsheetobj.selectedSheet.files){
            
            for (let i = 0; i < this.props.facsheetobj.selectedSheet.files.length; i++) {
                const cpdfitem = this.props.facsheetobj.selectedSheet.files[i];
                if(cpdfitem.language === clang){
                    cviewurl = cpdfitem.currentFilePath;
                }
            }
        }
        //console.log(cviewurl);

        return (<>
        <Col className="main-inner-content">
            <Col xs={12} className="main-content" style={{paddingTop:"85px"}}>
                <Container>
                    <Col xs={12} className="middle-content" style={{marginBottom:"8rem"}}>
                        <Breadcrumb style={{position:"relative",zIndex:"2",marginLeft:"-4px"}}>
                            <Link to="/" className="breadcrumb-item" style={{marginTop:"-3px"}}><Home size={14}/></Link>
                            <Link to="/factsheets" className="breadcrumb-item">Fact Sheets</Link>
                            <Breadcrumb.Item active>Viewer</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col xs={12} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" className="card-content" style={{...salstyle,marginTop:"25px"}}>
                            <Col className="subcard-main" style={{opacity:"1"}}>
                                <Col xs={12} style={{marginBottom:"15px",position:"relative",paddingBottom:"5px",borderBottom:"1px solid #ccc"}}>
                                    <h5 className="middle-content-header" style={{marginTop:"-8px",marginLeft:"15px",fontWeight:"600",color:"#2D568C"}}>{this.state.selectedsheet?this.state.selectedsheet.sheetName:"-"}</h5>
                                    {cviewurl?<Col className="text-right factview-download-link">
                                        <a href={cviewurl} target="_blank" rel="noopener noreferrer" download><Button variant="danger" className="fact-download-link float-right" size="sm">DOWNLOAD</Button></a>
                                    </Col>:<></>}
                                </Col>
                                <Col xs={12} lg={9} className="col-centered">
                                    {/* <Document file={this.state.viewurl} onLoadError={this.handleViewerError} /> */}
                                    {cviewurl && !this.state.isloaded?<div className="lds-dual-ring"></div>:<></>}
                                    {cviewurl?<iframe id="viewerpdf" src={cviewurl} onLoad={this.iframeLoaded} onError={this.handleViewerError} style={{display:(this.state.loadingerror?"none":"block"),width:"100%",minHeight:"650px",background:"#efefef"}} title="title"></iframe>:<></>}
                                    {cviewurl && this.state.loadingerror?<h4 className="text-center" style={{padding:"10rem 15px",color:"#725cd3",background:"#efefef"}}>PDF File Not Found</h4>:<></>}
                                </Col>
                                {/* <Col xs={12} md={9} className="col-centered"><img src={this.state.viewurl} className="img-fluid" style={{border:"1px solid #ccc"}} alt="preview factsheet"/></Col> */}
                            </Col>
                        </Col>
                    </Col>  
                </Container>
            </Col>
        </Col>
        <Col className="main-footer">
            <Container>
                <img src={footerlogo} className="img-fluid" alt="footer logo"/>
                <span>National Cancer Control Programme,  Sri Lanka.</span>
                <a href="http://www.avenir.lk" target="_blank" rel="noreferrer"><img src={aitdevlogo} className="img-fluid devlogo-footer" title="Avenir IT (pvt) Ltd, Sri Lanka" alt="avenir it logo"/></a>
            </Container>
        </Col>
        </>);
    }
}

export default withTranslation()(withRouter(connect()(FactSheetViewer)));
