import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Navbar, Dropdown, Container, Row, Form } from 'react-bootstrap';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Search, BarChart2, PieChart, ChevronRight, Menu, X } from 'react-feather';

import { langList, chartList } from '../../_services/common.service';

import { setChartCode, setMainType, setSearchFilters, setChartType } from '../../actions/search/search_action';

import SearchModal from './searchmodal';

import nccplogo from '../../assets/img/nccplogo-black.png';
//import nccpsmlogo from '../../assets/img/logo_wtext.png';
import linechart from '../../assets/img/icons/line-chart.png';

class NavbarTop extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
          selectedLang: "en", issubpage: false, showsearchmodal: false,
          isShowMegaMenu: false, chartType: null, isStatePage: false, isShowMobileSidebar: false,
        };
    }

    componentDidMount(){
      this._isMounted = true;

      if(this._isMounted){
        
        //load language
        if(this.props.langobj&&this.props.langobj.languageDetails){
          //console.log(i18n.language);
          this.setState({ selectedLang: (i18n.language?i18n.language:"en") });
        }

        this.setState({ issubpage: (window.location.pathname === "/"?false:true)});
        this.props.history.listen((location, action) => {
            this.setState({ issubpage: (location.pathname === "/"?false:true)});
        });

        //console.log(this.props.searchcatobj.mainType);
        const concernedElement = document.querySelector(".navbar-mega-menu");
        document.addEventListener("mousedown", (event) => {
          if (!concernedElement.contains(event.target)) {
            this.setState({ isShowMegaMenu: false });
          }
        });

        //listen to page changes
        //this.initMegeMenu();
        this.checkisStataPage();
        this.props.history.listen((newLocation, action) => {
          //console.log(newLocation.pathname);
          setTimeout(() => {
            this.checkisStataPage();
          }, 100);
        });
      }
    }

    componentWillUnmount(){
      this._isMounted = false;
    }

    checkisStataPage = () => {
      var isstatpage = false;
      if (this.props.location.pathname === "/statistics") {
        //this.initMegeMenu();
        isstatpage = true;
      }
      this.setState({isStatePage: isstatpage});
    }

    //init mega menu
    initMegeMenu = () => {
      //console.log(this.props.searchcatobj);
      if(this.props.searchcatobj && (this.props.searchcatobj.mainType === "latest" || this.props.searchcatobj.mainType === "trends") && this.props.searchcatobj.chartCode===null){
        this.setState({ chartType: this.props.searchcatobj.mainType, isShowMegaMenu: true });
      }
    }
    //
    handleChangeLang = (cidx,evt) => {
      const selectedlang = langList[cidx];
      if(this.props.langobj.languageDetails.code !== selectedlang.code){
        this.setState({ selectedLang: selectedlang.code});
        var curlangobj = langList.find(litem => litem.code === selectedlang.code);
        if(curlangobj !== undefined){
          i18n.changeLanguage(curlangobj.code);
          localStorage.setItem("nclangobj",JSON.stringify(curlangobj));
          this.props.handleLangObj(curlangobj, this.checkUrlContains("/statistics"));
        }
      }
    }
    //toggle search modal
    handleCloseSearch = () => {
      this.setState({ showsearchmodal: !this.state.showsearchmodal });
    }
    //toggle mega menu
    handleMegaMenu = (ctype,ismobileview) => {
      if(ismobileview){
        this.props.SetMainType(ctype);
        this.props.SetChartType(null);
        this.props.SetChartCode(null);
        this.props.SetSearchFilters(null);

        this.toggleMobileSidebar(false);
        this.props.history.push("/redirect");
      } else{
        const isshowmodal = (this.props.searchcatobj && this.props.searchcatobj.mainType !== ctype?true:!this.state.isShowMegaMenu);
        this.setState({ chartType: ctype, isShowMegaMenu: isshowmodal});
        //this.props.SetMainType(ctype);
      }
      
    }
    //handle click mega menu item
    handleChartClick = (citem) => {
      this.props.SetSearchFilters(null);
      this.props.SetChartCode(citem.code);
      this.props.SetMainType(this.state.chartType);
      this.setState({chartType: null, isShowMegaMenu: false});
      this.props.history.push("/redirect");
    }
    //handle redirect
    handleRedirect = (rpath) => {
      this.setState({chartType: null, isShowMegaMenu: false});
      /* this.props.SetMainType(null);
      this.props.SetSearchFilters(null);
      this.props.SetChartCode(null); */
      this.toggleMobileSidebar(false);
      this.props.history.push(rpath);
    }
    //onblur menu
    handleCloseMegaMenu = () => {
      //console.log("yap");
    }
    //check url to active link
    checkUrlContains = (cpath) => {
      return this.props.location.pathname.includes(cpath);
    }
    //toggle mobile sidebar
    toggleMobileSidebar = (isshow) => {
      this.setState({ isShowMobileSidebar: isshow });
    }

    render() {
      var langOptList = langList.map((litem,lidx) => {
          return <option key={lidx} value={litem.code} onClick={e => this.handleChangeLang(lidx,e)} >{litem.text}</option>;
      });

      return (
        <>{/* <Alerts/> <NoteInfos/> */}
          <Col className={"navbar-main "+(this.state.issubpage?"subpage-nav":"")}>
            <Navbar bg={this.props.dmode?"dark":"light"} variant={this.props.dmode?"dark":"light"}>
              {this.state.issubpage?<Navbar.Brand>
                <span className="d-block d-lg-none mobile-menu-link">
                  <span onClick={() => this.toggleMobileSidebar(!this.state.isShowMobileSidebar)}>{this.state.isShowMobileSidebar?<X size={28}/>:<Menu size={28}/>}</span>
                </span>
                <span>
                <img src={nccplogo} className="navbar-logo" alt=""/>
                {/* <img src={nccpsmlogo} className="navbar-logo d-block d-lg-none" alt=""/> */}
              </span></Navbar.Brand>:<></>}
              <Container style={{position:"relative"}}>
                <Row>
                  <Col xs={12} style={{paddingLeft:"0px"}}>
                    {this.state.issubpage?<ul className="list-inline navbar-linklist d-none d-lg-block">
                      <li className="list-inline-item"><div onClick={() => this.handleRedirect("/")}>HOME</div></li>
                      <li className={"list-inline-item"+(this.state.isStatePage && this.props.searchcatobj && this.props.searchcatobj.mainType==="latest"?" active":"")}><div onClick={() => this.handleMegaMenu("latest")}>TODAY</div></li>
                      <li className={"list-inline-item"+(this.state.isStatePage && this.props.searchcatobj && this.props.searchcatobj.mainType==="trends"?" active":"")}><div onClick={() => this.handleMegaMenu("trends")}>OVERTIME</div></li>
                      {/* <li className={"list-inline-item"+(this.checkUrlContains("/factsheetstomorrow")?" active":"")}><div onClick={() => this.handleRedirect("/factsheets")}>TOMORROW</div></li> */}
                      <li className={"list-inline-item"+(this.checkUrlContains("/factsheets")?" active":"")}><div onClick={() => this.handleRedirect("/factsheets")}>FACT SHEETS</div></li>
                      <li className={"list-inline-item"+(this.checkUrlContains("/method")?" active":"")}><div onClick={() => this.handleRedirect("/method")}>METHODOLOGY</div></li>
                    </ul>:<></>}

                    <ul className="list-inline navbar-formlist">
                      {this.state.issubpage?<li className="list-inline-item">
                        {/* <Button variant="secondary" size="sm" onClick={this.handleCloseSearch}></Button> */}
                        <div className="navbar-search" onClick={this.handleCloseSearch}><Search size={20}/><Form.Control type="text" placeholder="Search charts" /></div>
                      </li>:<></>}
                      <li className="list-inline-item d-none">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" size="sm"> {this.props.langobj.languageDetails.text} </Dropdown.Toggle>
                          <Dropdown.Menu>{langOptList}</Dropdown.Menu>
                      </Dropdown>
                      </li>
                    </ul>
                  </Col>  
                </Row>
              </Container>
              
            </Navbar>

            <Col className={"mobile-sidebar d-block d-lg-none "+(this.state.isShowMobileSidebar?"viewsidebar":"")}>
              <ul>
                <li onClick={() => this.handleRedirect("/")}>HOME</li>
                <li className={(this.state.isStatePage && this.props.searchcatobj && this.props.searchcatobj.mainType==="latest"?" active":"")} onClick={() => this.handleMegaMenu("latest",true)}>Today</li>
                <li className={(this.state.isStatePage && this.props.searchcatobj && this.props.searchcatobj.mainType==="trends"?" active":"")} onClick={() => this.handleMegaMenu("trends",true)}>Overview</li>
                {/* <li className={(this.checkUrlContains("/factsheetstomorrow")?" active":"")} onClick={() => this.handleRedirect("/factsheets")}>Tomorrow</li> */}
                <li className={(this.checkUrlContains("/factsheets")?" active":"")} onClick={() => this.handleRedirect("/factsheets")}>Factsheets</li>
                <li className={(this.checkUrlContains("/method")?" active":"")} onClick={() => this.handleRedirect("/method")}>Methodology</li>
              </ul>
            </Col>

          </Col>
          <Col className="d-none d-md-block">
            <Col className={"navbar-mega-menu "+(this.state.issubpage && this.state.isShowMegaMenu?"":"d-none")} onBlur={ this.handleCloseMegaMenu } style={this.state.chartType==="trends"?{left:"27%"}:{}}>
              <Col className="megamenu-overlay"></Col>
              <Col className="megamenu-inner-content">
                <Col className={this.state.chartType==="latest"?"":"d-none"}><Row>
                  {chartList.map((item, i) =>{
                      return <React.Fragment key={i}>{item.type==="latest-bar"?<Col xs={12} md={6} className="mega-subitem-main">
                        <Col onClick={()=> this.handleChartClick(item)}><BarChart2 size={16}/>{this.props.t(item.code)} 
                        <span className="chev-right"><ChevronRight size={14}/></span></Col>
                      </Col>:<></>}</React.Fragment>
                  })}
                </Row></Col>

                <Col className={this.state.chartType==="latest"?"":"d-none"}><Row>
                  {chartList.map((item, i) =>{
                    return <React.Fragment key={i}>{item.type==="latest-pie"?<Col xs={12} md={6} className="mega-subitem-main">
                      <Col onClick={()=> this.handleChartClick(item)}><PieChart size={16}/>{this.props.t(item.code)} 
                      <span className="chev-right"><ChevronRight size={14}/></span></Col>
                    </Col>:<></>}</React.Fragment>
                  })}
                </Row></Col>

                <Col className={this.state.chartType==="latest"?"":"d-none"}><Row>
                    {chartList.map((item, i) =>{
                      return <React.Fragment key={i}>{item.type==="latest-line"?<Col xs={12} md={6} className="mega-subitem-main">
                        <Col onClick={()=> this.handleChartClick(item)}><img src={linechart} style={{width:"16px",height:"16px",marginRight:"10px"}} alt="line chart"/>{this.props.t(item.code)} 
                        <span className="chev-right"><ChevronRight size={14}/></span></Col>
                      </Col>:<></>}</React.Fragment>
                    })}
                </Row></Col>

                <Col className={this.state.chartType==="trends"?"":"d-none"}><Row>
                    {chartList.map((item, i) =>{
                      return <React.Fragment key={i}>{item.type==="trends-line"?<Col xs={12} md={6} className="mega-subitem-main">
                        <Col onClick={()=> this.handleChartClick(item)}><img src={linechart} style={{width:"16px",height:"16px",marginRight:"10px"}} alt="line chart"/>{this.props.t(item.code)} 
                        <span className="chev-right"><ChevronRight size={14}/></span></Col>
                      </Col>:<></>}</React.Fragment>
                    })}
                </Row></Col>
              </Col>
            </Col>
          </Col>
          
          <SearchModal latestYear={this.props.latestYear} cancerSites={this.props.cancerSites} showsearchmodal={this.state.showsearchmodal} handleCloseSearch={this.handleCloseSearch} />
        </>
      );
    }
}

const mapDispatchToProps = dispatch => ({
  SetMainType: (payload) => dispatch(setMainType(payload)),
  SetChartCode: (payload) => dispatch(setChartCode(payload)),
  SetChartType: (payload) => dispatch(setChartType(payload)),
  SetSearchFilters: (payload) => dispatch(setSearchFilters(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(NavbarTop)));
