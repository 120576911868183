import React from 'react';
import {Col, Form, ListGroup, Badge } from 'react-bootstrap';
import { Search, X, BarChart2, PieChart } from 'react-feather';

import './mainSearch.css';

import { setChartCode, setMainType, setSearchFilters } from '../../actions/search/search_action';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { chartList } from '../../_services/common.service';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';

import linechart from '../../assets/img/icons/line-chart.png';

let typeTimer = null;

class MainSearch extends React.Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
           dictionary:[
               {chartCode:"LBCRD", baseType:"CR", cancerSiteList:[], type:"latest",chartSubType:"Bar",name:"Crude Rate",searchKeyWords:["crude","chart","latest", "2019"],factSheetPath:""},
               {chartCode:"LBASR", baseType:"ASR", cancerSiteList:[], type:"latest",chartSubType:"Bar",name:"Age Standardized Rate",searchKeyWords:["Standardized","Rate","Age","2019"],factSheetPath:""},
               {chartCode:"LBOAS", baseType:"AgeSpecificRate", cancerSiteList:[], type:"latest",chartSubType:"Bar",name:"Overall Age Specific Rate",searchKeyWords:["Overall","Specific","Age","2019"],factSheetPath:""},
               {chartCode:"LPPLC", baseType:"Proportion", cancerSiteList:[], type:"latest",chartSubType:"Pie",name:"Proportion of leading cancers",searchKeyWords:["Proportion","leading","latest","2019"],factSheetPath:""},
               {chartCode:"LLADC", baseType:"AgeSpecificCount", type:"latest",chartSubType:"Line",name:"Age Distribution of Leading Cancers",searchKeyWords:["age","distribution","latest"],factSheetPath:""},

               {chartCode:"TLCCI", baseType:"CR", cancerSiteList:[], type:"trends",chartSubType:"Line",name:"Crude Cancer Incidence Rates",searchKeyWords:["Crude","incidence","trend","rate"],factSheetPath:""},
               {chartCode:"TLASI", baseType:"ASR", cancerSiteList:[], type:"trends",chartSubType:"Line",name:"Age Standardized Incidence Rates",searchKeyWords:["age","standardized","incidence","trend"],factSheetPath:""}
            ],

            searchValue:"",suggestList:[], cursor: -1,selectedItem:null,
        }

        this.searchRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        if(this._isMounted){
            //console.log(this.props);
            this.setCancerSitesForDictionary();

            document.addEventListener("mousedown", this.checkIfClickedOutside);
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
        //this.setCancerSitesForDictionary();
    }

    checkIfClickedOutside = e => {
        if (!this.props.ismodalview && this.state.suggestList.length > 0 && this.searchRef.current && !this.searchRef.current.contains(e.target)) {
          this.closeSearchSuggestList();
        }
    }

    setCancerSitesForDictionary = () =>{
        let dictionary = this.state.dictionary;
        let cancerSiteList = (this.props.cancerSites?this.props.cancerSites:[]);
        //console.log(cancerSiteList);
        
        for(let i = 0 ; i < dictionary.length; i++) {
            let dlist = [];
            for(const citem of cancerSiteList) {
                if(dictionary[i].baseType === citem.cancerSiteBaseType){
                    dlist.push({id:citem.cancerSiteId, name:citem.cancerSiteEngName, cancerSiteICD10Name:citem.cancerSiteICD10Name, genderCode:citem.cancerSiteGenderCode});
                }
            }
            dictionary[i].cancerSiteList = dlist;
        }

        this.setState({dictionary:dictionary}, () => {
            //console.log(dictionary);
            if(this.props.searchwords){
                this.setState({ searchValue: this.props.searchwords }, () => {
                    this.handleSearchFromBackend();
                });
            }
        });
    }

    handleSearchValueChange = (e) => {
        this.setState({ searchValue: e.target.value });
    }

    handleSearchKeyUp = (e,isfrombody) => {
        const cdictionlist = JSON.parse(JSON.stringify(this.state.dictionary));
        //console.log(cdictionlist);
        let serachtxt = (this.state.searchValue);
        if(serachtxt!=="" && serachtxt.length>=3){
            //console.log(serachtxt);
            let searchKeyArray = serachtxt.split(/[ ,]+/);
            let suggestList = []; let keywordArray = []; let cancersitesArray = [];
            
            for(const sitem of searchKeyArray) {
                if(sitem!=="" && sitem!==" " && sitem.length>=3){
                    for (const citem of cdictionlist) {
                        //key words
                        for (const key of citem.searchKeyWords) {
                            if(key.toLowerCase().includes(sitem.toLowerCase())){
                                keywordArray.push(citem);
                                break;
                            }
                        }
                        
                    }
                }
            }   

            //cancer site
            for(const sitem of searchKeyArray){
                if(sitem!=="" && sitem!==" " && sitem.length>=3){
                    if(keywordArray.length>0){
                        for(let x = 0; x < keywordArray.length; x++){
                            for(let y=0; y<keywordArray[x].cancerSiteList.length; y++){
                                if(keywordArray[x].cancerSiteList[y].name.toLowerCase().includes(sitem.toLowerCase()) || keywordArray[x].cancerSiteList[y].cancerSiteICD10Name.toLowerCase().includes(sitem.toLowerCase())){
                                    keywordArray[x].csitem = keywordArray[x].cancerSiteList[y];
                                    cancersitesArray.push(keywordArray[x]);
                                    break;
                                }
                            }
                        }
                    }
                    else{
                        const cdictionlist2 = JSON.parse(JSON.stringify(this.state.dictionary));
                        for (let c=0; c < cdictionlist2.length; c++) {
                            for(let y=0; y<cdictionlist2[c].cancerSiteList.length; y++){
                                if(cdictionlist2[c].cancerSiteList[y].name.toLowerCase().includes(sitem.toLowerCase()) || cdictionlist2[c].cancerSiteList[y].cancerSiteICD10Name.toLowerCase().includes(sitem.toLowerCase())){
                                    cdictionlist2[c].csitem = cdictionlist2[c].cancerSiteList[y];
                                    cancersitesArray.push(cdictionlist2[c]);
                                    break;
                                }
                            }
                        }
                    }

                }
            }
            //console.log(keywordArray);
            suggestList = (cancersitesArray.length>0?cancersitesArray:keywordArray);
            this.setState({ suggestList: suggestList }, () => {
                //console.log(suggestList);
            });
        }
        else{
            this.setState({ suggestList: [] });
        }

        const { cursor } = this.state;
        let cindx = cursor;
        // arrow up/down button should select next/previous list element
        if(e){
            if (e.keyCode === 38 && cursor > 0) {
                e.preventDefault();
                this.setState( {
                    cursor: cursor - 1
                },()=>{
                    cindx = cindx - 1;
                })
                
            } 
            else if (e.keyCode === 40 && cursor < this.state.suggestList.length - 1) {
                e.preventDefault();
                this.setState( {
                    cursor: cursor + 1
                }, ()=>{
                    cindx = cindx +1;
                })
            }
        
            //clear selected seuggestinn item
            if(e.keyCode !== 40 && e.keyCode !== 38){
                this.setState({cursor:-1, selectedItem:null});
            }

            //enter button - select suggested doctor if not undefined
            let selItm = this.state.suggestList[this.state.cursor];
            if(e.keyCode === 13 && selItm !== undefined){
                this.selectSuggestedItem(selItm);
            }
        }
    }

    searchFiledKeyUp = (e) =>{
        clearTimeout(typeTimer);
        if(e && e.keyCode === 13){
            this.handleSearchFromBackend();
        }
        else if(this.state.searchValue.length>=3){
            // let srchval = this.state.searchValue.trim().replace(/ /g, "");
            // if(srchval.length%3===0 && e.target.value!==""){
            //     this.handleSearchFromBackend();
            // }
            typeTimer =  setTimeout(() => {
                this.handleSearchFromBackend();
            }, 500);
            
        }

        if(this.state.searchValue.length<3){
            this.setState({ suggestList: [] });
        }
        
    }

    handleSearchFromBackend =()=> {
        let serachtxt = (this.state.searchValue.trim());
        let sobj = {searchKey:serachtxt, currentYear:this.props.latestYear};
        
        if(serachtxt!=="" ){
            submitSets(submitCollection.getSearchResults,sobj).then(res=> {
                if(res.status===true){
                    this.setState({ suggestList: res.extra });
                }
                
            })
        }
        else{
            this.setState({ suggestList: [] });
        }
    }

    handleSearchBlur = (e) =>{
        //this.setState({suggestList:[]});
    }

    selectSuggestedItem =(item)=>{
        this.setState({ selectedItem:item, suggestList:[]});//searchValue:item.name,
        //console.log(item);
        this.props.SetMainType(item.chartSearchType);
        this.props.SetChartCode(item.chartCode);

        
            let selChart = null;
            for(let i = 0; i<chartList.length;i++){
                if(item.chartCode === chartList[i].code){
                    selChart = chartList[i];
                }
            } 

            
            //set cancer sites
            let selCancerList = [];
            for (let i = 0; i < item.cancerSites.length; i++) {
                selCancerList.push({id:item.cancerSites[i].cancerSiteId , name:item.cancerSites[i].cancerSiteEngName , icdcode:item.cancerSites[i].cancerSiteICD10Name});
            }
            
            //set gender
            let gender = "";
            if(item.gender!==undefined){
                gender = item.gender;
            }
            else{
                gender = (selChart.filters.genderOnlyMF===true || selChart.filters.basedOnType===true?"Male":"Both");
            }
            
            //set min and max year
            let minYear = 2005; let maxYear = this.props.latestYear;
            if(item.fromYear!==undefined && item.toYear!==undefined){
                minYear = item.fromYear;
                maxYear = item.toYear;
            }
            else if(item.fromYear!==undefined && item.toYear===undefined){
                maxYear = item.fromYear;
            }
            else if(item.fromYear===undefined && item.toYear===undefined){
                minYear = 2005; maxYear = this.props.latestYear;
            }

            
            let filterobj = {
                cancerSite: selCancerList.length>0?selCancerList[0].id:0, 
                selCancerSites:selCancerList.length > 0 ? selCancerList : [{id:0,name:"All Sites",icdcode:"All Sites"}],
                district:0, 
                totalNoOfResults:selCancerList[0]?(selCancerList[0].id!==0?-1:10):10, 
                gender:gender, 
                minAge:0, maxAge:75 , 
                districtName:"", 
                cancerSiteName:"",
                basedOnType: (item.chartCode==="TLCCI" || item.chartCode==="TLASI")&& gender==="Both" ? "GENDER" :"CANCER_SITE",
                isICDCodeView:false,
                minYear:minYear, maxYear:maxYear};

                this.props.SetSearchFilters(filterobj);

        this.props.history.push("/redirect");
        if(this.props.ismodalview){
            this.props.handleCloseSearch();
        }
    }

    closeSearchSuggestList = () =>{
        this.setState({suggestList:[],searchValue:""});
        this.searchInput.focus();
    }

    render(){
        const { cursor } = this.state;
        //console.log(this.props.cancerSites);
        return(
            <Col ref={this.searchRef} className="mainSearchCol">
                <label className="search-icon"><Search/></label>
                {this.state.searchValue.length>0?
                    <label className="close-icon" onClick={this.closeSearchSuggestList} ><X/></label>:
                <></>}
                <Form.Control type="text" value={this.state.searchValue} ref={(input) => { this.searchInput = input; }} onBlur={this.handleSearchBlur} autoFocus={this.props.ismodalview?true:false} onChange={this.handleSearchValueChange} onKeyUp={this.searchFiledKeyUp} placeholder="Search statistics / Type the site of cancer"/>
                
                {this.state.suggestList.length>0?
                    <Col style={{position:"relative"}}><Col xs={12} className="suggestbox">
                        <ListGroup>
                            {
                                this.state.suggestList.map((item, i)=>{
                                    return <ListGroup.Item key={i} className={cursor === i ? 'active' : null} onMouseDown={()=>this.selectSuggestedItem(item)} >
                                        <Col>
                                        <Badge bg="secondary" className={item.chartType.toLowerCase()}>{item.chartType==="bar"?<BarChart2 size={16}/>:item.chartType==="pie"?<PieChart size={16}/>:<><img src={linechart} style={{height:"16px"}} alt=""/></>}</Badge>
                                        <h5>{item.searchName} {(item.fromYear && item.toYear ? (item.fromYear +" - "+item.toYear): (item.fromYear?item.fromYear:"") )}</h5>
                                        <small>{item.gender?(item.gender==="Both"?"Both Sexes":item.gender)+" ":""}</small>
                                        {
                                            item.chartCode==="LBOAS"?
                                            <small>{item.cancerSites[0]?(item.cancerSites[0].cancerSiteEngName.toLowerCase()+" cancers "):""}</small>:
                                            
                                            item.cancerSites.map((citem,x)=>{
                                                if(x<=5){
                                                    return <small key={x}>{citem.cancerSiteEngName + (item.cancerSites.length===(x+1) || x===5?(" cancers"):", ") }</small>
                                                } else{
                                                    return <React.Fragment key={x}/>
                                                }
                                            })
                                        }
                                        </Col>
                                    </ListGroup.Item>
                                })
                            }
                        </ListGroup>
                    </Col></Col>
                    :<></>          
                }
            </Col>
        )
    }
}

//export default MainSearch;
const mapDispatchToProps = dispatch => ({
    SetMainType: (payload) => dispatch(setMainType(payload)),
    SetChartCode: (payload) => dispatch(setChartCode(payload)),
    SetSearchFilters: (payload) => dispatch(setSearchFilters(payload)),
  });
  
  export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(MainSearch)));