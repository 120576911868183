import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col } from 'react-bootstrap';

class RedirectComponent extends React.Component {
  _isMounted = false;

  constructor(props){
    super (props);
    this.state = {

    };
  }

  async componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      //console.log(this.props.searchcatobj);
      if(this.props.searchcatobj !== null && this.props.searchcatobj.mainType!==null){
        this.props.history.push("/statistics");
      } else{
        this.props.history.push("/");
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
      return (
        <Col xs="12" md="4" className="col-centered text-center" style={{fontSize:"16px",fontWeight:"300"}}>Redirecting...</Col>
      )
  }
}

export default withRouter(RedirectComponent);
