import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_SI } from "./si/translations";
import { TRANSLATIONS_TA } from "./ta/translations";
import { TRANSLATIONS_EN } from "./en/translations";

i18n.use(initReactI18next).init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     ta: {
       translation: TRANSLATIONS_TA
     },
     si: {
        translation: TRANSLATIONS_SI
      }
   }
 });

 i18n.changeLanguage("en");
