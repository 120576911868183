import React from 'react';
import Chart from "react-apexcharts";

const defaultColors = ["#2E86C1"];

export default function DynamicBarChart(props) {
    var options = {
        chart: {
            animations:{ enabled: props.animations},
            background: '#fff',
            fontFamily: (props.arialfont?"'Arial', sans-serif":"'Poppins', sans-serif"),
            toolbar: {
                show: false,
            },
            
            /* dropShadow: {
                enabled: true,
                color: '#000',
                top: 12,
                left: 7,
                blur: 5,
                opacity: 0.1
              }, */
        },
        markers: {
            size: 0
        },
        yaxis:{
            title:{
                text:props.axistitles.y,
                offsetX:-6,
                style:{
                    fontFamily: (props.arialfont?"'Arial', sans-serif":"'Poppins', sans-serif"),
                    fontWeight: 600,
                    fontSize:'15px',
                    color:'#051429',
                }
            },
            labels:{
                rotate: 0,
                minWidth:30,
                maxWidth: 800,
                offsetX:8,
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: (props.arialfont?"'Arial', sans-serif":"'Poppins', sans-serif"),
                    fontWeight: 500,
                    cssClass: 'apexcharts-xaxis-label',
                },
                offsetY: 2,
            },
            
        },
        xaxis: {
            title:{
                text:props.axistitles.x,
                style:{
                    fontWeight: 600,
                    fontSize:'15px',
                    fontFamily: (props.arialfont?"'Arial', sans-serif":"'Poppins', sans-serif"),
                    color:'#051429',
                }
            },
            categories: props.data.categories,
            labels:{
                rotateAlways: false,
                style: {
                    colors: [],
                    fontSize: '14px',
                    fontFamily: (props.arialfont?"'Arial', sans-serif":"'Poppins', sans-serif"),
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 2,
                horizontal: true,
                columnWidth: '100%',
                barHeight: '75%',
                distributed: true,
            }
        },
        tooltip: {
            y:{
               formatter: function(val) {
                   return val;
               },
            }
       },
        grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 1,
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            },  
            row: {
                colors: undefined,
                opacity: 0.5
            },  
            column: {
                colors: undefined,
                opacity: 0.5
            },
            padding: {
                left: props.downloadType!==undefined ?35 : 10,// props.data.categories.length>18?30:30
                right: 0,
                bottom:props.downloadType!==undefined ?0 : -20
            },
        },
        fill: {
            colors: props.data.colors.length>0?props.data.colors: defaultColors,
        },    
        legend:{show: false},
        dataLabels: {
            enabled: false,
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                width: '100%'
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
    };
    var series = [{
        data: props.data.series
    }];
    //console.log(props);

    var chartheight = props.data.categories.length>18?800:450;

    return (
        <>
            <Chart className="mchart-view" options={options} series={series} type="bar" height={chartheight} />
        </>
    )


}
