import React from 'react';
import { Col,Row } from 'react-bootstrap';
import { BarChart2, PieChart, ChevronRight } from 'react-feather';

import { chartList } from '../../_services/common.service';

import linechart from '../../assets/img/icons/line-chart.png';

export default function ChangeChartView(props){
    const searchcatobj = props.searchcatobj;
    //console.log(searchcatobj);
    //handle click mega menu item
    const handleChartClick = (citem) => {
        props.SetSearchFilters(null);
        props.SetChartCode(citem.code);
        props.SetMainType(searchcatobj.mainType);
        props.history.push("/redirect");
    }

    return (<Col className="changechartview-main">
        <Row>
            {searchcatobj.mainType==="latest"?<Col xs={4} className={searchcatobj&&(searchcatobj.chartCode==="LBCRD"||searchcatobj.chartCode==="LBASR"||searchcatobj.chartCode==="LBOAS")?"active":""}>
                <Col className="thumb-link"><BarChart2 size={22}/></Col>
                <Col className="changechartview-inner-content">
                    <Col><Row style={{padding:"0px 15px"}}>
                    {chartList.map((item, i) =>{
                        return <React.Fragment key={i}>{item.type==="latest-bar"?<Col xs={12} onClick={()=> handleChartClick(item)} className="changechartview-subitem-main">
                            <Col><BarChart2 size={16}/>{props.t(item.code)} 
                            <span className="chev-right"><ChevronRight size={14}/></span></Col>
                        </Col>:<></>}</React.Fragment>
                    })}
                    </Row></Col>
                </Col>
            </Col>:<></>}
            
            {searchcatobj.mainType==="latest"?<Col xs={4} className={searchcatobj&&(searchcatobj.chartCode==="LPPLC")?"active":""}>
                <Col className="thumb-link"><PieChart size={22}/></Col>
                <Col className="changechartview-inner-content">
                    <Col><Row style={{padding:"0px 15px"}}>
                    {chartList.map((item, i) =>{
                        return <React.Fragment key={i}>{item.type==="latest-pie"?<Col xs={12} onClick={()=> handleChartClick(item)} className="changechartview-subitem-main">
                        <Col><PieChart size={16}/>{props.t(item.code)} 
                        <span className="chev-right"><ChevronRight size={14}/></span></Col>
                        </Col>:<></>}</React.Fragment>
                    })}
                    </Row></Col>
                </Col>
            </Col>:<></>}
            
            {searchcatobj.mainType==="latest"?<Col xs={4} className={searchcatobj&&(searchcatobj.chartCode==="LLADC")?"active":""}>
                <Col className="thumb-link"><img src={linechart} alt=""/></Col>
                <Col className="changechartview-inner-content">
                    <Col><Row style={{padding:"0px 15px"}}>
                        {chartList.map((item, i) =>{
                        return <React.Fragment key={i}>{item.type==="latest-line"?<Col xs={12} onClick={()=> handleChartClick(item)} className="changechartview-subitem-main">
                            <Col><img src={linechart} style={{width:"16px",height:"16px",marginRight:"10px"}} alt="line chart"/>{props.t(item.code)} 
                            <span className="chev-right"><ChevronRight size={14}/></span></Col>
                        </Col>:<></>}</React.Fragment>
                        })}
                    </Row></Col>
                </Col>
            </Col>:<></>}
            
            {searchcatobj.mainType==="trends"?<Col xs={4} className={searchcatobj&&(searchcatobj.chartCode==="TLCCI"||searchcatobj.chartCode==="TLASI")?"active":""}>
                <Col className="thumb-link"><img src={linechart} alt=""/></Col>
                <Col className="changechartview-inner-content">
                    <Col ><Row style={{padding:"0px 15px"}}>
                        {chartList.map((item, i) =>{
                        return <React.Fragment key={i}>{item.type==="trends-line"?<Col xs={12} onClick={()=> handleChartClick(item)} className="changechartview-subitem-main">
                            <Col><img src={linechart} style={{width:"16px",height:"16px",marginRight:"10px"}} alt="line chart"/>{props.t(item.code)} 
                            <span className="chev-right"><ChevronRight size={14}/></span></Col>
                        </Col>:<></>}</React.Fragment>
                        })}
                    </Row></Col>
                </Col>
            </Col>:<></>}
            
        </Row>
    </Col>);
}

