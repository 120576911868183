import React from 'react';
import { Switch, withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import './App.css';

import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "./_translations/i18n";
import { submitCollection } from './_services/submit.service';
import { submitSets } from './components/UiComponents/SubmitSets';

import NavbarTop from './components/common_layouts/navbartop';

import { langList, getCookie, setCookie } from './_services/common.service';

import LandingPage from './components/landingPage/landingpage';
import ChartMapPage from './components/chartmapPage/chartmappage';
import FactSheetPage from './components/factsheetPage/factsheetpage';
import FactSheetViewer from './components/factsheetPage/factsheetviewer/factsheetviewer';
import MethodPage from './components/methodPage/method';
import RedirectComponent from './components/common_layouts/Redirect';
//import Loading from './components/common_layouts/loading';
import NoMatchComponent from './components/nomatch/nomatch';

import { languageAction } from './actions/common/language_action';
import { dictionaryAction } from './actions/common/dictionary_actions';
import { setChartDescriptionAction } from './actions/common/chartDescription_action';
import { setDistrictListAction , setCancerSiteListAction , setICD10ListAction, setTrendYearsAction } from './actions/common/filterList_action';
import { setLatestYear, setUseColor, setCurrentDataVersion } from './actions/common/commonSettings_action';

class App extends React.Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      dmode: (localStorage.getItem("ahcdmode")?true:false), //dark mode
      cstat: null, isAvailableLazyLoading: false,
      signedobj: null, browseruserkey: null,
      isDataLoaded:false
    }

  }

  componentDidMount(){
    this._isMounted = true;

    if(this._isMounted){
      //console.log(i18n.exists("translations:en"));
      if(localStorage.getItem("nclangobj")){ //get saved language
          const clangobj = JSON.parse(localStorage.getItem("nclangobj"));
          i18n.changeLanguage(clangobj.code);

      } else{ //check browser language
        /* if(navigator && navigator.language){
          const checklang = langList.findIndex(litem => litem.code === navigator.language);
          if(checklang > -1){
            this.props.setLangObj(langList[checklang]);
            i18n.changeLanguage(navigator.language);
          }
        } */
      }

      //add class d-mode if darkmode activated
      if(this.state.dmode){
        document.body.classList.add("d-mode");
      } else{
        document.body.classList.remove("d-mode");
      }
    }

    if(getCookie("nccplk")===undefined || getCookie("nccplk")===null){
      this.loadCancerSites();
      this.getSettings();
      this.loadChartDescriptions();
      setCookie("nccplk");
    }
    else{
      //Check cancer sites
      // if(this.props.filterListState.cancerSiteList.length===0){
      //   this.loadCancerSites();
      // }
      // else{
      //   this.setState({isDataLoaded:true});
      // }

      //Check latest year
      this.getSettings()
     

      //check chart descriptions
      // if(this.props.chartDescriptionState.chartDescriptionsList.length===0){
      //   this.loadChartDescriptions();
      // }
    }

  }
  
  componentWillUnmount(){
    this._isMounted = false;
  }

  getSettings = () =>{
    submitSets(submitCollection.getSettings).then(res=> {
      if(res.status===true){
          let lyear = res.extra.currentYear;//set this from backend
          this.props.setLatestYear(lyear);
          let ylist = [];
          for (let i = 2005; i <= lyear; i++) {
            ylist.push(i);
          }
          this.props.SetTrendYears(ylist);
          this.props.setUseColor(res.extra.isUseColors);
          
          //check data version
          if(res.extra.currentDataVersion!==this.props.commonSettingsState.currentDataVersion){
            this.loadCancerSites();
            this.loadChartDescriptions();
          }
          else{
              //Check cancer sites
              if(this.props.filterListState.cancerSiteList.length===0){
                this.loadCancerSites();
              }
              else{
                this.setState({isDataLoaded:true});
              }

              //check chart descriptions
              if(this.props.chartDescriptionState.chartDescriptionsList.length===0){
                this.loadChartDescriptions();
              }
          }

          this.props.SetCurrentDataVersion(res.extra.currentDataVersion);
      }

    })

  }

  loadChartDescriptions = () => {
    submitSets(submitCollection.getChartDescriptions).then(res=> {
      if(res.status===true){
          this.props.SetChartDescriptions(res.extra)
      }
    })
  }

  loadCancerSites = () => { //load cancer Sites 
    submitSets(submitCollection.getCancerSites).then(res=> {
      if(res.status===true){
        this.props.SetCancerSites(res.extra);
      }
      this.setState({isDataLoaded:true});
    })
  }

  //dark mode toggle
  dmodeToggle = (cstate) => {
    if((cstate?!cstate:this.state.dmode)){
      document.body.classList.remove("d-mode");
      localStorage.removeItem("ahcdmode",true);
    } else{
      document.body.classList.add("d-mode");
      localStorage.setItem("ahcdmode",true);
    }

    this.setState({
      dmode: (cstate?cstate:!this.state.dmode)
    });
  }
  
  handleLangObj = (cobj, reload) => {
    //console.log(cobj);
    this.props.setLangObj(cobj);
    
    if(reload===true){setTimeout(() => {window.location.reload();}, 100);}
  }
  
  render(){
    const clangobj = (localStorage.getItem("nclangobj")?JSON.parse(localStorage.getItem("nclangobj")):undefined);
    const isRTL = i18n.dir((clangobj?clangobj.code:this.props.langState?this.props.langState.languageDetails.code:"en"));
    //const isRTL = i18n.dir("en");
    //console.log(this.props);
    return (
      <div className="App">
        <div className="wrapper-container">
          {
            this.state.isDataLoaded===true?
            <>
              <NavbarTop handleLangObj={this.handleLangObj} searchcatobj={this.props.searchCategoryState} langobj={this.props.langState} dmode={this.state.dmode} signedobj={this.props.signState} cancerSites={this.props.filterListState.cancerSiteList} latestYear={this.props.commonSettingsState.latestYear}  dmodeToggle={this.dmodeToggle}/>
              <Switch>
                <Route path="/statistics" exact><ChartMapPage searchobj={this.props.searchState} searchcatobj={this.props.searchCategoryState} filterlists={this.props.filterListState} latestYear={this.props.commonSettingsState.latestYear} usecolor={this.props.commonSettingsState.useColor} isRTL={isRTL} langobj={this.props.langState} chartDescriptions={this.props.chartDescriptionState.chartDescriptionsList}/></Route>
                <Route path="/factsheets" exact><FactSheetPage isRTL={isRTL} langobj={this.props.langState} /></Route>
                <Route path="/factsheets/viewer" exact><FactSheetViewer isRTL={isRTL} facsheetobj={this.props.factSheetState} langobj={this.props.langState} /></Route>
                <Route path="/method" exact><MethodPage isRTL={isRTL}  langobj={this.props.langState} /></Route>
                <Route path="/redirect"><RedirectComponent searchcatobj={this.props.searchCategoryState} isRTL={isRTL} langobj={this.props.langState} /></Route>
                <Route path="/" exact><LandingPage filterlists={this.props.filterListState} dictionary={this.props.dictionaryState.dictionaryList} isRTL={isRTL} langobj={this.props.langState} cancerSites={this.props.filterListState.cancerSiteList} latestYear={this.props.commonSettingsState.latestYear} /></Route>
                <Route><NoMatchComponent signedobj={this.props.signState} /></Route>
              </Switch>
            </>
            :
            <div className="preloading-div"><div className="lds-ellipsis centered"><div></div><div></div><div></div><div></div></div></div>
          }  
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setLangObj: (payload) => dispatch(languageAction(payload)),
  SetDictionary: (payload) => dispatch(dictionaryAction(payload)),
  SetDistricts: (payload) => dispatch(setDistrictListAction(payload)),
  SetCancerSites: (payload) => dispatch(setCancerSiteListAction(payload)),
  SetICD10s: (payload) => dispatch(setICD10ListAction(payload)),
  SetTrendYears: (payload) => dispatch(setTrendYearsAction(payload)),
  setLatestYear:(payload) => dispatch(setLatestYear(payload)),
  setUseColor:(payload) => dispatch(setUseColor(payload)),
  SetCurrentDataVersion:(payload) => dispatch(setCurrentDataVersion(payload)),//
  SetChartDescriptions:(payload)=>dispatch(setChartDescriptionAction(payload))
});

export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(App)));
