import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Button, Row } from 'react-bootstrap';
import { X } from 'react-feather';
import { Range } from 'rc-slider';
import Select from 'react-select';

import 'rc-slider/assets/index.css';

import './filtersview.css';
import { withTranslation } from "react-i18next";

class FiltersView extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            sliderleft:0, sliderright: 100, sliderleftwidth: 0, sliderrightwidth: 0, resetRangeVals:false, isICDCodeView:false,
        }
    }

    componentDidMount(){
        this._isMounted = true;

        if(this._isMounted){
            
        }
        //console.log(this.props.filtersData.cancerSiteList);
        
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleFilterChange = (ctype,event) => {
        //console.log(event);
        let fobj = this.props.filtervals;
        if(ctype==="cancersite"){
            /* var index1 = event.nativeEvent.target.selectedIndex;
            let name = event.nativeEvent.target[index1].text; */
            fobj.cancerSite = event.value;
            fobj.cancerSiteName = event.label;
            //console.log(fobj);
        }
        else if(ctype==="district"){
            /* var index2 = event.nativeEvent.target.selectedIndex;
            let name = event.nativeEvent.target[index2].text; */
            fobj.district = event.value;
            fobj.districtName = event.label;
        }
        else if(ctype==="gender"){
            fobj.gender = event;

            let temcsarr = []; 
            let canlist = this.props.filtersData.cancerSiteList;
            let selectedcanlist = fobj.selCancerSites;

            if(this.props.chartcode==="LBOAS"){
                let csobj = null;
                for (let s = 0; s < canlist.length; s++) {
                    if(fobj.cancerSite===canlist[s].cancerSiteId){
                        csobj = canlist[s];
                        break;
                    }
                }
                
                if(csobj!==null){
                    if(fobj.gender==="Male" && (csobj.cancerSiteGenderCode==="AS-MOC" || csobj.cancerSiteGenderCode==="COM-MOC" || csobj.cancerSiteGenderCode==="AS-ALL" || csobj.cancerSiteGenderCode==="COM-ALL") ){
                        
                    }
                    else if(fobj.gender==="Female" && (csobj.cancerSiteGenderCode==="AS-FOC" || csobj.cancerSiteGenderCode==="COM-FOC" || csobj.cancerSiteGenderCode==="AS-ALL" || csobj.cancerSiteGenderCode==="COM-ALL")){
                        
                    }
                    else if(fobj.gender==="Both"){
                        
                    }
                    else{
                        fobj.cancerSite = "AllSitesAgeSpecificRate";
                        fobj.cancerSiteName = "All Sites Combined";
                    }
                }

            }
            else{
                for (let i = 0; i < selectedcanlist.length; i++) {
                   for (let x = 0; x < canlist.length; x++) {
                       
                        if(selectedcanlist[i].id === canlist[x].cancerSiteId){
                            if(fobj.gender==="Male" && (canlist[x].cancerSiteGenderCode==="AS-MOC" || canlist[x].cancerSiteGenderCode==="COM-MOC" || canlist[x].cancerSiteGenderCode==="AS-ALL" || canlist[x].cancerSiteGenderCode==="COM-ALL") ){
                                temcsarr.push(selectedcanlist[i]);
                            }
                            else if(fobj.gender==="Female" && (canlist[x].cancerSiteGenderCode==="AS-FOC" || canlist[x].cancerSiteGenderCode==="COM-FOC" || canlist[x].cancerSiteGenderCode==="AS-ALL" || canlist[x].cancerSiteGenderCode==="COM-ALL")){
                                temcsarr.push(selectedcanlist[i]);
                            }
                            else if(fobj.gender==="Both"){
                                temcsarr.push(selectedcanlist[i]);
                            }
                        }
                       
                   }
                    
                }

            }

            if(temcsarr.length===0){
                //temcsarr.push({id:"0",name:"All Sites", icdcode:"All Sites"});
                temcsarr = ((this.props.filtersVisibility.multiCancerSites===true || this.props.filtersVisibility.basedOnType===true) && this.props.filtersVisibility.csall===true)?[{id:"0",name:"All Sites", icdcode:"All Sites"}]: (this.props.chartcode==="LLADC"?[{id:"AllSitesAgeSpecificRate",name:"All Sites Combined",icdcode:"All Sites Combined"}]:[]);
                if( fobj.totalNoOfResults === -1){
                    fobj.totalNoOfResults = 10;
                }
            }
            fobj.selCancerSites = temcsarr;
            //console.log(fobj.selCancerSites);

        }
        else if(ctype==="totres"){
            fobj.totalNoOfResults = event;
        }
        else if(ctype==="multiCancersite"){
            /* var index3 = event.nativeEvent.target.selectedIndex;
            let name = event.nativeEvent.target[index3].text; */
            //let icdcode = event.target[index3].getAttribute("data-icd");
            
            if(event.value === 0 || event.value === "0"){
                fobj.selCancerSites = [];
                fobj.totalNoOfResults = 10;
            }
            else{
                fobj.totalNoOfResults = -1;
                for (let i = 0; i < fobj.selCancerSites.length; i++) {
                    if(fobj.selCancerSites[i].id === event.value){
                        return false;
                    }
                    if(fobj.selCancerSites[i].id=== 0 || fobj.selCancerSites[i].id=== "0"){
                        fobj.selCancerSites.splice(i,1)
                    }

                    //remove all cambind
                    if(this.props.chartcode==="LLADC" && fobj.selCancerSites[i].id==="AllSitesAgeSpecificRate"){
                        fobj.selCancerSites.splice(i,1)
                    }

                } 
            }

            if(this.props.chartcode==="LLADC" && event.value==="AllSitesAgeSpecificRate"){
                fobj.selCancerSites = [];
            }

            fobj.cancerSite = "0";
            var obj = {id:event.value, name:event.label, icdcode:event.label}
            fobj.selCancerSites.push(obj);
        }
        else if(ctype==="basedon"){
            fobj.basedOnType = event;
            fobj.selCancerSites=[{id:"0",name:"All Sites", icdcode:"All Sites"}];
            if(event==="GENDER"){
                fobj.gender = "Both";
            }
            else if(event==="CANCER_SITE"){
                fobj.gender = "Male";
            }
        }
        else if(ctype==="minYear"){
            if(event.value>fobj.maxYear){
                fobj.minYear = fobj.maxYear;
            }
            else{
                fobj.minYear = event.value;
            }
        }
        else if(ctype==="maxYear"){
            if(event.value<fobj.minYear){
                fobj.maxYear = fobj.minYear;
            }
            else{
                fobj.maxYear = event.value;
            }
        }   

        this.props.mainChartLoad(fobj);
    }

    deleteMultiCanceSite = (indx) =>{
        let fobj = this.props.filtervals;
        fobj.selCancerSites.splice(indx,1);

        if(fobj.selCancerSites.length === 0){
            //fobj.selCancerSites.push({id: 0, name: "All Sites", icdcode: "All Sites"});
            fobj.selCancerSites = ((this.props.filtersVisibility.multiCancerSites===true || this.props.filtersVisibility.basedOnType===true) && this.props.filtersVisibility.csall===true)?[{id:"0",name:"All Sites", icdcode:"All Sites"}]: (this.props.chartcode==="LLADC"?[{id:"AllSitesAgeSpecificRate",name:"All Sites Combined",icdcode:"All Sites Combined"}]:[]);
            fobj.totalNoOfResults = 10;
        }

        this.props.mainChartLoad(fobj);
    }

    resetFilters= () => {
        let fobj = {cancerSite:(this.props.chartcode==="LBOAS"?"AllSitesAgeSpecificRate":"0"), 
                    selCancerSites:((this.props.filtersVisibility.multiCancerSites===true || this.props.filtersVisibility.basedOnType===true) && this.props.filtersVisibility.csall===true)?[{id:"0",name:"All Sites", icdcode:"All Sites"}]: (this.props.chartcode==="LLADC"?[{id:"AllSitesAgeSpecificRate",name:"All Sites Combined",icdcode:"All Sites Combined"}]:[]),
                    district:0, 
                    totalNoOfResults:10, 
                    gender: (this.props.filtersVisibility.genderOnlyMF===true || this.props.filtersVisibility.basedOnType===true ?"Male":"Both"), 
                    minAge:0, maxAge:75 ,
                    districtName:"", 
                    cancerSiteName:"",
                    basedOnType:"CANCER_SITE",
                    minYear:2005, maxYear:this.props.latestYear};

        this.props.mainChartLoad(fobj);
        this.setState({resetRangeVals:true},()=>{
            setTimeout(() => {
                this.setState({resetRangeVals:false});
            }, 100);
        });
    }

    handleRangeChange = (event) =>{
        let fobj = this.props.filtervals;
        fobj.minAge = event[0];
        fobj.maxAge = event[1];
        //console.log(fobj)
        this.setState({filterobj:fobj}, () =>{
           this.props.mainChartLoad(fobj);
        });
    }

    handleCancerSiteTypeChange = () =>{
        //this.setState({isICDCodeView:!this.state.isICDCodeView});
        let fobj = this.props.filtervals;
        fobj.isICDCodeView = !fobj.isICDCodeView;
        //fobj.cancerSite = (this.props.chartcode==="LBOAS"?"AllSitesAgeSpecific":"-1");
        //fobj.selCancerSites = ((this.props.filtersVisibility.multiCancerSites===true || this.props.filtersVisibility.basedOnType===true) && this.props.filtersVisibility.csall===true)?[{id:"0",name:"All Sites", icdcode:"All Sites"}]: (this.props.chartcode==="LLADC"?[{id:"AllSitesAgeSpecificRate",name:"All Sites Combined",icdcode:"All Sites Combined"}]:[]);
        //console.log(fobj);

        if(this.props.chartcode!=="LBOAS"){
            for (let x = 0; x < fobj.selCancerSites.length; x++) {
                for (let y = 0; y < this.props.filtersData.cancerSiteList.length; y++) {
                    if(this.props.filtersData.cancerSiteList[y].cancerSiteId === fobj.selCancerSites[x].id){
                        fobj.selCancerSites[x].name =  fobj.isICDCodeView===true ? this.props.filtersData.cancerSiteList[y].cancerSiteICD10Name : this.props.filtersData.cancerSiteList[y].cancerSiteEngName;
                        fobj.selCancerSites[x].icdcode = fobj.isICDCodeView===true ? this.props.filtersData.cancerSiteList[y].cancerSiteICD10Name : this.props.filtersData.cancerSiteList[y].cancerSiteEngName;
    
                    }
                }
            }
        }
        if(this.props.chartcode==="LBOAS"){
            for (let a = 0; a < this.props.filtersData.cancerSiteList.length;a++) {
                if(this.props.filtersData.cancerSiteList[a].cancerSiteId === fobj.cancerSite){
                    fobj.cancerSiteName = fobj.isICDCodeView===true ? this.props.filtersData.cancerSiteList[a].cancerSiteICD10Name : this.props.filtersData.cancerSiteList[a].cancerSiteEngName;
                }
            }
        }
        
        this.props.updateFilterObjWithoutBackCall(fobj);
        
    }

    render(){
        const vyearslist = this.props.filtersData.trendsYearsList.map((item, i) =>{
            return {value: item, label: item};
        });
        //console.log(this.props.chartBaseType,this.props.filtersData.cancerSiteList);
        const vcancersites = [];
        this.props.filtersData.cancerSiteList.filter((fitem) => fitem.cancerSiteBaseType===this.props.chartBaseType).forEach(item => {
            if(this.props.filtervals.gender==="Male" && (item.cancerSiteGenderCode==="AS-MOC" || item.cancerSiteGenderCode==="COM-MOC" || item.cancerSiteGenderCode==="AS-ALL" || item.cancerSiteGenderCode==="COM-ALL") ){
                //console.log(item);
                vcancersites.push({value: item.cancerSiteId, label: (this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteEngName : this.props.langobj.code==="ta"? item.cancerSiteEngName: item.cancerSiteEngName))});
            }
            else if(this.props.filtervals.gender==="Female" && (item.cancerSiteGenderCode==="AS-FOC" || item.cancerSiteGenderCode==="COM-FOC" || item.cancerSiteGenderCode==="AS-ALL" || item.cancerSiteGenderCode==="COM-ALL")){
                //console.log(item.cancerSiteGenderCode);
                vcancersites.push({value: item.cancerSiteId, label: (this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteEngName : this.props.langobj.code==="ta"? item.cancerSiteEngName: item.cancerSiteEngName))});
            }
            else if(this.props.filtervals.gender==="Both"){
                vcancersites.push({value: item.cancerSiteId, label: (this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteEngName : this.props.langobj.code==="ta"? item.cancerSiteEngName: item.cancerSiteEngName))});
            }
        });
        //vcancersites.unshift({value: -1, label:"All Sites"});
        if(this.props.filtersVisibility.multiCancerSites && this.props.filtersVisibility.csall===true){
            vcancersites.unshift({value: 0, label:"All Sites"});
        }
        //console.log(vcancersites);

        return (<>
        <Col className="main-filter-content">

            <Col className={this.props.filtersVisibility.basedOnType===true?"single-filter-content":"single-filter-content d-none "}>
                <label className="filter-label">{this.props.t("BASED_ON")}</label>
                {/* <Col>
                    <Button variant={this.props.filtervals.basedOnType==="CANCER_SITE" ?"primary" : "secondary"} size="sm" onClick={e => this.handleFilterChange("CANCER_SITE","basedon")} style={{width:"48%",marginRight:"5px"}}>{this.props.t("CANCER_SITE")}</Button>
                    <Button variant={this.props.filtervals.basedOnType==="GENDER" ?"primary" : "secondary"} size="sm" onClick={e => this.handleFilterChange("GENDER","basedon")} style={{width:"48%"}}>{this.props.t("GENDER")}</Button>
                </Col> */}
                <Col className="form-check-main">
                    <Row>
                        <Col xs={6} style={{paddingRight:"0px"}}>
                            <div className={"form-check "+ (this.props.filtervals.basedOnType==="CANCER_SITE" ?"active":"")}>
                                <input type="radio" id={this.props.t("CANCER_SITE")} className="form-check-input" checked={this.props.filtervals.basedOnType==="CANCER_SITE" ?true:false} onChange={e => this.handleFilterChange("basedon","CANCER_SITE")} />
                                <label htmlFor={this.props.t("CANCER_SITE")} className="form-check-label">{/* <div className="filter-category"></div> */} {this.props.t("CANCER_SITE")}</label>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className={"form-check "+ (this.props.filtervals.basedOnType==="GENDER" ?"active":"")}>
                                <input type="radio" id={this.props.t("GENDER")} className="form-check-input" checked={this.props.filtervals.basedOnType==="GENDER" ?true:false} onChange={e => this.handleFilterChange("basedon","GENDER")} />
                                <label htmlFor={this.props.t("GENDER")} className="form-check-label">{/* <div className="filter-category gender"></div> */} {this.props.t("GENDER")}</label>
                            </div>
                        </Col>
                    </Row>    
                </Col>
            </Col>
            
            <Col className={this.props.filtersVisibility.cancerSite===true?"single-filter-content":"single-filter-content d-none "}>
                <label className="filter-label" style={{width:"100%"}}>
                    {this.props.t("CANCER_SITE")}
                    <span className="cancersiye-changetxt">ICD Code
                        <div className="switch">
                            <input type="checkbox" checked={this.props.filtervals.isICDCodeView} onChange={()=>this.handleCancerSiteTypeChange()}/>
                            <label><span className="fontawesome-ok"></span><span className="fontawesome-remove"></span><div></div></label>
                        </div>
                    </span>
                    
                </label>
                {/* <Form.Control as="select" size="sm" value={this.props.filtervals.cancerSite} onChange={e => this.handleFilterChange(e.target.value,"cancersite",e)}>
                    <option value={0}>-</option>
                    <option value={-1} className={this.props.filtersVisibility.csall===false?"d-none":""}>All Sites</option>
                    {
                        this.props.filtersData.cancerSiteList.map((item, i) =>{
                            if(item.cancerSiteBaseType===this.props.chartBaseType){
                                
                                if(this.props.filtervals.gender==="Male" && (item.cancerSiteGenderCode==="AS-MOC" || item.cancerSiteGenderCode==="COM-MOC" || item.cancerSiteGenderCode==="AS-ALL" || item.cancerSiteGenderCode==="COM-ALL") ){
                                    //console.log(item.cancerSiteGenderCode);
                                    return (
                                        <option key={i} value={item.cancerSiteId}>
                                            { this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteSinName : this.props.langobj.code==="ta"? item.cancerSiteTamName: item.cancerSiteEngName)}
                                        </option>
                                    )
                                }
                                else if(this.props.filtervals.gender==="Female" && (item.cancerSiteGenderCode==="AS-FOC" || item.cancerSiteGenderCode==="COM-FOC" || item.cancerSiteGenderCode==="AS-ALL" || item.cancerSiteGenderCode==="COM-ALL")){
                                    //console.log(item.cancerSiteGenderCode);
                                    return (
                                        <option key={i} value={item.cancerSiteId}>
                                            { this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteSinName : this.props.langobj.code==="ta"? item.cancerSiteTamName: item.cancerSiteEngName)}
                                        </option>
                                    )
                                }
                                else{
                                    return (
                                        <option key={i} value={item.cancerSiteId}>
                                            { this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteSinName : this.props.langobj.code==="ta"? item.cancerSiteTamName: item.cancerSiteEngName)}
                                        </option>
                                    )
                                }
                            } else{
                                return <React.Fragment key={i}></React.Fragment>
                            }
                        })
                    }
                </Form.Control> */}
                <Select options={vcancersites} placeholder="Select Site" className="filterselmain" classNamePrefix="filterselect" value={vcancersites.find((fitem) => fitem.value === this.props.filtervals.cancerSite)} onChange={e => this.handleFilterChange("cancersite",e)} />
            </Col>

            <Col className={this.props.filtersVisibility.multiCancerSites===true || (this.props.filtersVisibility.basedOnType===true && this.props.filtervals.basedOnType==="CANCER_SITE") ?"single-filter-content":"single-filter-content d-none "}>{/* multiple cance sites */}
                <label className="filter-label" style={{width:"100%"}}>
                    {this.props.t("CANCER_SITE")}
                    <span className="cancersiye-changetxt">ICD Code 
                        <div className="switch">
                            <input type="checkbox" checked={this.props.filtervals.isICDCodeView} onChange={()=>this.handleCancerSiteTypeChange()}/>
                            <label><span className="fontawesome-ok"></span><span className="fontawesome-remove"></span><div></div></label>
                        </div>
                    </span>
                </label>
                {/* <Select options={[]} /> */}
                {/* <Form.Control as="select" size="sm" value={"-1"} onChange={e => this.handleFilterChange(e.target.value,"multiCancersite",e)}>
                    <option value={-1}>-</option>
                    <option value={"0"} className={this.props.filtersVisibility.csall===false?"d-none":""}>All Sites</option>
                    {
                        this.props.filtersData.cancerSiteList.map((item, i) =>{
                            if(item.cancerSiteBaseType===this.props.chartBaseType){
                                if(this.props.filtervals.gender==="Male" && (item.cancerSiteGenderCode==="AS-MOC" || item.cancerSiteGenderCode==="COM-MOC" || item.cancerSiteGenderCode==="AS-ALL" || item.cancerSiteGenderCode==="COM-ALL") ){
                                    //console.log(item.cancerSiteGenderCode);
                                    return (
                                        <option key={i} value={item.cancerSiteId}>
                                            { this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteSinName : this.props.langobj.code==="ta"? item.cancerSiteTamName: item.cancerSiteEngName)}
                                        </option>
                                    )
                                }
                                else if(this.props.filtervals.gender==="Female" && (item.cancerSiteGenderCode==="AS-FOC" || item.cancerSiteGenderCode==="COM-FOC" || item.cancerSiteGenderCode==="AS-ALL" || item.cancerSiteGenderCode==="COM-ALL")){
                                    //console.log(item.cancerSiteGenderCode);
                                    return (
                                        <option key={i} value={item.cancerSiteId}>
                                            { this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteSinName : this.props.langobj.code==="ta"? item.cancerSiteTamName: item.cancerSiteEngName)}
                                        </option>
                                    )
                                }
                                else{
                                    return (
                                        <option key={i} value={item.cancerSiteId}>
                                            { this.props.filtervals.isICDCodeView===true?item.cancerSiteICD10Name:(this.props.langobj.code==="si"? item.cancerSiteSinName : this.props.langobj.code==="ta"? item.cancerSiteTamName: item.cancerSiteEngName)}
                                        </option>
                                    )
                                }
                            } else{
                                return <React.Fragment key={i}></React.Fragment>
                            }
                        })
                    }

                </Form.Control> */}
                <Select options={vcancersites} placeholder="Select Sites" className="filterselmain" classNamePrefix="filterselect" value={-1}  onChange={e => this.handleFilterChange("multiCancersite",e)} />
                <Col xs={12} style={{paddingTop:"5px"}}>
                    {
                        this.props.filtervals.selCancerSites.map((item, i) =>{
                            return (
                                <span key={i} className="multiCanceSiteItem"><X color="black" size={12} onClick={()=>this.deleteMultiCanceSite(i)} /> 
                                { this.props.filtervals.isICDCodeView===true?item.icdcode:item.name}
                                </span> 
                            )
                        }) 
                    }
                </Col>
            </Col>

            {/* <Col className={this.props.filtersVisibility.district===true?"single-filter-content":"single-filter-content d-none "}>
                <label className="filter-label">{this.props.t("DISTRICT")}</label>
                <Form.Control as="select" size="sm" value={this.props.filtervals.district} onChange={e => this.handleFilterChange(e.target.value,"district",e )}>
                    <option value={0}>-</option>
                    {
                        this.props.filtersData.districtList.map((item, i) =>{
                            return <option key={i} value={item.disId}>{(this.props.langobj.code==="si"? item.disSinName : this.props.langobj.code==="ta"? item.disTamName: item.disEngName)}</option>
                        })
                    }
                </Form.Control>
            </Col> */}
            {/* <Col className={this.props.filtersVisibility.counts===true?"single-filter-content":"single-filter-content d-none "}>
                <label className="filter-label">Counts</label>
                <Col>
                    <Button variant="primary" size="sm" onClick={e => this.handleFilterChange(1,"counts")} style={{width:"48%",marginRight:"5px"}}>Incidents</Button>
                    <Button variant="secondary" size="sm" onClick={e => this.handleFilterChange(2,"counts")} style={{width:"48%"}}>Mortality</Button>
                </Col>
            </Col> */}
            <Col className={this.props.filtersVisibility.gender===true || (this.props.filtersVisibility.basedOnType===true) ?"single-filter-content":"single-filter-content d-none "}>
                <label className="filter-label">{this.props.t("GENDER")}</label>
                {/* <Col>
                    <Button variant={this.props.filtervals.gender==="Both"?"primary":"secondary"} size="sm" className={this.props.filtersVisibility.genderOnlyMF===true?"d-none":""} onClick={e => this.handleFilterChange("Both","gender")} style={{width:"31%",marginRight:"5px"}}>{this.props.t("BOTH")}</Button>
                    <Button variant={this.props.filtervals.gender==="Male"?"primary":"secondary"} size="sm" onClick={e => this.handleFilterChange("Male","gender")} style={{width:"31%", marginRight:"5px"}}>{this.props.t("MALE")}</Button>
                    <Button variant={this.props.filtervals.gender==="Female"?"primary":"secondary"} size="sm" onClick={e => this.handleFilterChange("Female","gender")} style={{width:"31%"}}>{this.props.t("FEMALE")}</Button>
                </Col> */}
                <Col className="form-check-main">
                    {!this.props.filtersVisibility.genderOnlyMF?<Row>
                        <Col xs={4} className={this.props.filtersVisibility.genderOnlyMF===true || (this.props.filtersVisibility.basedOnType===true && this.props.filtervals.basedOnType==="CANCER_SITE" ) ?"d-none":""} style={{paddingRight:"0px"}}>
                            <div className={"form-check "+ (this.props.filtervals.gender==="Both" ?"active":"") }>
                                <input type="radio" id={this.props.t("BOTH")} className="form-check-input" checked={this.props.filtervals.gender==="Both"?true:false} onChange={e => this.handleFilterChange("gender","Both")} />
                                <label htmlFor={this.props.t("BOTH")} className="form-check-label">{/* <div className="filter-gender"></div> */} {this.props.t("BOTH")}</label>
                            </div>
                        </Col>
                        <Col xs={(this.props.filtersVisibility.basedOnType===true && this.props.filtervals.basedOnType==="CANCER_SITE" )?6:4} style={(this.props.filtersVisibility.basedOnType===true && this.props.filtervals.basedOnType==="CANCER_SITE" )?{paddingRight:"0px"}:{paddingRight:"0px",paddingLeft:"5px"}}>
                            <div className={"form-check "+ (this.props.filtervals.gender==="Male" ?"active":"")}>
                                <input type="radio" id={this.props.t("MALE")} className="form-check-input" checked={this.props.filtervals.gender==="Male"?true:false} onChange={e => this.handleFilterChange("gender","Male")} />
                                <label htmlFor={this.props.t("MALE")} className="form-check-label">{/* <div className="filter-gender male"></div> */} {this.props.t("MALE")}</label>
                            </div>
                        </Col>
                        <Col xs={(this.props.filtersVisibility.basedOnType===true && this.props.filtervals.basedOnType==="CANCER_SITE" )?6:4} style={(this.props.filtersVisibility.basedOnType===true && this.props.filtervals.basedOnType==="CANCER_SITE" )?{}:{paddingLeft:"5px"}}>
                            <div className={"form-check "+ (this.props.filtervals.gender==="Female" ?"active":"")}>
                                <input type="radio" id={this.props.t("FEMALE")} className="form-check-input" checked={this.props.filtervals.gender==="Female"?true:false} onChange={e => this.handleFilterChange("gender","Female")} />
                                <label htmlFor={this.props.t("FEMALE")} className="form-check-label">{/* <div className="filter-gender female"></div> */} {this.props.t("FEMALE")}</label>
                            </div>
                        </Col>
                    </Row>:<Row>
                        <Col xs={6} style={{paddingRight:"0px"}}>
                            <div className={"form-check "+ (this.props.filtervals.gender==="Male" ?"active":"")}>
                                <input type="radio" id={this.props.t("MALE")} className="form-check-input" checked={this.props.filtervals.gender==="Male"?true:false} onChange={e => this.handleFilterChange("gender","Male")} />
                                <label htmlFor={this.props.t("MALE")} className="form-check-label">{/* <div className="filter-gender male"></div> */} {this.props.t("MALE")}</label>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className={"form-check "+ (this.props.filtervals.gender==="Female" ?"active":"")}>
                                <input type="radio" id={this.props.t("FEMALE")} className="form-check-input" checked={this.props.filtervals.gender==="Female"?true:false} onChange={e => this.handleFilterChange("gender","Female")} />
                                <label htmlFor={this.props.t("FEMALE")} className="form-check-label">{/* <div className="filter-gender female"></div> */} {this.props.t("FEMALE")}</label>
                            </div>
                        </Col>
                    </Row>}    
                </Col>
            </Col>

            <Col className={this.props.filtersVisibility.ageRange===true?"single-filter-content":"single-filter-content d-none "}>
                <label className="filter-label" style={{marginBottom:"10px"}}>Age Groups</label>
                {
                    this.state.resetRangeVals===false?
                        <Range min={0} max={90} defaultValue={[this.props.filtervals.minAge, this.props.filtervals.maxAge]} marks={{0:0, 15: 15, 30: 30, 45:45, 60:60, 75: 75, 90:90 }} step={15} allowCross={false} onAfterChange={(e)=> this.handleRangeChange(e)}/>
                    :<Col></Col>
                }
                <br/>
            </Col>
            <Col className={this.props.filtersVisibility.topResults===true || (this.props.filtersVisibility.basedOnType===true && this.props.filtervals.basedOnType==="CANCER_SITE") ?"single-filter-content":"single-filter-content d-none "}>
                <label className="filter-label">{this.props.t("NO_OF_DATA")}</label>
                {/* <Col>
                    <Button variant={this.props.filtervals.totalNoOfResults===10 ?"primary" : "secondary"} size="sm" onClick={e => this.handleFilterChange(10,"totres")} style={{width:"48%",marginRight:"5px"}}>{this.props.t("TOP10")}</Button>
                    <Button variant={this.props.filtervals.totalNoOfResults===0 ?"primary" : "secondary"} size="sm" onClick={e => this.handleFilterChange(0,"totres")} style={{width:"48%"}}>{this.props.t("ALL")}</Button>
                </Col> */}
                <Col className="form-check-main">
                    <Row>
                        <Col xs={6} style={{paddingRight:"0px"}}>
                            <div className={"form-check "+ (this.props.filtervals.totalNoOfResults===10 ?"active":"")}>
                                <input type="radio" id={this.props.t("TOP10")} className="form-check-input" disabled={this.props.filtervals.selCancerSites[0]?(parseInt(this.props.filtervals.selCancerSites[0].id)!==0?true:false):false} checked={this.props.filtervals.totalNoOfResults===10?true:false} onChange={e => this.handleFilterChange("totres",10)} />
                                <label htmlFor={this.props.t("TOP10")} className="form-check-label">{/* <div className="filter-results"></div> */} {this.props.t("TOP10")}</label>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className={"form-check "+ (this.props.filtervals.totalNoOfResults===0 ?"active":"")}>
                                <input type="radio" id={this.props.t("ALL")} className="form-check-input" disabled={this.props.filtervals.selCancerSites[0]?(parseInt(this.props.filtervals.selCancerSites[0].id)!==0?true:false):false} checked={this.props.filtervals.totalNoOfResults===0?true:false} onChange={e => this.handleFilterChange("totres",0)} />
                                <label htmlFor={this.props.t("ALL")} className="form-check-label">{/* <div className="filter-results all"></div> */} {this.props.t("ALL")}</label>
                            </div>
                        </Col>
                    </Row>    
                </Col>
            </Col>
            
            <Col className={this.props.filtersVisibility.yearRange===true?"single-filter-content":"single-filter-content d-none "}>
                <label className="filter-label">{this.props.t("YEAR_RANGE")}</label>
                <Row>
                    <Col xs={6} style={{paddingRight:"1px"}}>
                        {/* <select className="form-control form-control-sm" value={this.props.filtervals.minYear} onChange={e => this.handleFilterChange(e.target.value,"minYear",e)}>
                        {
                            this.props.filtersData.trendsYearsList.map((item, i) =>{
                                return <option key={i} value={item}>{item}</option>
                            })
                        }
                        </select> */}
                        <Select options={vyearslist} placeholder="Min" className="filterselmain" classNamePrefix="filterselect" value={vyearslist.find(mitem => mitem.value === this.props.filtervals.minYear)} onChange={e => this.handleFilterChange("minYear",e)}/>
                    </Col>
                    <Col xs={6} style={{paddingLeft:"1px"}}>
                        {/* <select className="form-control form-control-sm" value={this.props.filtervals.maxYear} onChange={e => this.handleFilterChange(e.target.value,"maxYear",e)}>
                        {
                            this.props.filtersData.trendsYearsList.map((item, i) =>{
                                return <option key={i} value={item}>{item}</option>
                            })
                        }
                        </select> */}
                        <Select options={vyearslist} placeholder="Max" className="filterselmain" classNamePrefix="filterselect" value={vyearslist.find(mitem => mitem.value === this.props.filtervals.maxYear)} onChange={e => this.handleFilterChange("maxYear",e)}/>
                    </Col>
                </Row>
            </Col>

            <Col className="single-filter-content">
                <Button variant="success" size="sm" onClick={()=>this.resetFilters()} style={{width:"60%",marginTop:"15px",borderRadius:"8px"}}>{this.props.t("RESET_FILTERS")}</Button>
            </Col>
        </Col>
        </>);
    }
}

export default withTranslation()(withRouter(connect()(FiltersView)));
