import { DICTIONARY_SET } from '../constants/dictionaryTypes';

const INITIAL_STATE = { dictionaryList:[]};

const dictionaryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case DICTIONARY_SET:
        return {
          ...state,
          dictionaryList: action.payload
        };
      default:
        return state;
    }
  };

export default dictionaryReducer;