import React from 'react';
import Chart from "react-apexcharts";
import {defaultColors} from "../../../../_services/common.service"

import reseticon from '../../../../assets/img/icons/zoom.png';

export default function DynamicLineChart(props) {
    
    var options = {
        chart: {
            animations:{ 
                enabled: props.animations,
                easing: 'easein',
                speed: 500,
                animateGradually: {
                    enabled: false,
                    delay: 0
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },//
            fontFamily: (props.arialfont?'Arial, sans-serif':'Poppins, sans-serif'),
            background: '#fff',
            type:"line",
            toolbar: {
                show: true,
                autoSelected: 'zoom',
                tools: {
                    download: false,
                    selection: true,
                    zoom: '<img src="" class="line-zoomicon" alt=""/>',
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: '<img src="'+reseticon+'" class="line-zoomicon" alt=""/>',
                }
            },
            zoom: {
                enabled: props.downloadType!==undefined?false:true,
            }
        },
        yaxis:{
            forceNiceScale: false,
            min:0,
            title:{
                text:props.axistitles.y,
                offsetX:-5,
                style:{
                    fontWeight: 600,
                    fontSize:'15px',
                    fontFamily: (props.arialfont?'Arial, sans-serif':'Poppins, sans-serif'),
                    color:'#283747',
                }
            },
            labels:{
                formatter: function(val) {
                    if(val!==undefined && val!==null && !isNaN(val) && val!==0){
                        return val.toFixed(0);
                    }
                    else{
                        return val;
                    }
                },
                rotateAlways: false,
                style: {
                    colors: [],
                    fontSize: '14px',
                    fontFamily: (props.arialfont?'Arial, sans-serif':'Poppins, sans-serif'),
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-label',
                },
                offsetX: 8,
                offsetY: 0,
            },
        },
        xaxis: {
            title:{
                text:props.axistitles.x,
                offsetY:-20,
                style:{
                    fontWeight: 600,
                    fontSize:'16px',
                    fontFamily: (props.arialfont?'Arial, sans-serif':'Poppins, sans-serif'),
                    color:'#283747',
                }    
            },
            categories: props.data.categories,
            labels:{
                rotateAlways: (props.data.categories.length>8?true:false),
                style: {
                    colors: [],
                    fontSize: '14px',
                    fontFamily: (props.arialfont?'Arial, sans-serif':'Poppins, sans-serif'),
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
            },
            
        },
        stroke: {
            curve: 'straight',
            width: 3,
        },
        markers: {
            size: 0
        },
        tooltip: {
             enabled: true, 
             enabledOnSeries: true,
             shared: props.data.series.length>15?false:true,
             y:{
                formatter: function(val) {
                    return val;
                },
             }
        },
        grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 1,
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            },  
            row: {
                colors: undefined,
                opacity: 0.5
            },  
            column: {
                colors: undefined,
                opacity: 0.5
            },
            padding: {
                left: 15,
                right: 10,
                bottom:(props.downloadType && (props.downloadType==="ppt" || props.downloadType==="img") ?(props.data.series.length<=5?0:-22):0)
            },
        },
        dataLabels: {
            enabled: false,
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                width: '100%'
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        
        legend: {
            offsetY: 1,
            height:props.downloadType!==undefined ? (props.data.series.length>18?450:undefined): undefined ,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: (props.downloadType && (props.downloadType==="ppt" || props.downloadType==="img")?"left":"center"),
            floating: false,
            //offsetY: (props.downloadType==="ppt"?20:-10),
            width:(props.downloadType && (props.downloadType==="ppt" || props.downloadType==="img")?500:undefined),
            fontSize:"16px",
            itemMargin: {
                horizontal: 6,
                vertical: props.data.series.length>20?4:1,
            },
          },
        colors: props.data.colors.length>0?props.data.colors: defaultColors,
    };
    var series = props.data.series;
    
    var chartheight = props.data.series.length>18?500:450;
    
    return (
        <>
            <Chart className="mchart-view" options={options} series={series} type="line" height={chartheight} />
        </>
    )


}
