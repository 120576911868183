
 import { DISTRICT_LIST_SET, CANCER_SITE_SET, ICD10_LIST_SET, TRENDS_YEARS_LIST_SET } from '../constants/filterListTypes';

 const INITIAL_STATE = { districtList:[], cancerSiteList:[], icd10List:[], trendsYearsList:[]};

const filterListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DISTRICT_LIST_SET:
        return {
        ...state,
        districtList: action.payload
        };
        case CANCER_SITE_SET:
        return {
            ...state,
            cancerSiteList: action.payload
        };
        case ICD10_LIST_SET:
        return {
            ...state,
            icd10List: action.payload
        };
        case TRENDS_YEARS_LIST_SET:
        return {
            ...state,
            trendsYearsList: action.payload
        };
        default:
        return state;
    }
  };

export default filterListReducer;