import { SEARCH_FILTERS_SET, SEARCH_RESULTS_SET } from '../constants/searchTypes';

const INITIAL_STATE = { filters:null, results:null};

const searchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SEARCH_FILTERS_SET:
        return {
          ...state,
          filters: action.payload
        };
      case SEARCH_RESULTS_SET:
        return {
          ...state,
          results: action.payload
        };
      default:
        return state;
    }
  };

export default searchReducer;