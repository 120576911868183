import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Particle } from 'jparticles';
import OnImagesLoaded from 'react-on-images-loaded';

import sal from 'sal.js';
import '../../../node_modules/sal.js/dist/sal.css';

import { langList } from '../../_services/common.service';
import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';

import i18n from "i18next";
import "../../_translations/i18n";

import './landingpage.css';
import { useTranslation, withTranslation } from 'react-i18next';

import MainSearch from '../mainSearch/mainSearch';
import BodyMap from './bodymap';
import RoadMap from './roadmap';

import logowtxt from '../../assets/img/logo_wtext.png';
import homelink1 from '../../assets/img/home/1.png';
import homelink2 from '../../assets/img/home/2.png';
import homelink3 from '../../assets/img/home/3.png';
//import homelink4 from '../../assets/img/home/4.png';
import homelink5 from '../../assets/img/home/5.png';

import footerlogo from '../../assets/img/footer-logo.png';
import aitdevlogo from '../../assets/img/ait_developers.png';

import { setMainType, setChartType, setChartCode } from '../../actions/search/search_action';

const salstyle = {
    "--sal-duration": "0.8s",
}

export const ViewLandingPage = (props) => {
    const [isallloaded,setAllLoaded] = useState(true);
    const { t } = useTranslation();

    const runAfterImagesLoaded = () => {
        //console.log("loaded");
        setAllLoaded(false);
    }

    return (
      <>
        {isallloaded?<div className="preloading-div"><div className="lds-ellipsis centered"><div></div><div></div><div></div><div></div></div></div>:<></>}
        <Col className="main-inner-content header">
            <Col xs={12} className="main-content">
                <Col xs={12} className="header-content">
                    <div onClick={props.toggleLightMode} style={{width:"50px",height:"20px",position:"absolute",zIndex:"11",right:"0px",top:"0px",cursor:"pointer"}}></div>
                    <Col className="header-overlay-bg"></Col>
                    <div id="particlesview" className="d-none d-sm-block" style={{width:"100%",height:"40rem"}}></div>
                    <Container>
                    <OnImagesLoaded onLoaded={runAfterImagesLoaded}>
                        <Row>
                            <Col xs={12} md={7} style={{position:"relative",paddingRight:"0px"}}>
                                <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle}>
                                    <img src={logowtxt} className="header-logo" alt="home logo"/>
                                    <h2>{t("NCCP_SUB")}<small>{t("NCCP")}</small></h2>
                                    <Col style={{marginTop:"2rem"}}><MainSearch cancerSites={props.cancerSites} latestYear={props.latestYear} /></Col>
                                </Col>
                                
                                <Col xs={12} className="home-link-content" style={{paddingTop:"35px"}}>
                                    <Row>
                                        <Col xs={12} md={6} lg={4} className="sub-content" onClick={()=>props.changeMainType("latest")} style={{paddingRight:"5px"}}>
                                            <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle} className="sub-container">
                                                <img src={homelink1} className="img-fluid" style={{width:"45%"}} alt="new"/>
                                                <h3>{t("CANCER")} {t("LATEST")}</h3>
                                                {/* <p className="text-center">View latest statistics of 2019</p> */}
                                            </Col>
                                        </Col>
                                        <Col xs={12} md={6} lg={4} className="sub-content" onClick={()=>props.changeMainType("trends")} style={{paddingLeft:"5px"}}>
                                            <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle} className="sub-container">
                                                <img src={homelink2} className="img-fluid" style={{width:"45%"}} alt="trend"/>
                                                <h3>{t("CANCER")}<br/>{t("TREND")}</h3>
                                                {/* <p className="text-center">View trending statistics of 2005-2019</p> */}
                                            </Col>
                                        </Col>
                                    </Row> 
                                    <Row>
                                        {/* <Col xs={12} md={6} lg={4} className="sub-content" onClick={()=>props.changeMainType("forecast")} style={{paddingRight:"5px"}}>
                                            <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle} className="sub-container">
                                                <img src={homelink4} className="img-fluid" style={{width:"45%"}} alt="fact sheets"/>
                                                <h3>{t("CANCER")}<br/>{t("FORECAST")}</h3>
                                                <p className="text-center">View predictions of future cancer data</p>
                                            </Col>
                                        </Col> */}
                                        <Col xs={12} md={6} lg={4} className="sub-content" onClick={()=>props.changeMainType("factSheets")} style={{paddingRight:"5px"}}>
                                            <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle} className="sub-container">
                                                <img src={homelink3} className="img-fluid" style={{width:"45%"}} alt="fact sheets"/>
                                                <h3>{t("FACT_SHEET")}</h3>
                                                {/* <p className="text-center">View fact sheet statistics of cancer data</p> */}
                                            </Col>
                                        </Col>
                                        {/* <Col xs={12} md={6} lg={4} className="sub-content" onClick={()=>props.changeMainType("method")} style={{paddingLeft:"5px"}}>
                                            <Col data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle} className="sub-container">
                                                <img src={homelink5} className="img-fluid" style={{width:"45%"}} alt="method"/>
                                                <h3>METHOD</h3>
                                            </Col>
                                        </Col> */}
                                    </Row>     
                                </Col>
                            </Col>
                            <Col xs={12} md={5} className="body-overlay-main">
                                <div data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle}>
                                    <BodyMap langobj={props.langobj} cancerSites={props.cancerSites} bodymaplist={props.bodymaplist} latestYear={props.latestYear}/>
                                </div>
                            </Col>    
                        </Row>  
                    </OnImagesLoaded>
                          
                    </Container>
                </Col>  
                {props.roadmaplist && props.roadmaplist.length > 0?<Col xs={12} className="middle-content landing-middle-content roadmap-content">
                    <Container>
                        <Col xs={12} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" style={salstyle}>
                            <h2>{t("ROADMAP")}</h2>
                            <RoadMap langobj={props.langobj} roadmaplist={props.roadmaplist} />
                        </Col>
                    </Container>
                </Col>:<></>}  
            </Col>
        </Col>
        <Col className="main-footer text-center">
            <Container>
                <img src={footerlogo} className="img-fluid" alt="footer logo"/>
                <span>National Cancer Control Programme,  Sri Lanka.</span>
                <a href="http://www.avenir.lk" target="_blank" rel="noreferrer"><img src={aitdevlogo} className="img-fluid devlogo-footer" title="Avenir IT (pvt) Ltd, Sri Lanka" alt="avenir it logo"/></a>
            </Container>
        </Col>
      </>
    );
   };

class LandingPage extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            selectedLang: "en", roadmaplist: [], bodymaplist: [],
            isLightMode: false,
        }
    }

    componentDidMount(){
        this._isMounted = true;

        if(this._isMounted){
            new Particle('#particlesview', {
                num: 50,
                color: '#a2fdfe',
                proximity: 100,
                range: 2000,
                maxSpeed: 0.5,
            });
            //document.body.classList.add("l-mode");
            sal({threshold: 0.2, once: true});

            if(localStorage.getItem("nclangobj")){
                const clangobj = JSON.parse(localStorage.getItem("nclangobj"));
                i18n.changeLanguage(clangobj.code);
            }

            this.loadRoadmapData();
            this.loadBodypartsData();
            //load language
            if(this.props.langobj&&this.props.langobj.languageDetails){
                this.setState({ selectedLang: (this.props.langobj.languageDetails.code?this.props.langobj.languageDetails.code:"en") });
            }
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
        //document.body.classList.remove("l-mode");
    }

    toggleLightMode = () => {
        this.setState({isLightMode: !this.state.isLightMode}, () => {
            if(this.state.isLightMode){
                document.body.classList.add("l-mode");
            } else{
                document.body.classList.remove("l-mode");
            }
        });
    }

    loadRoadmapData = () => {
        submitSets(submitCollection.getAllRoadmapDetails).then(res=> {
            //console.log(res);
            if(res.status===true && res.extra && res.extra.length > 0){
                this.setState({ roadmaplist: res.extra }, () => {
                    sal({threshold: 0.2, once: true});
                });
            }
        });
    }

    loadBodypartsData = () => {
        submitSets(submitCollection.getBodyPartDetails).then(res=> {
            //console.log(res);
            if(res.status===true && res.extra && res.extra.length > 0){
                this.setState({ bodymaplist: res.extra });
            }
        });
    }

    handleLang = (lcode, evt) => {
        evt.preventDefault();
        this.setState({ selectedLang: langList[lcode].code});
        i18n.changeLanguage(langList[lcode].code);
        this.props.handleLangObj(langList[lcode]);
        localStorage.setItem("nclangobj",JSON.stringify(langList[lcode]));
    }

    changeMainType = (type) =>{
        window.scrollTo({ top: 0, behavior: 'smooth' }); //scroll to top

        this.props.SetMainType(type);
        this.props.SetChartType(null);
        this.props.SetChartCode(null);
        
        if(type==="latest" || type==="trends"){
            this.props.history.push("/statistics");
        } else if(type==="forecast"){
            this.props.history.push("/factsheets");
        } 
        else if(type==="method"){
            this.props.history.push("/method");
        }
        else{
            this.props.history.push("/factsheets");
        }
    }

    render(){
        return (<ViewLandingPage toggleLightMode={this.toggleLightMode} bodymaplist={this.state.bodymaplist} roadmaplist={this.state.roadmaplist} handleLang={this.handleLang} changeMainType={this.changeMainType} {...this.props}/>);
    }
}

const mapDispatchToProps = dispatch => ({
    SetMainType: (payload) => dispatch(setMainType(payload)),
    SetChartType: (payload) => dispatch(setChartType(payload)),
    SetChartCode: (payload) => dispatch(setChartCode(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(LandingPage)));
