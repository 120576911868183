


import React, { useState, useEffect } from 'react';
import { Col, ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'; //Table
import { X, Search } from 'react-feather';

import SearchModal from '../common_layouts/searchmodal';

import maleview from '../../assets/img/home/male.png';
import femaleview from '../../assets/img/home/female.png';

//body parts
import lungspart from '../../assets/img/home/body/lungs.png';
import facepart from '../../assets/img/home/body/face-new.png';
import bladderpart from '../../assets/img/home/body/bladder.png';
import stomachpart from '../../assets/img/home/body/stomach.png';
import thyroidpart from '../../assets/img/home/body/thyroid.png';
import uteruspart from '../../assets/img/home/body/uterus.png';
/* import liverpart from '../../assets/img/home/body/liver.png';
import kidneyleftpart from '../../assets/img/home/body/kidney-left.png';
import kidneyrightpart from '../../assets/img/home/body/kidney-right.png'; */
import breastpart from '../../assets/img/home/body/breast.png';
import colonpart from '../../assets/img/home/body/colon.png';
import lymppart from '../../assets/img/home/body/lympoma.png';

const bodysampledata = [
    {
        type:"lungs", 
        code:["1001"],
        title:"Trachea, Bronchus and Lungs",
        searchwords: "leukaemia lung"
    },
    {
        type:"uterus", 
        code:["1002","1012","1013"],
        title: "Uterus, Ovary and Cervix",
        searchwords: "uterus cervix ovary"
    },
    {
        type:"thyroid", 
        code:["1014","1015","1003"],
        title:"Thyroid, Pharynx and Larynx",
        searchwords: "thyroid larynx pharynx"
    },
    {
        type:"face", 
        code:["1004"],
        title:"Lip, Tongue and Mouth",
        searchwords: "mouth"
    },
    {
        type:"colon", 
        code:["1005"],
        title:"Colon and Rectum",
        searchwords: "colon"
    },
    {
        type:"stomach", 
        code:["1006","1016"],
        title:"Stomach and Oesophagus",
        searchwords: "oesophagus stomach"
    },
    {
        type:"bladder", 
        code:["1007","1017"],
        title:"Bladder and Prostate",
        searchwords: "bladder prostate"
    },
    {
        type:"lymphoma", 
        code:["1008"],
        title:"Lymphoma",
        searchwords: "lymphoma"
    },
    {
        type:"breasts", 
        code:["1009"],
        title:"Breasts",
        searchwords: "breast"
    }
];

export default function BodyMap (props) {
    const [isSelected, setSelected] = useState(false);
    const [selectedGender, setSelectedGender] = useState("male");
    const [selectedSiteObj, setSelectedSiteObj] = useState(null);
    const [loadedBodymapList, setBodymapList] = useState([]);
    const [selectedBodyObj, setSelectedBodyObj] = useState(null);
    const [showsearchmodal, setShowSearchModal] = useState(false);
    const [searchWords, setSearchWords] = useState("");
    const [searchGender, setSearchGender] = useState("male");

    const clang = (props.langobj?props.langobj.languageDetails.code:"en");
    //console.log(clang);

    useEffect(() => {
        //console.log(props.bodymaplist);
        setBodymapList(props.bodymaplist);
    }, [props.bodymaplist]);

    const handleSetSelectedObj = (selsite,gender) => {
        const foundobj = bodysampledata.find(xitem => xitem.type === selsite);
        if(foundobj){
            const selarrlist = [];
            var searchwords = "";

            for (let j = 0; j < foundobj.code.length; j++) {
                const codeitem = foundobj.code[j];

                const foundbodyobj = loadedBodymapList.find(xitem => (xitem.bodyPartCode === codeitem && (xitem.gender === "Both" ||  xitem.gender === gender)));
                
                if(foundbodyobj && foundbodyobj.cancerSites && foundbodyobj.cancerSites.length > 0){
                    for (let i = 0; i < foundbodyobj.cancerSites.length; i++) {
                        //searchwords = searchwords + (clang==="si"?foundbodyobj.cancerSites[i].cancerSiteNameSin:clang==="ta"?foundbodyobj.cancerSites[i].cancerSiteNameTam:foundbodyobj.cancerSites[i].cancerSiteNameEng)+' ';
                        searchwords = searchwords + foundbodyobj.cancerSites[i].cancerSiteNameEng+' ';
                    }    
                    selarrlist.push(foundbodyobj);
                }    
            }
            
            searchwords = searchwords + gender;
            foundobj["searchwords"] = searchwords;

            //console.log(selarrlist);
            setSearchGender(gender);
            setSelectedBodyObj(selarrlist);
            setSelected(selsite);
            setSelectedSiteObj(foundobj);
        } else{
            setSelected(false);
        }
    }

    const handleSearchBodyPart = (xitem) => {
        //console.log(selectedSiteObj.searchwords);
        var searchwords = "";
        for (let i = 0; i < xitem.cancerSites.length; i++) {
            searchwords = searchwords + xitem.cancerSites[i].cancerSiteNameEng+' ';
        }
        searchwords = searchwords + searchGender;
        setSearchWords(searchwords);
        handleCloseSearch();
    }
    //toggle search modal
    const handleCloseSearch = () => {
        setShowSearchModal(!showsearchmodal);
    }

    const toggleCollapseClass = (idx) => {
        var parentdiv = document.getElementsByClassName("single-site-view")[idx];
        var childptag = parentdiv.getElementsByTagName("p")[0];
        //console.log(childptag.classList.contains('viewall'));

        //toggle class
        if(childptag.classList.contains('viewall')){
            childptag.classList.remove("viewall");
        } else{
            var elems = document.querySelectorAll(".single-site-view p");
            [].forEach.call(elems, (el) => {
                el.classList.remove("viewall");
            });
            childptag.classList.add("viewall");
        }
    }

    const renderTooltip = (props) => (
        <Tooltip className="button-tooltip d-none d-lg-block" {...props}>
            {props.text}
        </Tooltip>
    );

    return <Col className="main-body-preview-div">
        <Col className={"body-preview"+(isSelected?" active":"")}>
            <Col className={"male-view "+(selectedGender==="male"?"":"d-none")}>
                <img src={maleview} className={"main-body"+(isSelected?" active":"")} alt="male body"/>

                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Trachea, Bronchus and Lungs"})}><div className={"sub-body-part lungs"+(isSelected==="lungs"?" active":"")}>
                    <img src={lungspart} onClick={() => handleSetSelectedObj("lungs","Male")} alt="lungs"/><div onClick={() => handleSetSelectedObj("lungs","Male")} className="glow-view" style={{marginLeft:"43%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Lip, Tongue and Mouth"})}><div className={"sub-body-part face"+(isSelected==="face"?" active":"")}>
                    <img src={facepart} onClick={() => handleSetSelectedObj("face","Male")} alt="face"/><div onClick={() => handleSetSelectedObj("face","Male")} className="glow-view" style={{marginLeft:"42%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Bladder and Prostate"})}><div className={"sub-body-part bladder"+(isSelected==="bladder"?" active":"")}>
                    <img src={bladderpart} onClick={() => handleSetSelectedObj("bladder","Male")} alt="bladder"/><div onClick={() => handleSetSelectedObj("bladder","Male")} className="glow-view"></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Stomach and Oesophagus"})}><div className={"sub-body-part stomach"+(isSelected==="stomach"?" active":"")}>
                    <img src={stomachpart} onClick={() => handleSetSelectedObj("stomach","Male")} alt="stomach"/><div onClick={() => handleSetSelectedObj("stomach","Male")} className="glow-view"></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Thyroid, Pharynx and Larynx"})}><div className={"sub-body-part thyroid"+(isSelected==="thyroid"?" active":"")}>
                    <img src={thyroidpart} onClick={() => handleSetSelectedObj("thyroid","Male")} alt="thyroid"/><div onClick={() => handleSetSelectedObj("thyroid","Male")} className="glow-view" style={{marginTop:"-91%",marginLeft:"23%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Lymphoma"})}><div className={"sub-body-part lymphoma"+(isSelected==="lymphoma"?" active":"")}>
                    <img src={lymppart} onClick={() => handleSetSelectedObj("lymphoma","Male")} alt="lymphoma"/><div onClick={() => handleSetSelectedObj("lymphoma","Male")} className="glow-view" style={{marginTop:"-31%",marginLeft:"12%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Colon and Rectum"})}><div className={"sub-body-part colon"+(isSelected==="colon"?" active":"")}>
                    <img src={colonpart} onClick={() => handleSetSelectedObj("colon","Male")} alt="colon"/><div onClick={() => handleSetSelectedObj("colon","Male")} className="glow-view" style={{marginTop:"-65%"}}></div>
                </div></OverlayTrigger>

                {/* <img src={liverpart} onClick={() => handleSetSelectedObj("liver","Male")} className={"sub-body-part liver"+(isSelected==="liver"?" active":"")} alt="liver"/> */}
                {/* <img src={kidneyleftpart} onClick={() => handleSetSelectedObj("kidney","Male")} className={"sub-body-part kidneyleft"+(isSelected==="kidney"?" active":"")} alt="kidney"/>
                <img src={kidneyrightpart} onClick={() => handleSetSelectedObj("kidney","Male")} className={"sub-body-part kidneyright"+(isSelected==="kidney"?" active":"")} alt="kidney"/> */}
            </Col>
            <Col className={"female-view "+(selectedGender==="female"?"":"d-none")}>
                <img src={femaleview} className={"main-body"+(isSelected?" active":"")} alt="female body"/>

                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Trachea, Bronchus and Lungs"})}><div className={"sub-body-part lungs"+(isSelected==="lungs"?" active":"")}>
                    <img src={lungspart} onClick={() => handleSetSelectedObj("lungs","Female")} alt="lungs"/><div onClick={() => handleSetSelectedObj("lungs","Female")} className="glow-view" style={{marginLeft:"43%",marginTop:"-69%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Breasts"})}><div className={"sub-body-part breasts"+(isSelected==="breasts"?" active":"")}>
                    <img src={breastpart} onClick={() => handleSetSelectedObj("breasts","Female")} alt="breasts"/><div onClick={() => handleSetSelectedObj("breasts","Female")}  className="glow-view" style={{marginLeft:"47%",marginTop:"-49%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Lip, Tongue and Mouth"})}><div className={"sub-body-part face"+(isSelected==="face"?" active":"")}>
                    <img src={facepart} onClick={() => handleSetSelectedObj("face","Female")} alt="face"/><div onClick={() => handleSetSelectedObj("face","Female")} className="glow-view" style={{marginLeft:"42%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Stomach and Oesophagus"})}><div className={"sub-body-part stomach"+(isSelected==="stomach"?" active":"")}>
                    <img src={stomachpart} onClick={() => handleSetSelectedObj("stomach","Female")} alt="stomach"/><div onClick={() => handleSetSelectedObj("stomach","Female")} className="glow-view"></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Thyroid, Pharynx and Larynx"})}><div className={"sub-body-part thyroid"+(isSelected==="thyroid"?" active":"")}>
                    <img src={thyroidpart} onClick={() => handleSetSelectedObj("thyroid","Female")} alt="thyroid"/><div onClick={() => handleSetSelectedObj("thyroid","Female")} className="glow-view" style={{marginTop:"-91%",marginLeft:"23%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Lymphoma"})}><div className={"sub-body-part lymphoma"+(isSelected==="lymphoma"?" active":"")}>
                    <img src={lymppart} onClick={() => handleSetSelectedObj("lymphoma","Female")} alt="lymphoma"/><div onClick={() => handleSetSelectedObj("lymphoma","Female")} className="glow-view" style={{marginTop:"-31%",marginLeft:"12%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Colon and Rectum"})}><div className={"sub-body-part colon"+(isSelected==="colon"?" active":"")}>
                    <img src={colonpart} onClick={() => handleSetSelectedObj("colon","Female")} alt="colon"/><div onClick={() => handleSetSelectedObj("colon","Female")} className="glow-view" style={{marginTop:"-75%"}}></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Uterus, Ovary and Cervix"})}><div className={"sub-body-part uterus"+(isSelected==="uterus"?" active":"")}>
                    <img src={uteruspart} onClick={() => handleSetSelectedObj("uterus","Female")} alt="uterus"/><div onClick={() => handleSetSelectedObj("uterus","Female")} className="glow-view"></div>
                </div></OverlayTrigger>
                <OverlayTrigger placement="right" transition={false} overlay={renderTooltip({text: "Bladder"})}><div className={"sub-body-part bladder"+(isSelected==="bladder"?" active":"")} style={{top:"35.5%"}}>
                    <img src={bladderpart} onClick={() => handleSetSelectedObj("bladder","Female")} alt="bladder"/><div onClick={() => handleSetSelectedObj("bladder","Female")} className="glow-view"></div>
                </div></OverlayTrigger>
                
                {/* <img src={liverpart} onClick={() => handleSetSelectedObj("liver")} className={"sub-body-part liver"+(isSelected==="liver"?" active":"")} alt="liver"/> */}
                {/* <img src={kidneyleftpart} onClick={() => handleSetSelectedObj("kidney")} className={"sub-body-part kidneyleft"+(isSelected==="kidney"?" active":"")} alt="kidney"/>
                <img src={kidneyrightpart} onClick={() => handleSetSelectedObj("kidney")} className={"sub-body-part kidneyright"+(isSelected==="kidney"?" active":"")} alt="kidney"/> */}
            </Col>
        </Col>
        <Col className={"description-view"+(isSelected?" active":"")}>
            <div className="desc-header">
                <span className="close-btn" onClick={() => setSelected(!isSelected)}><X size={20}/></span>
            </div>
            <div className="desc-content">
                <div className="preview-content">{selectedSiteObj?<Col className="img-thumb">
                    <img src={selectedSiteObj.type==="lungs"?lungspart:selectedSiteObj.type==="thyroid"?thyroidpart:selectedSiteObj.type==="uterus"?uteruspart:selectedSiteObj.type==="bladder"?bladderpart:selectedSiteObj.type==="colon"?colonpart:selectedSiteObj.type==="face"?facepart
                    :selectedSiteObj.type==="lymphoma"?lymppart:selectedSiteObj.type==="stomach"?stomachpart:selectedSiteObj.type==="breasts"?breastpart:""} className={"img-fluid"} alt="lungs"/></Col>:<></>}
                </div>
                <Col style={{padding:"0px 5px"}}>
                    {selectedBodyObj && selectedBodyObj.length > 0?<>{(selectedBodyObj.length > 1)?(selectedBodyObj.map((xitem,xidx) => {
                        return <Col className="single-site-view" key={xidx}>
                            <h4>{xitem?(clang==="si"?xitem.bodyPartNameSin:clang==="ta"?xitem.bodyPartNameTam:xitem.bodyPartName):"-"}
                            <Button variant="danger" size="sm" onClick={() => handleSearchBodyPart(xitem)} style={{borderRadius:"25px",padding:"3px 6px",fontSize:"11px"}}><Search size={14}/> SEARCH</Button></h4>
                            <div  onClick={e => (xitem.description.length > 160?toggleCollapseClass(xidx):null)}><p className={xitem.description.length > 160?"overflow-view":""}>{(clang==="si"?xitem.descriptionSin:clang==="ta"?xitem.descriptionTam:xitem.description)}</p>
                            {xitem.description.length > 160?<div className="viewmore-link">MORE</div>:<></>}</div>
                            {/* {selectedSiteObj && selectedSiteObj.figures && selectedSiteObj.figures.length > 0?
                            <Col style={{padding:"0px 10px"}}><Table className="table table-bordered">
                                <thead><tr><th width="50%">YEAR</th><th>MALE</th><th>FEMALE</th></tr></thead>
                                <tbody>
                                    {selectedSiteObj.figures.map((xitem, xidx) => {
                                        return <tr key={xidx}><td>{xitem.year}</td><td>{xitem.male}</td><td>{xitem.female}</td></tr>
                                    })}
                                </tbody>
                            </Table></Col>:<></>}
                            <Col className="text-center"><Button variant="danger" size="sm" onClick={handleSearchBodyPart} style={{borderRadius:"25px",padding:"5px 20px"}}>SEARCH</Button></Col> */}</Col>;
                    })):<>
                        <Col className="single-site-view oneitem">
                            <h4>{selectedBodyObj[0]?(clang==="si"?selectedBodyObj[0].bodyPartNameSin:clang==="ta"?selectedBodyObj[0].bodyPartNameTam:selectedBodyObj[0].bodyPartName):"-"}</h4>
                            {selectedBodyObj[0]?<p>{(clang==="si"?selectedBodyObj[0].descriptionSin:clang==="ta"?selectedBodyObj[0].descriptionTam:selectedBodyObj[0].description)}</p>:"-"}
                            <Col className="text-center"><Button variant="danger" size="sm" onClick={() => handleSearchBodyPart(selectedBodyObj[0])} style={{borderRadius:"25px",padding:"5px 18px",marginLeft:"20px"}}><Search size={14}/> SEARCH</Button></Col>
                        </Col>
                    </>}</>:<></>}    
                </Col>
                
            </div>
        </Col>
        <Col className="text-center bodymap-toggle-list">
            {!isSelected?<ButtonGroup aria-label="Basic example">
                <Button variant="secondary" className={selectedGender==="male"?"active":""} onClick={() => setSelectedGender("male")}>Male</Button>
                <Button variant="secondary" className={selectedGender==="female"?"active":""} onClick={() => setSelectedGender("female")}>Female</Button>
            </ButtonGroup>:<></>}
        </Col>

        {showsearchmodal?<SearchModal isbodysearch={true} cancerSites={props.cancerSites} searchQuery={searchWords?searchWords:""} showsearchmodal={showsearchmodal} latestYear={props.latestYear} handleCloseSearch={handleCloseSearch} />:<></>}
    </Col>;
}