import { LATESTYEAR_SET, USE_COLOR_SET,CURRENT_DATA_VERSION_SET } from '../constants/commonTypes';

const INITIAL_STATE = { latestYear:null, useColor:false, currentDataVersion:null };

const commonSettingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case LATESTYEAR_SET:
        return {
          ...state,
          latestYear: action.payload
        };
        case USE_COLOR_SET:
        return {
          ...state,
          useColor: action.payload
        };
        case CURRENT_DATA_VERSION_SET:
        return {
          ...state,
          currentDataVersion: action.payload
        };
      default:
        return state;
    }
  };

export default commonSettingsReducer;