import { combineReducers } from 'redux';

import languageReducer from './languageReducer';
import searchReducer from './searchReducer';
import searchCategoryReducer from './searchCategoryReducer';
import dictionaryReducer from './dictionaryReducer';
import filterListsReducer from './filterListsReducer';
import commonSettingsReducer from './commonSettingsReducer';
import factSheetReducer from './factsheetReducer';
import chartDescriptionsReducer from './chartDescriptionsReducer';

const appReducer = combineReducers({
  langState: languageReducer,
  searchState:searchReducer,
  searchCategoryState:searchCategoryReducer,
  dictionaryState:dictionaryReducer,
  filterListState:filterListsReducer,
  commonSettingsState:commonSettingsReducer,
  factSheetState:factSheetReducer,
  chartDescriptionState:chartDescriptionsReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;